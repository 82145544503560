<template>

  <v-card  class="" :max-width="$vuetify.breakpoint.xs ? 340 : 400">
    <v-card-title primary-title>
        Dropship.Me
        <v-chip class="ml-3" small outlined >New</v-chip>
    </v-card-title>
    <v-img contain
        size="100"
        src="https://ahkr.b-cdn.net/wp-content/uploads/2021/11/dropship-me-hompage-1024x427.jpg"></v-img>


    <v-card-text>
        DropshipMe is a powerful dropshipping tool that enables an ecommerce
        store owner to track down a profitable niche, search for the best suppliers in that niche,
        and then choose which products to sell.
    </v-card-text>
    <v-card-actions>
        <v-btn outlined color="primary">Visit <v-icon>mdi-open-in-new</v-icon></v-btn>
        <v-btn outlined color="primary">Read More <v-icon>mdi-book</v-icon></v-btn>


    </v-card-actions>
    <v-chip
      class="ma-2"
      color="primary"
    >
      10% off use code: MYNEWSTORE10
    </v-chip>

  </v-card>

</template>

<script>
export default {
    name: 'ContentCard'
}
</script>

<style>

</style>