<template>
<svg id="Lager_2" data-name="Lager 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 154.06 157.46">
  <defs>

    <linearGradient id="linear-gradient" x1="0" y1="128.7" x2="154.06" y2="128.7" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#47a0ff"/>
      <stop offset=".21" stop-color="#4ba3fd"/>
      <stop offset=".44" stop-color="#58aefa"/>
      <stop offset=".69" stop-color="#6fbff5"/>
      <stop offset=".94" stop-color="#8dd7ee"/>
      <stop offset="1" stop-color="#97dfec"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="1932.47" y1="46.73" x2="1932.47" y2="8.21" gradientTransform="translate(1974.87) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#58dfec"/>
      <stop offset="1" stop-color="#5a9dff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3" x1="1923.97" y1="46.73" x2="1923.97" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-4" x1="1932.44" y1="46.73" x2="1932.44" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-5" x1="1940.93" y1="46.73" x2="1940.93" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-6" x1="1923.94" y1="46.73" x2="1923.94" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-7" x1="1932.42" y1="46.73" x2="1932.42" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-8" x1="1940.9" y1="46.73" x2="1940.9" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-9" x1="1923.91" y1="46.73" x2="1923.91" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-10" x1="1932.39" y1="46.73" x2="1932.39" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-11" x1="1940.87" y1="46.73" x2="1940.87" y2="8.21" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-12" x1="5.72" y1="70.47" x2="19.36" y2="46.85" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-13" x1="5.72" y1="48.07" x2="19.36" y2="24.46" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-14" x1="50.29" y1="111.03" x2="98.57" y2="79.1" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#2babec"/>
      <stop offset="1" stop-color="#163a88"/>
    </linearGradient>
    <linearGradient id="linear-gradient-15" x1="87.33" y1="73.38" x2="112.97" y2="28.96" xlink:href="#linear-gradient-14"/>
    <linearGradient id="linear-gradient-16" x1="89.53" y1="70.4" x2="111.1" y2="31.33" gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlink:href="#linear-gradient-2"/>
    <linearGradient id="linear-gradient-17" x1="74.58" y1="624.65" x2="85.62" y2="605.51" gradientTransform="translate(0 -626.84) rotate(30) scale(1.15 .87) skewX(23.41)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#2774c6"/>
      <stop offset="1" stop-color="#47a0ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-18" x1="94.18" y1="624.65" x2="105.23" y2="605.51" xlink:href="#linear-gradient-17"/>
    <linearGradient id="linear-gradient-19" x1="113.56" y1="624.65" x2="124.61" y2="605.51" xlink:href="#linear-gradient-17"/>
    <clipPath id="clippath">
      <path class="cls-153" d="M71.89,80.75l.19-.12s.03-.02,.05-.03l1.59-.93h0c.53-.26,1.28-.19,2.11,.29,1.59,.92,2.89,2.96,2.89,4.55,0,.7-.26,1.19-.68,1.45l-1.78,1.04c-.53,.26-1.27,.18-2.1-.29-1.59-.92-2.89-2.96-2.89-4.55,0-.65,.15-1.07,.61-1.4Z"/>
    </clipPath>
    <clipPath id="clippath-1">
      <path class="cls-153" d="M96.94,96.3l.19-.12s.03-.02,.05-.03l1.59-.93h0c.53-.26,1.28-.19,2.11,.29,1.59,.92,2.89,2.96,2.89,4.55,0,.94-.67,1.46-.67,1.46l-1.79,1.02c-.53,.26-1.27,.18-2.1-.29-1.59-.92-2.89-2.96-2.89-4.55,0-.65,.15-1.07,.61-1.4Z"/>
    </clipPath>
    <clipPath id="clippath-2">
      <path class="cls-153" d="M78.21,107.13l.19-.12s.03-.02,.05-.03l1.59-.93h0c.53-.26,1.27-.19,2.11,.29,1.59,.92,2.88,2.96,2.88,4.55,0,.7-.26,1.19-.68,1.45l-1.78,1.04c-.53,.26-1.27,.18-2.1-.29-1.59-.92-2.88-2.96-2.88-4.55,0-.65,.15-1.07,.61-1.4Z"/>
    </clipPath>
    <clipPath id="clippath-3">
      <path class="cls-153" d="M50.89,92.78l.19-.12s.03-.02,.05-.03l1.59-.93h0c.53-.26,1.27-.19,2.11,.29,1.59,.92,2.88,2.96,2.88,4.55,0,.7-.26,1.19-.68,1.45l-1.78,1.04c-.53,.26-1.27,.18-2.1-.29-1.59-.92-2.88-2.96-2.88-4.55,0-.65,.15-1.07,.61-1.4Z"/>
    </clipPath>
    <linearGradient id="linear-gradient-20" x1="1947.11" y1="108.45" x2="1992.92" y2="71.86" gradientTransform="translate(2046.12) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#cff7ff"/>
      <stop offset="0" stop-color="#ccf3fc"/>
      <stop offset=".05" stop-color="#badce9"/>
      <stop offset=".09" stop-color="#accada"/>
      <stop offset=".14" stop-color="#a2bed0"/>
      <stop offset=".22" stop-color="#9cb7ca"/>
      <stop offset=".38" stop-color="#9bb5c9"/>
      <stop offset=".55" stop-color="#9db8cb"/>
      <stop offset=".69" stop-color="#a5c2d4"/>
      <stop offset=".83" stop-color="#b3d4e2"/>
      <stop offset=".96" stop-color="#c7edf6"/>
      <stop offset="1" stop-color="#cff7ff"/>
    </linearGradient>
    <linearGradient id="linear-gradient-21" x1="1977.57" y1="36.64" x2="1977.32" y2="19.36" gradientTransform="translate(2046.12) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff7466"/>
      <stop offset=".13" stop-color="#f2695a"/>
      <stop offset=".35" stop-color="#e35c4b"/>
      <stop offset=".53" stop-color="#de5846"/>
      <stop offset=".64" stop-color="#e46454"/>
      <stop offset=".86" stop-color="#f5847a"/>
      <stop offset=".97" stop-color="#ff9791"/>
    </linearGradient>
    <linearGradient id="linear-gradient-22" x1="1986.84" y1="52.98" x2="1987.23" y2="42.89" gradientTransform="translate(2071.81) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ffb445"/>
      <stop offset=".95" stop-color="#ffbb46"/>
      <stop offset="1" stop-color="#ffbc47"/>
    </linearGradient>
    <linearGradient id="linear-gradient-23" x1="1976.88" y1="84.18" x2="1980.83" y2="49.64" gradientTransform="translate(2071.81) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ffbc47"/>
      <stop offset=".38" stop-color="#ffc149"/>
      <stop offset=".86" stop-color="#ffd14f"/>
      <stop offset=".99" stop-color="#ffd752"/>
    </linearGradient>
    <linearGradient id="linear-gradient-24" x1="1981.8" y1="87.48" x2="1983.99" y2="49.76" gradientTransform="translate(2071.81) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ffb445"/>
      <stop offset=".53" stop-color="#ffbb45"/>
      <stop offset="1" stop-color="#ffc745"/>
    </linearGradient>
    <linearGradient id="linear-gradient-25" x1="1994.51" y1="83.96" x2="1997.47" y2="46.92" xlink:href="#linear-gradient-23"/>
    <linearGradient id="linear-gradient-26" x1="1942.89" y1="81.17" x2="1945.89" y2="66.65" gradientTransform="translate(2046.12) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff7466"/>
      <stop offset=".59" stop-color="#ec6454"/>
      <stop offset=".99" stop-color="#de5846"/>
    </linearGradient>
    <linearGradient id="linear-gradient-27" x1="1944.69" y1="67.48" x2="1948.64" y2="60.17" gradientTransform="translate(2046.12) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#de5846"/>
      <stop offset=".25" stop-color="#ea6f61"/>
      <stop offset=".54" stop-color="#f5857b"/>
      <stop offset=".8" stop-color="#fc928b"/>
      <stop offset="1" stop-color="#ff9791"/>
    </linearGradient>
    <linearGradient id="linear-gradient-28" x1="1989.66" y1="33.66" x2="1995.14" y2="26.11" gradientTransform="translate(2046.12) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#de5846"/>
      <stop offset=".24" stop-color="#e96151"/>
      <stop offset=".67" stop-color="#f96f60"/>
      <stop offset="1" stop-color="#ff7466"/>
    </linearGradient>
    <linearGradient id="linear-gradient-29" x1="2003.86" y1="47.66" x2="1998.28" y2="38" gradientTransform="translate(2046.12) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff7466"/>
      <stop offset=".13" stop-color="#f2695a"/>
      <stop offset=".35" stop-color="#e35c4b"/>
      <stop offset=".53" stop-color="#de5846"/>
      <stop offset=".67" stop-color="#df5a48"/>
      <stop offset=".76" stop-color="#e36151"/>
      <stop offset=".84" stop-color="#e96e60"/>
      <stop offset=".91" stop-color="#f27f75"/>
      <stop offset=".97" stop-color="#fe9690"/>
      <stop offset=".97" stop-color="#ff9791"/>
    </linearGradient>
  </defs>
  <g id="_Слой_1" data-name="Слой 1">
    <g>
      <g>
        <path class="cls-49" d="M154.06,99.94c0,11.39-7.52,22.76-22.56,31.45-30.08,17.37-78.85,17.37-108.94,0C7.52,122.7,0,111.33,0,99.94s7.52-22.77,22.56-31.44c30.09-17.37,78.86-17.37,108.94,0,15.03,8.67,22.56,20.06,22.56,31.44Z"/>
        <path class="cls-180" d="M154.06,99.94v13.05c0,11.38-7.52,22.76-22.56,31.45-30.08,17.36-78.85,17.36-108.94,0C7.52,135.76,0,124.37,0,112.99v-13.05c0,11.39,7.52,22.76,22.56,31.45,30.09,17.37,78.86,17.37,108.94,0,15.03-8.69,22.56-20.06,22.56-31.45Z"/>
      </g>
      <g>
        <polygon class="cls-30" points="52.2 5.93 52.21 9.28 32.6 20.6 32.59 17.25 52.2 5.93"/>
        <path class="cls-28" d="M48.97,15.18l3.85-2.23c.73-.42,1.32-.08,1.33,.75v4.43c.01,.85-.58,1.88-1.3,2.29l-3.85,2.23c-.74,.43-1.33,.09-1.34-.76v-4.43c-.02-.83,.58-1.86,1.32-2.29Z"/>
        <path class="cls-32" d="M40.49,20.08l3.85-2.23c.73-.42,1.32-.08,1.33,.76v4.43c.01,.85-.58,1.87-1.3,2.29l-3.85,2.23c-.74,.43-1.32,.08-1.33-.77v-4.43c-.02-.83,.57-1.85,1.3-2.28Z"/>
        <path class="cls-31" d="M32,24.98l3.85-2.23c.73-.42,1.32-.08,1.33,.75v4.43c.01,.85-.58,1.88-1.3,2.29l-3.86,2.23c-.74,.43-1.32,.08-1.32-.77v-4.43c-.02-.83,.57-1.85,1.3-2.28Z"/>
        <path class="cls-24" d="M49,24.74l3.85-2.23c.73-.42,1.32-.08,1.33,.75v4.44c.01,.83-.58,1.86-1.3,2.28l-3.86,2.23c-.74,.43-1.33,.09-1.34-.75v-4.44c-.01-.83,.58-1.86,1.32-2.29Z"/>
        <path class="cls-27" d="M40.51,29.64l3.86-2.23c.73-.42,1.32-.08,1.33,.75v4.44c.01,.83-.58,1.86-1.3,2.28l-3.86,2.23c-.74,.43-1.32,.08-1.33-.75v-4.44c-.01-.83,.57-1.85,1.3-2.28Z"/>
        <path class="cls-25" d="M32.03,34.53l3.86-2.23c.73-.42,1.32-.08,1.33,.75v4.44c.01,.83-.58,1.86-1.3,2.28l-3.86,2.23c-.74,.43-1.32,.08-1.33-.75v-4.44c-.01-.83,.57-1.85,1.3-2.28Z"/>
        <path class="cls-29" d="M49.02,34.3l3.86-2.23c.73-.42,1.32-.08,1.33,.77v4.43c.01,.83-.58,1.86-1.3,2.28l-3.86,2.23c-.74,.43-1.33,.09-1.34-.75v-4.43c-.01-.85,.58-1.88,1.32-2.3Z"/>
        <path class="cls-26" d="M40.54,39.19l3.86-2.23c.73-.42,1.32-.08,1.33,.77v4.43c.01,.83-.58,1.86-1.3,2.28l-3.85,2.23c-.74,.43-1.32,.08-1.33-.75v-4.43c-.01-.85,.57-1.87,1.3-2.29Z"/>
        <path class="cls-23" d="M32.06,44.09l3.86-2.23c.73-.42,1.32-.08,1.33,.77v4.43c.01,.83-.58,1.86-1.3,2.28l-3.85,2.23c-.74,.43-1.32,.08-1.33-.75v-4.43c-.01-.85,.57-1.87,1.3-2.29Z"/>
      </g>
      <g>
        <path class="cls-47" d="M21.11,57.96c0,2.39-1.94,5.44-4.32,6.82l-8.48,4.9c-2.39,1.38-4.32,.56-4.32-1.83v-8.48c0-2.39,1.94-5.44,4.32-6.82l8.48-4.9c2.39-1.38,4.32-.56,4.32,1.83v8.48Z"/>
        <path class="cls-11" d="M17.72,61.45s-.01,0-.02,0l-10.32,5.96c-.18,.1-.35,.13-.47,.07-.12-.06-.18-.2-.17-.39l.51-8.08c.02-.35,.31-.78,.65-.98l9.29-5.36c.34-.2,.62-.1,.65,.23l.5,7.34s.03,.11,.03,.17c0,.36-.29,.82-.65,1.02Zm-9.64,4.27l8.93-5.16-.43-6.24-8.08,4.66-.43,6.73Z"/>
        <path class="cls-11" d="M14.74,55.39c-.36,.21-.65,.08-.65-.27v-1.9c0-.78-.63-1.04-1.41-.6l-.16,.09c-.78,.45-1.41,1.45-1.41,2.22v1.9c0,.36-.29,.82-.65,1.02-.36,.21-.65,.08-.65-.27v-1.9c0-1.49,1.21-3.41,2.71-4.27l.16-.09c1.49-.86,2.71-.35,2.71,1.14v1.9c0,.36-.29,.82-.65,1.02Z"/>
        <path class="cls-11" d="M15.56,60.88s0,0-.02,0l-6.01,3.47c-.18,.1-.35,.13-.47,.07-.12-.06-.19-.2-.17-.38l.26-4.3c.02-.35,.3-.78,.65-.98l5.5-3.17c.34-.2,.63-.09,.65,.23l.25,3.86s.03,.11,.03,.17c0,.36-.29,.82-.65,1.02Zm-5.34,1.79l4.63-2.67-.18-2.75-4.28,2.47-.18,2.95Z"/>
      </g>
      <g>
        <g class="cls-46">
          <path class="cls-206" d="M21.11,35.56c0,2.39-1.94,5.44-4.32,6.82l-8.48,4.9c-2.39,1.38-4.32,.56-4.32-1.83v-8.48c0-2.39,1.94-5.44,4.32-6.82l8.48-4.9c2.39-1.38,4.32-.56,4.32,1.83v8.48Z"/>
        </g>
        <g>
          <path class="cls-11" d="M8.89,44.34l-1.27,.73c-.88,.51-1.59,.2-1.59-.67v-5.1c0-.88,.71-2,1.59-2.51l1.27-.73c.88-.51,1.59-.21,1.59,.67v5.1c0,.88-.71,2-1.59,2.51Zm-1.27-6.25c-.16,.09-.29,.3-.29,.46v5.1c0,.16,.13,.22,.29,.12l1.27-.73c.16-.09,.29-.3,.29-.46v-5.1c0-.16-.13-.22-.29-.12l-1.27,.73Z"/>
          <path class="cls-11" d="M14.58,41.22c-1.5,.87-4.16,2.29-5.16,2.07-.15-.03-.25-.17-.25-.37v-5.8c0-.32,.23-.72,.54-.95,.51-.52,2.07-2.84,2.11-3.33v-.27c0-1.83,0-3.04,1.19-3.73,.45-.26,.95-.29,1.33-.09,.32,.17,.84,.64,.73,1.93-.05,.5-.12,1.35,0,1.44h0s.07,0,.27-.12c.26-.15,.97-.54,1.32-.74l.19-.11c.2-.12,.4-.19,.59-.23,0,0,0,0,0,0,.03,0,.05-.01,.08-.01,.57-.05,.92,.3,.92,.93,0,.09,0,.24-.04,.45,.39,.07,.65,.39,.65,.92,0,.44-.16,.94-.44,1.4,.06,.16,.09,.37,.09,.62,0,.58-.3,1.28-.75,1.84,.02,.12,.02,.27,0,.44-.14,1.84-2.41,3.15-3.38,3.71Zm-4.1,.58c.61-.1,2.02-.68,4.1-1.88,.61-.35,2.04-1.18,2.08-1.77,.02-.21-.02-.31-.08-.43-.08-.14-.06-.36,.04-.6,.1-.23,.28-.45,.48-.59,.15-.1,.3-.38,.3-.55,0-.23-.04-.32-.06-.33-.18-.04-.26-.21-.23-.46,.03-.25,.2-.53,.42-.74,.07-.07,.22-.24,.22-.49,0-.17-.14-.24-.3-.14-.29,.17-.54,.12-.62-.11-.08-.23,.03-.59,.27-.89,.02-.03,.05-.08,.05-.24,0-.12-.06-.18-.17-.18,0,0-.01,0-.02,0-.04,0-.08,.02-.12,.04l-.22,.12c-.35,.19-1.04,.58-1.29,.72-.54,.31-.95,.4-1.23,.25-.5-.26-.42-1.14-.32-2.24,.08-.89-.33-1.17-.61-1.19-.05,.38-.05,1.05-.05,1.71v.27c0,1-1.62,3.48-2.65,4.67v5.03Z"/>
        </g>
      </g>
      <g>
        <path class="cls-39" d="M136.14,102.85l-48.33,27.84c-.81,.47-2.13,.47-2.94,0L12.36,89.2c-.41-.23-.61-.54-.61-.85v1.68c0,.31,.2,.61,.61,.85l72.51,41.5c.81,.46,2.13,.46,2.94,0l48.33-27.84c.4-.23,.6-.53,.6-.84v-1.68c0,.3-.2,.6-.6,.84Z"/>
        <path class="cls-59" d="M136.13,101.17c.81,.46,.82,1.22,0,1.68l-48.33,27.84c-.81,.47-2.13,.47-2.94,0L12.36,89.2c-.82-.47-.81-1.22,0-1.69l48.33-27.84c.81-.47,2.12-.47,2.93,0l72.51,41.5Z"/>
        <polygon class="cls-33" points="67.04 64.21 63.15 66.45 59.25 64.21 63.14 61.98 67.04 64.21"/>
        <polygon class="cls-33" points="71.99 67.04 68.1 69.28 64.2 67.04 68.08 64.81 71.99 67.04"/>
        <polygon class="cls-33" points="76.94 69.87 73.05 72.11 69.14 69.87 73.03 67.64 76.94 69.87"/>
        <polygon class="cls-33" points="81.88 72.7 78 74.94 74.09 72.7 77.98 70.47 81.88 72.7"/>
        <polygon class="cls-33" points="86.83 75.53 82.94 77.77 79.04 75.54 82.93 73.3 86.83 75.53"/>
        <polygon class="cls-33" points="91.78 78.36 87.89 80.6 83.98 78.36 87.87 76.13 91.78 78.36"/>
        <polygon class="cls-33" points="96.73 81.2 92.84 83.43 88.93 81.2 92.82 78.96 96.73 81.2"/>
        <polygon class="cls-33" points="101.67 84.03 97.79 86.26 93.88 84.03 97.77 81.79 101.67 84.03"/>
        <polygon class="cls-33" points="106.62 86.86 102.73 89.09 98.83 86.86 102.71 84.62 106.62 86.86"/>
        <polygon class="cls-33" points="111.57 89.69 107.68 91.93 103.77 89.69 107.66 87.45 111.57 89.69"/>
        <polygon class="cls-33" points="116.51 92.52 112.63 94.75 108.72 92.52 112.61 90.28 116.51 92.52"/>
        <polygon class="cls-33" points="121.46 95.35 117.57 97.59 113.67 95.35 117.55 93.11 121.46 95.35"/>
        <polygon class="cls-33" points="126.41 98.18 122.52 100.42 118.62 98.18 122.5 95.95 126.41 98.18"/>
        <polygon class="cls-33" points="131.35 101.01 127.47 103.25 123.56 101.01 127.45 98.78 131.35 101.01"/>
        <polygon class="cls-33" points="60.23 68.13 56.34 70.37 52.44 68.14 56.32 65.9 60.23 68.13"/>
        <polygon class="cls-33" points="65.17 70.96 61.29 73.2 57.39 70.97 61.27 68.73 65.17 70.96"/>
        <polygon class="cls-33" points="70.12 73.8 66.24 76.03 62.33 73.8 66.21 71.56 70.12 73.8"/>
        <polygon class="cls-33" points="75.07 76.62 71.18 78.86 67.28 76.63 71.16 74.39 75.07 76.62"/>
        <polygon class="cls-33" points="80.01 79.46 76.13 81.69 72.23 79.46 76.11 77.22 80.01 79.46"/>
        <polygon class="cls-33" points="84.96 82.29 81.08 84.53 77.17 82.29 81.05 80.05 84.96 82.29"/>
        <polygon class="cls-33" points="89.91 85.12 86.03 87.36 82.12 85.12 86 82.88 89.91 85.12"/>
        <polygon class="cls-33" points="94.86 87.95 90.97 90.19 87.07 87.95 90.95 85.72 94.86 87.95"/>
        <polygon class="cls-33" points="99.8 90.78 95.92 93.02 92.01 90.78 95.9 88.54 99.8 90.78"/>
        <polygon class="cls-33" points="104.75 93.61 100.87 95.85 96.96 93.61 100.84 91.38 104.75 93.61"/>
        <polygon class="cls-33" points="114.65 99.27 110.76 101.51 106.85 99.27 110.74 97.04 114.65 99.27"/>
        <polygon class="cls-33" points="119.59 102.11 115.71 104.34 111.8 102.11 115.69 99.87 119.59 102.11"/>
        <polygon class="cls-33" points="124.54 104.93 120.65 107.17 116.75 104.94 120.63 102.7 124.54 104.93"/>
        <polygon class="cls-33" points="55.51 70.85 51.64 73.08 47.73 70.85 51.61 68.61 55.51 70.85"/>
        <polygon class="cls-33" points="60.46 73.68 56.58 75.91 52.68 73.68 56.56 71.45 60.46 73.68"/>
        <polygon class="cls-33" points="65.41 76.51 61.53 78.74 57.62 76.51 61.5 74.27 65.41 76.51"/>
        <polygon class="cls-33" points="70.35 79.34 66.48 81.57 62.57 79.34 66.45 77.11 70.35 79.34"/>
        <polygon class="cls-33" points="75.3 82.17 71.42 84.41 67.52 82.17 71.4 79.94 75.3 82.17"/>
        <polygon class="cls-33" points="80.25 85 76.37 87.24 72.47 85 76.34 82.77 80.25 85"/>
        <polygon class="cls-33" points="85.2 87.84 81.32 90.07 77.41 87.83 81.29 85.6 85.2 87.84"/>
        <polygon class="cls-33" points="90.14 90.66 86.27 92.9 82.36 90.66 86.24 88.43 90.14 90.66"/>
        <polygon class="cls-33" points="95.09 93.5 91.21 95.73 87.31 93.49 91.18 91.26 95.09 93.5"/>
        <polygon class="cls-33" points="100.04 96.33 96.16 98.56 92.25 96.32 96.13 94.09 100.04 96.33"/>
        <polygon class="cls-33" points="109.93 101.99 106.06 104.22 102.15 101.99 106.02 99.75 109.93 101.99"/>
        <polygon class="cls-33" points="114.88 104.82 111 107.05 107.1 104.82 110.97 102.58 114.88 104.82"/>
        <polygon class="cls-33" points="119.82 107.65 115.95 109.88 112.04 107.65 115.92 105.42 119.82 107.65"/>
        <polygon class="cls-33" points="50.8 73.56 46.92 75.79 43.02 73.56 46.9 71.33 50.8 73.56"/>
        <polygon class="cls-33" points="55.75 76.39 51.87 78.63 47.97 76.39 51.85 74.16 55.75 76.39"/>
        <polygon class="cls-33" points="60.7 79.23 56.82 81.46 52.91 79.22 56.79 76.99 60.7 79.23"/>
        <polygon class="cls-33" points="65.64 82.05 61.76 84.29 57.86 82.05 61.74 79.82 65.64 82.05"/>
        <polygon class="cls-33" points="70.59 84.89 66.71 87.12 62.81 84.89 66.69 82.65 70.59 84.89"/>
        <polygon class="cls-33" points="75.54 87.72 71.66 89.95 67.75 87.71 71.63 85.48 75.54 87.72"/>
        <polygon class="cls-33" points="80.49 90.55 76.61 92.78 72.7 90.55 76.58 88.31 80.49 90.55"/>
        <polygon class="cls-33" points="85.43 93.38 81.55 95.61 77.65 93.38 81.53 91.15 85.43 93.38"/>
        <polygon class="cls-33" points="46.09 76.27 42.21 78.51 38.31 76.28 42.19 74.04 46.09 76.27"/>
        <polygon class="cls-33" points="51.04 79.1 47.16 81.34 43.26 79.11 47.14 76.87 51.04 79.1"/>
        <polygon class="cls-33" points="55.99 81.94 52.11 84.17 48.2 81.94 52.08 79.7 55.99 81.94"/>
        <polygon class="cls-33" points="60.93 84.77 57.05 87 53.15 84.77 57.03 82.53 60.93 84.77"/>
        <polygon class="cls-33" points="65.88 87.6 62 89.83 58.1 87.6 61.98 85.36 65.88 87.6"/>
        <polygon class="cls-33" points="70.83 90.43 66.95 92.67 63.04 90.43 66.92 88.19 70.83 90.43"/>
        <polygon class="cls-33" points="75.78 93.26 71.9 95.5 67.99 93.26 71.87 91.02 75.78 93.26"/>
        <polygon class="cls-33" points="80.72 96.09 76.84 98.33 72.94 96.09 76.82 93.86 80.72 96.09"/>
        <polygon class="cls-33" points="90.38 96.21 86.5 98.44 82.59 96.21 86.47 93.97 90.38 96.21"/>
        <polygon class="cls-33" points="95.33 99.04 90.62 101.75 87.34 103.65 86.74 103.99 77.88 98.92 81.76 96.69 86.71 99.52 91.42 96.81 95.33 99.04"/>
        <polygon class="cls-33" points="105.22 104.7 101.34 106.94 97.43 104.7 101.31 102.47 105.22 104.7"/>
        <polygon class="cls-33" points="110.17 107.54 106.29 109.77 102.38 107.53 106.26 105.3 110.17 107.54"/>
        <polygon class="cls-33" points="115.11 110.36 111.23 112.6 107.33 110.36 111.21 108.13 115.11 110.36"/>
        <polygon class="cls-33" points="100.51 107.41 96.63 109.65 92.72 107.41 96.6 105.18 100.51 107.41"/>
        <polygon class="cls-33" points="105.46 110.25 101.58 112.48 97.67 110.25 101.55 108.01 105.46 110.25"/>
        <polygon class="cls-33" points="110.41 113.07 106.52 115.31 102.62 113.08 106.5 110.84 110.41 113.07"/>
        <polygon class="cls-33" points="46.33 81.82 42.45 84.05 33.6 78.99 37.48 76.75 46.33 81.82"/>
        <polygon class="cls-33" points="51.28 84.65 47.4 86.88 43.49 84.65 47.37 82.41 51.28 84.65"/>
        <polygon class="cls-33" points="56.22 87.48 52.34 89.71 48.44 87.48 52.32 85.25 56.22 87.48"/>
        <polygon class="cls-33" points="61.17 90.31 57.29 92.54 53.39 90.31 57.27 88.08 61.17 90.31"/>
        <polygon class="cls-33" points="66.12 93.14 62.24 95.38 58.33 93.14 62.21 90.91 66.12 93.14"/>
        <polygon class="cls-33" points="71.07 95.98 67.19 98.21 63.28 95.97 67.16 93.74 71.07 95.98"/>
        <polygon class="cls-33" points="85.91 104.47 82.03 106.7 68.23 98.8 72.11 96.57 85.91 104.47"/>
        <polygon class="cls-33" points="95.8 110.13 91.92 112.36 88.01 110.12 91.89 107.89 95.8 110.13"/>
        <polygon class="cls-33" points="100.75 112.96 96.87 115.19 92.96 112.96 96.84 110.72 100.75 112.96"/>
        <polygon class="cls-33" points="105.69 115.79 101.82 118.02 97.91 115.79 101.79 113.56 105.69 115.79"/>
        <polygon class="cls-33" points="36.67 81.7 32.79 83.93 28.89 81.7 32.77 79.46 36.67 81.7"/>
        <polygon class="cls-33" points="41.62 84.53 37.74 86.77 33.84 84.53 37.72 82.3 41.62 84.53"/>
        <polygon class="cls-33" points="71.3 101.52 67.42 103.75 38.78 87.36 42.66 85.13 71.3 101.52"/>
        <polygon class="cls-33" points="76.25 104.35 72.37 106.58 68.46 104.35 72.34 102.11 76.25 104.35"/>
        <polygon class="cls-33" points="81.2 107.18 77.32 109.42 73.41 107.18 77.29 104.94 81.2 107.18"/>
        <polygon class="cls-33" points="91.09 112.84 87.21 115.08 83.3 112.84 87.19 110.6 91.09 112.84"/>
        <polygon class="cls-33" points="96.04 115.67 92.16 117.91 88.25 115.67 92.13 113.44 96.04 115.67"/>
        <g>
          <polygon class="cls-77" points="67.04 64.21 67.04 64.63 63.15 66.86 63.15 66.45 67.04 64.21"/>
          <polygon class="cls-77" points="63.15 66.45 63.15 66.86 59.25 64.63 59.25 64.21 63.15 66.45"/>
          <polygon class="cls-77" points="71.99 67.04 71.99 67.46 68.1 69.7 68.1 69.28 71.99 67.04"/>
          <polygon class="cls-77" points="68.1 69.28 68.1 69.7 64.2 67.46 64.2 67.04 68.1 69.28"/>
          <polygon class="cls-77" points="76.94 69.87 76.93 70.29 73.05 72.53 73.05 72.11 76.94 69.87"/>
          <polygon class="cls-77" points="73.05 72.11 73.05 72.53 69.14 70.29 69.14 69.87 73.05 72.11"/>
          <polygon class="cls-77" points="81.88 72.7 81.88 73.12 77.99 75.36 78 74.94 81.88 72.7"/>
          <polygon class="cls-77" points="78 74.94 77.99 75.36 74.09 73.12 74.09 72.7 78 74.94"/>
          <polygon class="cls-77" points="86.83 75.53 86.83 75.95 82.94 78.19 82.94 77.77 86.83 75.53"/>
          <polygon class="cls-77" points="82.94 77.77 82.94 78.19 79.04 75.96 79.04 75.54 82.94 77.77"/>
          <polygon class="cls-77" points="91.78 78.36 91.78 78.78 87.89 81.02 87.89 80.6 91.78 78.36"/>
          <polygon class="cls-77" points="87.89 80.6 87.89 81.02 83.98 78.78 83.98 78.36 87.89 80.6"/>
          <polygon class="cls-77" points="96.73 81.2 96.72 81.62 92.84 83.85 92.84 83.43 96.73 81.2"/>
          <polygon class="cls-77" points="92.84 83.43 92.84 83.85 88.93 81.62 88.93 81.2 92.84 83.43"/>
          <polygon class="cls-77" points="101.67 84.03 101.67 84.45 97.78 86.68 97.79 86.26 101.67 84.03"/>
          <polygon class="cls-77" points="97.79 86.26 97.78 86.68 93.88 84.45 93.88 84.03 97.79 86.26"/>
          <polygon class="cls-77" points="106.62 86.86 106.62 87.28 102.73 89.51 102.73 89.09 106.62 86.86"/>
          <polygon class="cls-77" points="102.73 89.09 102.73 89.51 98.82 87.28 98.83 86.86 102.73 89.09"/>
          <polygon class="cls-77" points="111.57 89.69 111.57 90.11 107.68 92.35 107.68 91.93 111.57 89.69"/>
          <polygon class="cls-77" points="107.68 91.93 107.68 92.35 103.77 90.11 103.77 89.69 107.68 91.93"/>
          <polygon class="cls-77" points="116.51 92.52 116.51 92.94 112.62 95.17 112.63 94.75 116.51 92.52"/>
          <polygon class="cls-77" points="112.63 94.75 112.62 95.17 108.72 92.94 108.72 92.52 112.63 94.75"/>
          <polygon class="cls-77" points="121.46 95.35 121.46 95.77 117.57 98.01 117.57 97.59 121.46 95.35"/>
          <polygon class="cls-77" points="117.57 97.59 117.57 98.01 113.66 95.77 113.67 95.35 117.57 97.59"/>
          <polygon class="cls-77" points="126.41 98.18 126.41 98.6 122.52 100.84 122.52 100.42 126.41 98.18"/>
          <polygon class="cls-77" points="122.52 100.42 122.52 100.84 118.61 98.6 118.62 98.18 122.52 100.42"/>
          <polygon class="cls-77" points="131.35 101.01 131.35 101.43 127.46 103.67 127.47 103.25 131.35 101.01"/>
          <polygon class="cls-77" points="127.47 103.25 127.46 103.67 123.56 101.43 123.56 101.01 127.47 103.25"/>
          <polygon class="cls-77" points="60.23 68.13 60.22 68.55 56.34 70.79 56.34 70.37 60.23 68.13"/>
          <polygon class="cls-77" points="56.34 70.37 56.34 70.79 52.44 68.56 52.44 68.14 56.34 70.37"/>
          <polygon class="cls-77" points="65.17 70.96 65.17 71.38 61.29 73.62 61.29 73.2 65.17 70.96"/>
          <polygon class="cls-77" points="61.29 73.2 61.29 73.62 57.39 71.39 57.39 70.97 61.29 73.2"/>
          <polygon class="cls-77" points="70.12 73.8 70.12 74.22 66.24 76.45 66.24 76.03 70.12 73.8"/>
          <polygon class="cls-77" points="66.24 76.03 66.24 76.45 62.33 74.22 62.33 73.8 66.24 76.03"/>
          <polygon class="cls-77" points="75.07 76.62 75.06 77.04 71.18 79.28 71.18 78.86 75.07 76.62"/>
          <polygon class="cls-77" points="71.18 78.86 71.18 79.28 67.28 77.05 67.28 76.63 71.18 78.86"/>
          <polygon class="cls-77" points="80.01 79.46 80.01 79.88 76.13 82.11 76.13 81.69 80.01 79.46"/>
          <polygon class="cls-77" points="76.13 81.69 76.13 82.11 72.23 79.88 72.23 79.46 76.13 81.69"/>
          <polygon class="cls-77" points="84.96 82.29 84.96 82.71 81.08 84.95 81.08 84.53 84.96 82.29"/>
          <polygon class="cls-77" points="81.08 84.53 81.08 84.95 77.17 82.71 77.17 82.29 81.08 84.53"/>
          <polygon class="cls-77" points="89.91 85.12 89.91 85.54 86.03 87.78 86.03 87.36 89.91 85.12"/>
          <polygon class="cls-77" points="86.03 87.36 86.03 87.78 82.12 85.54 82.12 85.12 86.03 87.36"/>
          <polygon class="cls-77" points="94.86 87.95 94.85 88.37 90.97 90.61 90.97 90.19 94.86 87.95"/>
          <polygon class="cls-77" points="90.97 90.19 90.97 90.61 87.07 88.37 87.07 87.95 90.97 90.19"/>
          <polygon class="cls-77" points="99.8 90.78 99.8 91.2 95.92 93.44 95.92 93.02 99.8 90.78"/>
          <polygon class="cls-77" points="95.92 93.02 95.92 93.44 92.01 91.2 92.01 90.78 95.92 93.02"/>
          <polygon class="cls-77" points="104.75 93.61 104.75 94.03 100.87 96.27 100.87 95.85 104.75 93.61"/>
          <polygon class="cls-77" points="100.87 95.85 100.87 96.27 96.96 94.03 96.96 93.61 100.87 95.85"/>
          <polygon class="cls-77" points="114.65 99.27 114.64 99.69 110.76 101.93 110.76 101.51 114.65 99.27"/>
          <polygon class="cls-77" points="110.76 101.51 110.76 101.93 106.85 99.69 106.85 99.27 110.76 101.51"/>
          <polygon class="cls-77" points="119.59 102.11 119.59 102.53 115.71 104.76 115.71 104.34 119.59 102.11"/>
          <polygon class="cls-77" points="115.71 104.34 115.71 104.76 111.8 102.53 111.8 102.11 115.71 104.34"/>
          <polygon class="cls-77" points="124.54 104.93 124.54 105.35 120.65 107.59 120.65 107.17 124.54 104.93"/>
          <polygon class="cls-77" points="120.65 107.17 120.65 107.59 116.75 105.36 116.75 104.94 120.65 107.17"/>
          <polygon class="cls-77" points="55.51 70.85 55.51 71.27 51.63 73.5 51.64 73.08 55.51 70.85"/>
          <polygon class="cls-77" points="51.64 73.08 51.63 73.5 47.73 71.27 47.73 70.85 51.64 73.08"/>
          <polygon class="cls-77" points="60.46 73.68 60.46 74.1 56.58 76.33 56.58 75.91 60.46 73.68"/>
          <polygon class="cls-77" points="56.58 75.91 56.58 76.33 52.68 74.1 52.68 73.68 56.58 75.91"/>
          <polygon class="cls-77" points="65.41 76.51 65.41 76.93 61.53 79.16 61.53 78.74 65.41 76.51"/>
          <polygon class="cls-77" points="61.53 78.74 61.53 79.16 57.62 76.93 57.62 76.51 61.53 78.74"/>
          <polygon class="cls-77" points="70.35 79.34 70.35 79.76 66.47 81.99 66.48 81.57 70.35 79.34"/>
          <polygon class="cls-77" points="66.48 81.57 66.47 81.99 62.57 79.76 62.57 79.34 66.48 81.57"/>
          <polygon class="cls-77" points="75.3 82.17 75.3 82.59 71.42 84.82 71.42 84.41 75.3 82.17"/>
          <polygon class="cls-77" points="71.42 84.41 71.42 84.82 67.52 82.59 67.52 82.17 71.42 84.41"/>
          <polygon class="cls-77" points="80.25 85 80.25 85.42 76.37 87.66 76.37 87.24 80.25 85"/>
          <polygon class="cls-77" points="76.37 87.24 76.37 87.66 72.46 85.42 72.47 85 76.37 87.24"/>
          <polygon class="cls-77" points="85.2 87.84 85.2 88.25 81.32 90.49 81.32 90.07 85.2 87.84"/>
          <polygon class="cls-77" points="81.32 90.07 81.32 90.49 77.41 88.25 77.41 87.83 81.32 90.07"/>
          <polygon class="cls-77" points="90.14 90.66 90.14 91.08 86.26 93.32 86.27 92.9 90.14 90.66"/>
          <polygon class="cls-77" points="86.27 92.9 86.26 93.32 82.36 91.08 82.36 90.66 86.27 92.9"/>
          <polygon class="cls-77" points="95.09 93.5 95.09 93.92 91.21 96.15 91.21 95.73 95.09 93.5"/>
          <polygon class="cls-77" points="91.21 95.73 91.21 96.15 87.3 93.91 87.31 93.49 91.21 95.73"/>
          <polygon class="cls-77" points="100.04 96.33 100.04 96.75 96.16 98.98 96.16 98.56 100.04 96.33"/>
          <polygon class="cls-77" points="96.16 98.56 96.16 98.98 92.25 96.74 92.25 96.32 96.16 98.56"/>
          <polygon class="cls-77" points="109.93 101.99 109.93 102.41 106.05 104.64 106.06 104.22 109.93 101.99"/>
          <polygon class="cls-77" points="106.06 104.22 106.05 104.64 102.15 102.4 102.15 101.99 106.06 104.22"/>
          <polygon class="cls-77" points="114.88 104.82 114.88 105.24 111 107.47 111 107.05 114.88 104.82"/>
          <polygon class="cls-77" points="111 107.05 111 107.47 107.09 105.24 107.1 104.82 111 107.05"/>
          <polygon class="cls-77" points="119.82 107.65 119.82 108.07 115.95 110.3 115.95 109.88 119.82 107.65"/>
          <polygon class="cls-77" points="115.95 109.88 115.95 110.3 112.04 108.07 112.04 107.65 115.95 109.88"/>
          <polygon class="cls-77" points="50.8 73.56 50.8 73.98 46.92 76.21 46.92 75.79 50.8 73.56"/>
          <polygon class="cls-77" points="46.92 75.79 46.92 76.21 43.02 73.98 43.02 73.56 46.92 75.79"/>
          <polygon class="cls-77" points="55.75 76.39 55.75 76.81 51.87 79.05 51.87 78.63 55.75 76.39"/>
          <polygon class="cls-77" points="51.87 78.63 51.87 79.05 47.97 76.81 47.97 76.39 51.87 78.63"/>
          <polygon class="cls-77" points="60.7 79.23 60.7 79.65 56.82 81.88 56.82 81.46 60.7 79.23"/>
          <polygon class="cls-77" points="56.82 81.46 56.82 81.88 52.91 79.64 52.91 79.22 56.82 81.46"/>
          <polygon class="cls-77" points="65.64 82.05 65.64 82.47 61.76 84.71 61.76 84.29 65.64 82.05"/>
          <polygon class="cls-77" points="61.76 84.29 61.76 84.71 57.86 82.47 57.86 82.05 61.76 84.29"/>
          <polygon class="cls-77" points="70.59 84.89 70.59 85.31 66.71 87.54 66.71 87.12 70.59 84.89"/>
          <polygon class="cls-77" points="66.71 87.12 66.71 87.54 62.81 85.31 62.81 84.89 66.71 87.12"/>
          <polygon class="cls-77" points="75.54 87.72 75.54 88.14 71.66 90.37 71.66 89.95 75.54 87.72"/>
          <polygon class="cls-77" points="71.66 89.95 71.66 90.37 67.75 88.13 67.75 87.71 71.66 89.95"/>
          <polygon class="cls-77" points="80.49 90.55 80.49 90.97 76.61 93.2 76.61 92.78 80.49 90.55"/>
          <polygon class="cls-77" points="76.61 92.78 76.61 93.2 72.7 90.97 72.7 90.55 76.61 92.78"/>
          <polygon class="cls-77" points="85.43 93.38 85.43 93.8 81.55 96.03 81.55 95.61 85.43 93.38"/>
          <polygon class="cls-77" points="81.55 95.61 81.55 96.03 77.65 93.8 77.65 93.38 81.55 95.61"/>
          <polygon class="cls-77" points="46.09 76.27 46.09 76.69 42.21 78.93 42.21 78.51 46.09 76.27"/>
          <polygon class="cls-77" points="42.21 78.51 42.21 78.93 38.31 76.7 38.31 76.28 42.21 78.51"/>
          <polygon class="cls-77" points="51.04 79.1 51.04 79.52 47.16 81.76 47.16 81.34 51.04 79.1"/>
          <polygon class="cls-77" points="47.16 81.34 47.16 81.76 43.25 79.53 43.26 79.11 47.16 81.34"/>
          <polygon class="cls-77" points="55.99 81.94 55.99 82.36 52.11 84.59 52.11 84.17 55.99 81.94"/>
          <polygon class="cls-77" points="52.11 84.17 52.11 84.59 48.2 82.36 48.2 81.94 52.11 84.17"/>
          <polygon class="cls-77" points="60.93 84.77 60.93 85.18 57.05 87.42 57.05 87 60.93 84.77"/>
          <polygon class="cls-77" points="57.05 87 57.05 87.42 53.15 85.19 53.15 84.77 57.05 87"/>
          <polygon class="cls-77" points="65.88 87.6 65.88 88.02 62 90.25 62 89.83 65.88 87.6"/>
          <polygon class="cls-77" points="62 89.83 62 90.25 58.1 88.02 58.1 87.6 62 89.83"/>
          <polygon class="cls-77" points="70.83 90.43 70.83 90.85 66.95 93.09 66.95 92.67 70.83 90.43"/>
          <polygon class="cls-77" points="66.95 92.67 66.95 93.09 63.04 90.85 63.04 90.43 66.95 92.67"/>
          <polygon class="cls-77" points="75.78 93.26 75.78 93.68 71.9 95.92 71.9 95.5 75.78 93.26"/>
          <polygon class="cls-77" points="71.9 95.5 71.9 95.92 67.99 93.68 67.99 93.26 71.9 95.5"/>
          <polygon class="cls-77" points="80.72 96.09 80.72 96.51 76.84 98.75 76.84 98.33 80.72 96.09"/>
          <polygon class="cls-77" points="76.84 98.33 76.84 98.75 72.94 96.51 72.94 96.09 76.84 98.33"/>
          <polygon class="cls-77" points="90.38 96.21 90.38 96.63 86.5 98.86 86.5 98.44 90.38 96.21"/>
          <polygon class="cls-77" points="86.5 98.44 86.5 98.86 82.59 96.63 82.59 96.21 86.5 98.44"/>
          <polygon class="cls-77" points="95.33 99.04 95.33 99.46 90.62 102.17 90.62 101.75 95.33 99.04"/>
          <polygon class="cls-77" points="90.62 101.75 90.62 102.17 87.34 104.07 87.34 103.65 90.62 101.75"/>
          <polygon class="cls-77" points="87.34 103.65 87.34 104.07 86.74 104.41 86.74 103.99 87.34 103.65"/>
          <polygon class="cls-77" points="86.74 103.99 86.74 104.41 77.88 99.34 77.88 98.92 86.74 103.99"/>
          <polygon class="cls-77" points="105.22 104.7 105.22 105.12 101.34 107.36 101.34 106.94 105.22 104.7"/>
          <polygon class="cls-77" points="101.34 106.94 101.34 107.36 97.43 105.12 97.43 104.7 101.34 106.94"/>
          <polygon class="cls-77" points="110.17 107.54 110.17 107.95 106.29 110.19 106.29 109.77 110.17 107.54"/>
          <polygon class="cls-77" points="106.29 109.77 106.29 110.19 102.38 107.95 102.38 107.53 106.29 109.77"/>
          <polygon class="cls-77" points="115.11 110.36 115.11 110.78 111.23 113.02 111.23 112.6 115.11 110.36"/>
          <polygon class="cls-77" points="111.23 112.6 111.23 113.02 107.33 110.78 107.33 110.36 111.23 112.6"/>
          <polygon class="cls-77" points="100.51 107.41 100.51 107.83 96.63 110.07 96.63 109.65 100.51 107.41"/>
          <polygon class="cls-77" points="96.63 109.65 96.63 110.07 92.72 107.83 92.72 107.41 96.63 109.65"/>
          <polygon class="cls-77" points="105.46 110.25 105.46 110.67 101.58 112.9 101.58 112.48 105.46 110.25"/>
          <polygon class="cls-77" points="101.58 112.48 101.58 112.9 97.67 110.67 97.67 110.25 101.58 112.48"/>
          <polygon class="cls-77" points="110.41 113.07 110.4 113.49 106.52 115.73 106.52 115.31 110.41 113.07"/>
          <polygon class="cls-77" points="106.52 115.31 106.52 115.73 102.62 113.5 102.62 113.08 106.52 115.31"/>
          <polygon class="cls-77" points="46.33 81.82 46.33 82.24 42.45 84.47 42.45 84.05 46.33 81.82"/>
          <polygon class="cls-77" points="42.45 84.05 42.45 84.47 33.6 79.41 33.6 78.99 42.45 84.05"/>
          <polygon class="cls-77" points="51.28 84.65 51.28 85.07 47.4 87.3 47.4 86.88 51.28 84.65"/>
          <polygon class="cls-77" points="47.4 86.88 47.4 87.3 43.49 85.07 43.49 84.65 47.4 86.88"/>
          <polygon class="cls-77" points="56.22 87.48 56.22 87.9 52.34 90.13 52.34 89.71 56.22 87.48"/>
          <polygon class="cls-77" points="52.34 89.71 52.34 90.13 48.44 87.9 48.44 87.48 52.34 89.71"/>
          <polygon class="cls-77" points="61.17 90.31 61.17 90.73 57.29 92.96 57.29 92.54 61.17 90.31"/>
          <polygon class="cls-77" points="57.29 92.54 57.29 92.96 53.39 90.73 53.39 90.31 57.29 92.54"/>
          <polygon class="cls-77" points="66.12 93.14 66.12 93.56 62.24 95.8 62.24 95.38 66.12 93.14"/>
          <polygon class="cls-77" points="62.24 95.38 62.24 95.8 58.33 93.56 58.33 93.14 62.24 95.38"/>
          <polygon class="cls-77" points="71.07 95.98 71.07 96.4 67.19 98.63 67.19 98.21 71.07 95.98"/>
          <polygon class="cls-77" points="67.19 98.21 67.19 98.63 63.28 96.39 63.28 95.97 67.19 98.21"/>
          <polygon class="cls-77" points="85.91 104.47 85.91 104.89 82.03 107.12 82.03 106.7 85.91 104.47"/>
          <polygon class="cls-77" points="82.03 106.7 82.03 107.12 68.23 99.22 68.23 98.8 82.03 106.7"/>
          <polygon class="cls-77" points="95.8 110.13 95.8 110.55 91.92 112.78 91.92 112.36 95.8 110.13"/>
          <polygon class="cls-77" points="91.92 112.36 91.92 112.78 88.01 110.54 88.01 110.12 91.92 112.36"/>
          <polygon class="cls-77" points="100.75 112.96 100.75 113.38 96.87 115.61 96.87 115.19 100.75 112.96"/>
          <polygon class="cls-77" points="96.87 115.19 96.87 115.61 92.96 113.38 92.96 112.96 96.87 115.19"/>
          <polygon class="cls-77" points="105.69 115.79 105.69 116.21 101.81 118.44 101.82 118.02 105.69 115.79"/>
          <polygon class="cls-77" points="101.82 118.02 101.81 118.44 97.91 116.21 97.91 115.79 101.82 118.02"/>
          <polygon class="cls-77" points="36.67 81.7 36.67 82.12 32.79 84.35 32.79 83.93 36.67 81.7"/>
          <polygon class="cls-77" points="32.79 83.93 32.79 84.35 28.89 82.12 28.89 81.7 32.79 83.93"/>
          <polygon class="cls-77" points="41.62 84.53 41.62 84.95 37.74 87.19 37.74 86.77 41.62 84.53"/>
          <polygon class="cls-77" points="37.74 86.77 37.74 87.19 33.84 84.95 33.84 84.53 37.74 86.77"/>
          <polygon class="cls-77" points="71.3 101.52 71.3 101.93 67.42 104.17 67.42 103.75 71.3 101.52"/>
          <polygon class="cls-77" points="67.42 103.75 67.42 104.17 38.78 87.78 38.78 87.36 67.42 103.75"/>
          <polygon class="cls-77" points="76.25 104.35 76.25 104.77 72.37 107 72.37 106.58 76.25 104.35"/>
          <polygon class="cls-77" points="72.37 106.58 72.37 107 68.46 104.77 68.46 104.35 72.37 106.58"/>
          <polygon class="cls-77" points="81.2 107.18 81.2 107.6 77.32 109.84 77.32 109.42 81.2 107.18"/>
          <polygon class="cls-77" points="77.32 109.42 77.32 109.84 73.41 107.6 73.41 107.18 77.32 109.42"/>
          <polygon class="cls-77" points="91.09 112.84 91.09 113.26 87.21 115.5 87.21 115.08 91.09 112.84"/>
          <polygon class="cls-77" points="87.21 115.08 87.21 115.5 83.3 113.26 83.3 112.84 87.21 115.08"/>
          <polygon class="cls-77" points="96.04 115.67 96.04 116.09 92.16 118.33 92.16 117.91 96.04 115.67"/>
          <polygon class="cls-77" points="92.16 117.91 92.16 118.33 88.25 116.09 88.25 115.67 92.16 117.91"/>
          <polygon class="cls-77" points="100.99 118.5 100.98 118.92 97.1 121.16 97.1 120.74 100.99 118.5"/>
          <polygon class="cls-77" points="97.1 120.74 97.1 121.16 93.2 118.92 93.2 118.5 97.1 120.74"/>
        </g>
        <polygon class="cls-33" points="100.99 118.5 97.1 120.74 93.2 118.5 97.08 116.27 100.99 118.5"/>
        <g class="cls-53">
          <path class="cls-11" d="M44.18,102.02l-1.75,1c-.4,.23-.41,.61,0,.84l8.04,4.6,2.47-1.43-8.77-5.02Z"/>
          <path class="cls-11" d="M51.08,108.81l8.04,4.6c.41,.24,1.06,.23,1.47,0l1.74-1-8.78-5.02-2.47,1.43Z"/>
          <path class="cls-11" d="M69.73,107.31l-16.68-9.55c-.41-.24-1.06-.23-1.47,0l-6.81,3.92,18.15,10.39,6.81-3.92c.4-.23,.41-.61,0-.84Z"/>
        </g>
        <polygon class="cls-8" points="133.06 105.07 130.02 106.81 130.02 107.63 133.07 105.89 133.06 105.07"/>
        <g>
          <path class="cls-48" d="M137.95,41.69L65.44,.2c-.41-.24-.78-.26-1.05-.1l-1.46,.84c.27-.15,.64-.13,1.05,.1l72.51,41.49c.82,.47,1.47,1.6,1.47,2.52l-.16,55.41c0,.46-.17,.78-.43,.93l1.46-.84c.26-.15,.43-.48,.43-.94l.16-55.41c0-.92-.65-2.05-1.47-2.52Z"/>
          <path class="cls-44" d="M136.49,42.53c.82,.47,1.47,1.6,1.47,2.52l-.16,55.41c0,.92-.66,1.3-1.48,.83L63.81,59.8c-.82-.47-1.48-1.6-1.47-2.52l.16-55.41c0-.92,.67-1.3,1.48-.83l72.51,41.49Z"/>
          <polygon class="cls-199" points="67.33 56.11 67.46 8.73 132.97 46.22 132.83 93.6 67.33 56.11"/>
          <polygon class="cls-117" points="67.53 55.99 67.67 9.09 132.76 46.34 132.63 93.24 67.53 55.99"/>
          <g class="cls-7">
            <path class="cls-108" d="M102,25.52c0-.12-.02-.23-.06-.36-.02-.13-.07-.26-.13-.39-.15-.32-.36-.62-.61-.84-.1-.1-.21-.19-.32-.25-.63-.36-1.13-.07-1.14,.63,0,.18,.03,.36,.09,.54,.1,.33,.28,.66,.5,.92,.08,.11,.18,.22,.27,.28,.09,.08,.17,.15,.27,.2h.03c.62,.34,1.1,.06,1.1-.64v-.08Z"/>
          </g>
          <g class="cls-70">
            <path class="cls-50" d="M102.8,25.92c0-.2-.03-.4-.1-.62-.04-.21-.13-.45-.22-.67-.26-.54-.61-1.07-1.05-1.44-.17-.18-.36-.32-.55-.43-1.07-.61-1.94-.11-1.94,1.09,0,.31,.06,.61,.15,.92,.17,.57,.47,1.13,.86,1.57,.14,.19,.31,.37,.47,.48,.15,.14,.3,.25,.46,.34l.05,.02c1.05,.57,1.88,.08,1.88-1.12v-.14Z"/>
          </g>
          <polygon class="cls-135" points="127.2 48.01 130.62 52.59 130.64 47.35 127.2 48.01"/>
          <g>
            <polygon class="cls-135" points="123.13 43.05 123.13 43.05 123.13 42.56 123.13 43.05"/>
            <polygon class="cls-135" points="126.55 47.63 123.13 43.05 123.11 48.29 126.55 47.63"/>
          </g>
          <polygon class="cls-39" points="130.64 46.85 126.87 47.57 123.13 42.56 123.11 43.32 126.29 47.68 123.11 48.29 130.62 52.59 127.2 48.01 130.64 47.35 130.64 46.85"/>
          <polygon class="cls-13" points="123.13 42.56 126.87 47.57 130.64 46.85 123.13 42.56"/>
          <polygon class="cls-12" points="69.56 48.96 69.56 49.87 130.53 84.77 130.54 83.86 69.56 48.96"/>
          <polygon class="cls-12" points="69.56 50.83 69.55 51.74 130.53 86.63 130.53 85.73 69.56 50.83"/>
          <polygon class="cls-12" points="69.55 52.7 69.55 53.61 130.52 88.5 130.52 87.6 69.55 52.7"/>
          <polygon class="cls-12" points="69.55 54.57 69.54 55.47 130.52 90.37 130.52 89.46 69.55 54.57"/>
          <g class="cls-84">
            <path class="cls-11" d="M120.07,42.28c-.2-.12-.41-.14-.56-.06-.31,.17-.31,.75-.01,1.27,.15,.26,.36,.47,.56,.59,.2,.12,.41,.15,.56,.06,.31-.18,.31-.75,0-1.28-.15-.26-.36-.47-.56-.58Z"/>
            <path class="cls-11" d="M69.48,12.45v4.14s52.33,29.93,52.33,29.93v-4.14S69.48,12.45,69.48,12.45Zm51.44,32.19c-.23,.14-.54,.09-.84-.08-.21-.12-.41-.3-.6-.52h0s-.59,.34-.59,.34c-.06,.04-.15,.02-.24-.03-.08-.05-.17-.13-.23-.24-.13-.22-.13-.46,0-.54l.59-.34h.01c-.24-.66-.18-1.28,.21-1.5,.24-.13,.54-.09,.85,.08,.31,.18,.62,.49,.85,.89,.47,.8,.47,1.67,0,1.94Z"/>
          </g>
          <path class="cls-51" d="M120.92,42.7c-.23-.4-.54-.71-.85-.89-.31-.18-.61-.22-.85-.08-.39,.22-.45,.84-.21,1.5h-.01l-.59,.34c-.13,.07-.13,.32,0,.54,.06,.11,.15,.2,.23,.24,.09,.05,.17,.06,.24,.03l.59-.33h0c.19,.21,.39,.39,.6,.51,.31,.18,.61,.22,.84,.08,.47-.27,.47-1.13,0-1.94Zm-.29,1.44c-.16,.09-.36,.06-.56-.06-.21-.12-.41-.32-.56-.59-.3-.52-.3-1.1,.01-1.27,.16-.09,.36-.06,.56,.06,.2,.12,.41,.32,.56,.58,.31,.53,.31,1.1,0,1.28Z"/>
        </g>
      </g>
      <g>
        <polygon class="cls-11" points="88.92 57.59 71.28 47.41 71.28 20.43 88.92 30.61 88.92 57.59"/>
        <polygon class="cls-17" points="88.19 47.25 72.01 37.91 72.01 21.74 88.19 31.08 88.19 47.25"/>
        <polygon class="cls-11" points="108.53 68.91 90.89 58.73 90.89 31.75 108.53 41.93 108.53 68.91"/>
        <polygon class="cls-45" points="107.8 58.57 91.62 49.23 91.62 33.06 107.8 42.4 107.8 58.57"/>
        <polygon class="cls-11" points="127.9 80.09 110.27 69.91 110.27 42.94 127.9 53.12 127.9 80.09"/>
        <polygon class="cls-198" points="127.17 69.76 111 60.42 111 44.24 127.17 53.58 127.17 69.76"/>
      </g>
      <g>
        <g class="cls-145">
          <rect class="cls-199" x="71.27" y="79.41" width="7.44" height="7.85"/>
          <path class="cls-199" d="M71.61,80.92c-.95,.86-.57,2.82,.85,4.38,1.42,1.56,3.33,2.13,4.28,1.26,.95-.86,.57-2.82-.85-4.38-1.42-1.56-3.33-2.12-4.28-1.26Z"/>
          <path class="cls-199" d="M71.61,80.93c-.95,.86-.57,2.82,.85,4.38,1.42,1.56,3.33,2.12,4.28,1.26,.95-.86,.57-2.82-.85-4.38-1.41-1.56-3.33-2.12-4.28-1.26Z"/>
          <path class="cls-176" d="M71.61,80.93c-.95,.86-.57,2.82,.85,4.37,1.41,1.55,3.33,2.12,4.27,1.26,.95-.86,.57-2.82-.85-4.37-1.41-1.56-3.33-2.12-4.27-1.26Z"/>
          <path class="cls-14" d="M71.62,80.93c-.94,.86-.57,2.81,.85,4.37,1.41,1.55,3.32,2.12,4.27,1.26,.94-.86,.57-2.81-.85-4.37-1.41-1.55-3.32-2.12-4.27-1.26Z"/>
          <path class="cls-161" d="M71.62,80.94c-.94,.86-.56,2.81,.85,4.36s3.32,2.11,4.26,1.26c.94-.86,.56-2.81-.85-4.36-1.41-1.55-3.32-2.12-4.26-1.26Z"/>
          <path class="cls-6" d="M71.62,80.94c-.94,.86-.56,2.81,.84,4.36,1.41,1.55,3.31,2.11,4.26,1.26,.94-.86,.56-2.81-.84-4.36-1.41-1.55-3.31-2.11-4.26-1.26Z"/>
          <path class="cls-6" d="M71.62,80.94c-.94,.85-.56,2.8,.84,4.35,1.41,1.55,3.31,2.11,4.25,1.25,.94-.86,.56-2.8-.84-4.35-1.41-1.55-3.31-2.11-4.25-1.25Z"/>
          <path class="cls-19" d="M71.63,80.95c-.94,.85-.56,2.8,.84,4.35,1.41,1.55,3.31,2.11,4.25,1.25,.94-.85,.56-2.8-.84-4.34-1.41-1.55-3.31-2.11-4.25-1.25Z"/>
          <path class="cls-169" d="M71.63,80.95c-.94,.85-.56,2.8,.84,4.34,1.4,1.54,3.3,2.1,4.24,1.25,.94-.85,.56-2.8-.84-4.34-1.4-1.54-3.3-2.1-4.24-1.25Z"/>
          <path class="cls-37" d="M71.63,80.95c-.94,.85-.56,2.79,.84,4.33,1.4,1.54,3.3,2.1,4.24,1.25,.94-.85,.56-2.79-.84-4.33-1.4-1.54-3.3-2.1-4.23-1.25Z"/>
          <path class="cls-60" d="M71.64,80.96c-.94,.85-.56,2.79,.84,4.33,1.4,1.54,3.29,2.1,4.23,1.25,.94-.85,.56-2.79-.84-4.33-1.4-1.54-3.29-2.1-4.23-1.25Z"/>
          <path class="cls-129" d="M71.64,80.96c-.93,.85-.56,2.79,.84,4.32,1.4,1.54,3.29,2.1,4.22,1.25,.93-.85,.56-2.79-.84-4.32-1.4-1.54-3.29-2.1-4.22-1.25Z"/>
          <path class="cls-192" d="M71.64,80.96c-.93,.85-.56,2.78,.84,4.32,1.4,1.54,3.29,2.09,4.22,1.25,.93-.85,.56-2.78-.84-4.32-1.4-1.54-3.29-2.09-4.22-1.25Z"/>
          <path class="cls-205" d="M71.65,80.97c-.93,.85-.56,2.78,.84,4.31,1.39,1.54,3.28,2.09,4.21,1.24,.93-.85,.56-2.78-.84-4.31-1.39-1.53-3.28-2.09-4.21-1.24Z"/>
          <path class="cls-64" d="M71.65,80.97c-.93,.85-.56,2.78,.84,4.31,1.39,1.53,3.28,2.09,4.21,1.24,.93-.85,.56-2.78-.84-4.31-1.39-1.53-3.28-2.09-4.21-1.24Z"/>
          <path class="cls-21" d="M71.65,80.97c-.93,.85-.56,2.77,.83,4.3,1.39,1.53,3.27,2.09,4.2,1.24,.93-.85,.56-2.77-.83-4.3-1.39-1.53-3.27-2.09-4.2-1.24Z"/>
          <path class="cls-130" d="M71.66,80.98c-.93,.84-.56,2.77,.83,4.3,1.39,1.53,3.27,2.08,4.2,1.24,.93-.84,.56-2.77-.83-4.3-1.39-1.53-3.27-2.08-4.2-1.24Z"/>
          <path class="cls-80" d="M71.66,80.98c-.93,.84-.56,2.77,.83,4.29,1.39,1.53,3.27,2.08,4.19,1.24,.93-.84,.56-2.76-.83-4.29-1.39-1.53-3.26-2.08-4.19-1.24Z"/>
          <path class="cls-94" d="M71.66,80.99c-.93,.84-.55,2.76,.83,4.29,1.39,1.53,3.26,2.08,4.19,1.24,.93-.84,.56-2.76-.83-4.29-1.39-1.53-3.26-2.08-4.19-1.24Z"/>
          <path class="cls-163" d="M71.67,80.99c-.93,.84-.55,2.76,.83,4.28,1.38,1.52,3.26,2.08,4.18,1.23,.93-.84,.55-2.76-.83-4.28-1.38-1.52-3.26-2.08-4.18-1.24Z"/>
          <path class="cls-91" d="M71.67,80.99c-.92,.84-.55,2.75,.83,4.28,1.38,1.52,3.25,2.07,4.18,1.23,.92-.84,.55-2.75-.83-4.28-1.38-1.52-3.25-2.07-4.18-1.23Z"/>
          <path class="cls-204" d="M71.67,80.99c-.92,.84-.55,2.75,.83,4.27,1.38,1.52,3.25,2.07,4.17,1.23,.92-.84,.55-2.75-.83-4.27-1.38-1.52-3.25-2.07-4.17-1.23Z"/>
          <path class="cls-35" d="M71.67,81c-.92,.84-.55,2.75,.83,4.27s3.25,2.07,4.17,1.23c.92-.84,.55-2.75-.83-4.27-1.38-1.52-3.25-2.07-4.17-1.23Z"/>
          <path class="cls-126" d="M71.68,81c-.92,.84-.55,2.75,.83,4.26,1.38,1.52,3.24,2.07,4.16,1.23,.92-.84,.55-2.74-.83-4.26-1.38-1.52-3.24-2.07-4.16-1.23Z"/>
          <path class="cls-201" d="M71.68,81.01c-.92,.84-.55,2.74,.83,4.25s3.24,2.06,4.16,1.23c.92-.84,.55-2.74-.82-4.26-1.38-1.51-3.24-2.06-4.16-1.23Z"/>
          <path class="cls-170" d="M71.68,81.01c-.92,.83-.55,2.74,.82,4.25,1.37,1.51,3.23,2.06,4.15,1.23,.92-.83,.55-2.74-.82-4.25-1.37-1.51-3.23-2.06-4.15-1.23Z"/>
          <path class="cls-75" d="M71.69,81.01c-.92,.83-.55,2.73,.82,4.24,1.37,1.51,3.23,2.06,4.15,1.22,.92-.83,.55-2.73-.82-4.25-1.37-1.51-3.23-2.06-4.15-1.22Z"/>
          <path class="cls-121" d="M71.69,81.02c-.92,.83-.55,2.73,.82,4.24,1.37,1.51,3.22,2.06,4.14,1.22,.92-.83,.55-2.73-.82-4.24-1.37-1.51-3.22-2.06-4.14-1.22Z"/>
          <path class="cls-78" d="M71.69,81.02c-.92,.83-.55,2.73,.82,4.23,1.37,1.51,3.22,2.05,4.14,1.22,.92-.83,.55-2.73-.82-4.23-1.37-1.51-3.22-2.05-4.14-1.22Z"/>
          <path class="cls-107" d="M71.7,81.02c-.91,.83-.55,2.72,.82,4.23,1.37,1.5,3.22,2.05,4.13,1.22,.92-.83,.55-2.72-.82-4.23-1.37-1.5-3.22-2.05-4.13-1.22Z"/>
          <path class="cls-202" d="M71.7,81.03c-.91,.83-.55,2.72,.82,4.22,1.37,1.5,3.21,2.05,4.13,1.22,.91-.83,.55-2.72-.82-4.22-1.37-1.5-3.21-2.05-4.13-1.22Z"/>
          <path class="cls-42" d="M71.7,81.03c-.91,.83-.55,2.72,.82,4.22,1.36,1.5,3.21,2.04,4.12,1.22,.91-.83,.55-2.72-.82-4.22-1.36-1.5-3.21-2.05-4.12-1.22Z"/>
          <path class="cls-5" d="M71.71,81.03c-.91,.83-.55,2.71,.82,4.21,1.36,1.5,3.21,2.04,4.12,1.21,.91-.83,.55-2.71-.82-4.21-1.36-1.5-3.21-2.04-4.12-1.22Z"/>
          <path class="cls-2" d="M71.71,81.04c-.91,.83-.54,2.71,.82,4.21,1.36,1.5,3.2,2.04,4.11,1.21,.91-.83,.54-2.71-.82-4.21-1.36-1.5-3.2-2.04-4.11-1.21Z"/>
          <path class="cls-191" d="M71.71,81.04c-.91,.83-.54,2.71,.81,4.2,1.36,1.5,3.2,2.04,4.11,1.21,.91-.83,.54-2.71-.82-4.2-1.36-1.5-3.2-2.04-4.11-1.21Z"/>
          <path class="cls-112" d="M71.71,81.04c-.91,.83-.54,2.7,.81,4.2,1.36,1.49,3.19,2.04,4.1,1.21,.91-.83,.54-2.7-.81-4.2-1.36-1.49-3.19-2.04-4.1-1.21Z"/>
          <path class="cls-101" d="M71.72,81.05c-.91,.82-.54,2.7,.81,4.19s3.19,2.03,4.09,1.21c.91-.82,.54-2.7-.81-4.19-1.36-1.49-3.19-2.03-4.1-1.21Z"/>
          <path class="cls-136" d="M71.72,81.05c-.91,.82-.54,2.7,.81,4.19,1.35,1.49,3.18,2.03,4.09,1.21,.91-.82,.54-2.7-.81-4.19-1.35-1.49-3.18-2.03-4.09-1.21Z"/>
          <path class="cls-100" d="M71.72,81.05c-.9,.82-.54,2.69,.81,4.18,1.35,1.49,3.18,2.03,4.08,1.21,.9-.82,.54-2.69-.81-4.18-1.35-1.49-3.18-2.03-4.08-1.21Z"/>
          <path class="cls-103" d="M71.73,81.06c-.9,.82-.54,2.69,.81,4.18,1.35,1.49,3.18,2.03,4.08,1.21,.9-.82,.54-2.69-.81-4.18-1.35-1.49-3.18-2.03-4.08-1.2Z"/>
          <path class="cls-71" d="M71.73,81.06c-.9,.82-.54,2.69,.81,4.17,1.35,1.48,3.17,2.02,4.08,1.2,.9-.82,.54-2.69-.81-4.17-1.35-1.48-3.17-2.02-4.08-1.2Z"/>
          <path class="cls-189" d="M71.73,81.06c-.9,.82-.54,2.68,.81,4.17,1.35,1.48,3.17,2.02,4.07,1.2,.9-.82,.54-2.68-.81-4.17-1.35-1.48-3.17-2.02-4.07-1.2Z"/>
          <path class="cls-1" d="M71.74,81.07c-.9,.82-.54,2.68,.81,4.16,1.35,1.48,3.17,2.02,4.06,1.2,.9-.82,.54-2.68-.81-4.16-1.34-1.48-3.16-2.02-4.06-1.2Z"/>
          <path class="cls-85" d="M71.74,81.07c-.9,.82-.54,2.68,.81,4.16,1.34,1.48,3.16,2.01,4.06,1.2,.9-.82,.54-2.68-.81-4.16-1.34-1.48-3.16-2.01-4.06-1.2Z"/>
          <path class="cls-160" d="M71.74,81.07c-.9,.82-.54,2.67,.8,4.15,1.34,1.48,3.16,2.01,4.05,1.2,.9-.82,.54-2.67-.8-4.15-1.34-1.48-3.16-2.01-4.05-1.2Z"/>
          <path class="cls-175" d="M71.75,81.08c-.9,.81-.54,2.67,.8,4.14,1.34,1.47,3.15,2.01,4.05,1.2s.54-2.67-.8-4.14c-1.34-1.47-3.15-2.01-4.05-1.2Z"/>
          <path class="cls-123" d="M71.75,81.08c-.89,.81-.54,2.67,.8,4.14,1.34,1.47,3.15,2.01,4.04,1.19,.89-.81,.54-2.67-.8-4.14-1.34-1.47-3.15-2.01-4.04-1.19Z"/>
          <path class="cls-16" d="M71.75,81.08c-.89,.81-.54,2.66,.8,4.13,1.34,1.47,3.14,2,4.04,1.19,.89-.81,.54-2.66-.8-4.13-1.34-1.47-3.14-2-4.04-1.19Z"/>
          <path class="cls-15" d="M71.75,81.09c-.89,.81-.53,2.66,.8,4.13,1.33,1.47,3.14,2,4.03,1.19,.89-.81,.54-2.66-.8-4.13-1.33-1.47-3.14-2-4.03-1.19Z"/>
          <path class="cls-38" d="M71.76,81.09c-.89,.81-.53,2.66,.8,4.12,1.33,1.47,3.14,2,4.03,1.19,.89-.81,.53-2.66-.8-4.12-1.33-1.47-3.14-2-4.03-1.19Z"/>
          <path class="cls-90" d="M71.76,81.09c-.89,.81-.53,2.65,.8,4.12,1.33,1.46,3.13,2,4.02,1.19,.89-.81,.53-2.65-.8-4.12-1.33-1.46-3.13-2-4.02-1.19Z"/>
          <path class="cls-74" d="M71.76,81.1c-.89,.81-.53,2.65,.8,4.11,1.33,1.46,3.13,1.99,4.02,1.19,.89-.81,.53-2.65-.8-4.11-1.33-1.46-3.13-1.99-4.02-1.19Z"/>
          <path class="cls-186" d="M71.77,81.1c-.89,.81-.53,2.65,.8,4.11,1.33,1.46,3.12,1.99,4.01,1.18,.89-.81,.53-2.65-.8-4.11-1.33-1.46-3.12-1.99-4.01-1.18Z"/>
          <path class="cls-193" d="M71.77,81.1c-.89,.81-.53,2.64,.8,4.1,1.33,1.46,3.12,1.99,4.01,1.18,.89-.81,.53-2.64-.8-4.1-1.33-1.46-3.12-1.99-4.01-1.18Z"/>
          <path class="cls-185" d="M71.77,81.11c-.89,.81-.53,2.64,.79,4.1,1.32,1.46,3.12,1.99,4,1.18,.89-.8,.53-2.64-.79-4.1-1.32-1.46-3.12-1.99-4-1.18Z"/>
          <path class="cls-111" d="M71.78,81.11c-.88,.8-.53,2.64,.79,4.09,1.32,1.46,3.11,1.98,4,1.18,.88-.8,.53-2.64-.79-4.09-1.32-1.46-3.11-1.98-4-1.18Z"/>
          <path class="cls-58" d="M71.78,81.11c-.88,.8-.53,2.63,.79,4.09,1.32,1.45,3.11,1.98,3.99,1.18,.88-.8,.53-2.63-.79-4.09s-3.11-1.98-3.99-1.18Z"/>
          <path class="cls-69" d="M71.78,81.12c-.88,.8-.53,2.63,.79,4.08,1.32,1.45,3.1,1.98,3.99,1.18,.88-.8,.53-2.63-.79-4.08-1.32-1.45-3.1-1.98-3.99-1.18Z"/>
          <path class="cls-73" d="M71.79,81.12c-.88,.8-.53,2.63,.79,4.08,1.32,1.45,3.1,1.98,3.98,1.17,.88-.8,.53-2.62-.79-4.08-1.32-1.45-3.1-1.98-3.98-1.18Z"/>
          <path class="cls-168" d="M71.79,81.12c-.88,.8-.53,2.62,.79,4.07,1.32,1.45,3.1,1.97,3.98,1.17,.88-.8,.53-2.62-.79-4.07-1.32-1.45-3.1-1.97-3.98-1.17Z"/>
          <path class="cls-87" d="M71.79,81.13c-.88,.8-.53,2.62,.79,4.07,1.31,1.45,3.09,1.97,3.97,1.17,.88-.8,.53-2.62-.79-4.06-1.31-1.45-3.09-1.97-3.97-1.17Z"/>
          <path class="cls-4" d="M71.8,81.13c-.88,.8-.53,2.62,.79,4.06,1.31,1.44,3.09,1.97,3.97,1.17,.88-.8,.53-2.62-.79-4.06-1.31-1.44-3.09-1.97-3.97-1.17Z"/>
          <path class="cls-128" d="M71.8,81.13c-.88,.8-.53,2.61,.79,4.05,1.31,1.44,3.08,1.97,3.96,1.17,.88-.8,.52-2.61-.79-4.05-1.31-1.44-3.08-1.97-3.96-1.17Z"/>
          <path class="cls-62" d="M71.8,81.14c-.88,.79-.52,2.61,.79,4.05,1.31,1.44,3.08,1.96,3.96,1.17,.87-.8,.52-2.61-.79-4.05-1.31-1.44-3.08-1.96-3.96-1.17Z"/>
          <path class="cls-114" d="M71.8,81.14c-.87,.79-.52,2.61,.78,4.04,1.31,1.44,3.08,1.96,3.95,1.17,.87-.79,.52-2.6-.78-4.04-1.31-1.44-3.08-1.96-3.95-1.17Z"/>
          <path class="cls-41" d="M71.81,81.14c-.87,.79-.52,2.6,.78,4.04,1.31,1.44,3.07,1.96,3.95,1.17,.87-.79,.52-2.6-.78-4.04-1.31-1.44-3.07-1.96-3.95-1.17Z"/>
          <path class="cls-72" d="M71.81,81.15c-.87,.79-.52,2.6,.78,4.03,1.3,1.44,3.07,1.96,3.94,1.16,.87-.79,.52-2.6-.78-4.03-1.3-1.43-3.07-1.96-3.94-1.16Z"/>
          <path class="cls-52" d="M71.81,81.15c-.87,.79-.52,2.6,.78,4.03,1.3,1.43,3.06,1.95,3.94,1.16,.87-.79,.52-2.59-.78-4.03-1.3-1.43-3.06-1.95-3.94-1.16Z"/>
          <path class="cls-147" d="M71.82,81.16c-.87,.79-.52,2.59,.78,4.02,1.3,1.43,3.06,1.95,3.93,1.16,.87-.79,.52-2.59-.78-4.02-1.3-1.43-3.06-1.95-3.93-1.16Z"/>
          <path class="cls-56" d="M71.82,81.16c-.87,.79-.52,2.59,.78,4.02,1.3,1.43,3.06,1.95,3.92,1.16,.87-.79,.52-2.59-.78-4.02-1.3-1.43-3.06-1.95-3.92-1.16Z"/>
          <path class="cls-86" d="M71.82,81.16c-.87,.79-.52,2.58,.78,4.01,1.3,1.43,3.05,1.95,3.92,1.16,.87-.79,.52-2.58-.78-4.01-1.3-1.43-3.05-1.95-3.92-1.16Z"/>
          <path class="cls-134" d="M71.83,81.16c-.87,.79-.52,2.58,.78,4.01,1.29,1.43,3.05,1.94,3.91,1.16,.87-.79,.52-2.58-.78-4.01-1.3-1.43-3.05-1.94-3.92-1.16Z"/>
          <path class="cls-96" d="M71.83,81.17c-.87,.79-.52,2.58,.78,4,1.29,1.42,3.04,1.94,3.91,1.15,.87-.79,.52-2.58-.78-4-1.29-1.42-3.04-1.94-3.91-1.15Z"/>
          <path class="cls-150" d="M71.83,81.17c-.86,.79-.52,2.58,.77,4,1.29,1.42,3.04,1.94,3.9,1.15,.86-.79,.52-2.57-.77-4-1.29-1.42-3.04-1.94-3.9-1.15Z"/>
          <path class="cls-81" d="M71.84,81.18c-.86,.78-.52,2.57,.77,3.99,1.29,1.42,3.04,1.94,3.9,1.15,.86-.79,.52-2.57-.77-3.99-1.29-1.42-3.04-1.94-3.9-1.15Z"/>
          <path class="cls-97" d="M71.84,81.18c-.86,.78-.52,2.57,.77,3.99,1.29,1.42,3.03,1.93,3.89,1.15,.86-.78,.52-2.57-.77-3.99-1.29-1.42-3.03-1.93-3.89-1.15Z"/>
          <path class="cls-194" d="M71.84,81.18c-.86,.78-.51,2.56,.77,3.98,1.29,1.42,3.03,1.93,3.89,1.15,.86-.78,.51-2.56-.77-3.98-1.29-1.42-3.03-1.93-3.89-1.15Z"/>
          <path class="cls-55" d="M71.84,81.19c-.86,.78-.51,2.56,.77,3.98,1.29,1.41,3.02,1.93,3.88,1.15,.86-.78,.51-2.56-.77-3.98-1.29-1.41-3.02-1.93-3.88-1.15Z"/>
          <path class="cls-105" d="M71.85,81.19c-.86,.78-.51,2.56,.77,3.97,1.28,1.41,3.02,1.93,3.88,1.15,.86-.78,.51-2.56-.77-3.97-1.28-1.41-3.02-1.92-3.88-1.14Z"/>
          <path class="cls-9" d="M71.85,81.19c-.86,.78-.51,2.55,.77,3.96,1.28,1.41,3.02,1.92,3.87,1.14s.51-2.55-.77-3.96c-1.28-1.41-3.02-1.92-3.87-1.14Z"/>
          <path class="cls-3" d="M71.85,81.2c-.86,.78-.51,2.55,.77,3.96,1.28,1.41,3.01,1.92,3.87,1.14,.86-.78,.51-2.55-.77-3.96-1.28-1.41-3.01-1.92-3.87-1.14Z"/>
          <path class="cls-22" d="M71.86,81.2c-.86,.78-.51,2.55,.77,3.95,1.28,1.41,3.01,1.92,3.86,1.14,.85-.78,.51-2.55-.77-3.95-1.28-1.41-3.01-1.92-3.86-1.14Z"/>
          <path class="cls-196" d="M71.86,81.2c-.85,.78-.51,2.54,.77,3.95,1.28,1.4,3,1.91,3.86,1.14,.85-.78,.51-2.54-.77-3.95-1.28-1.41-3-1.92-3.86-1.14Z"/>
          <path class="cls-110" d="M71.86,81.21c-.85,.78-.51,2.54,.76,3.94,1.28,1.4,3,1.91,3.85,1.14,.85-.77,.51-2.54-.76-3.94-1.28-1.4-3-1.91-3.85-1.14Z"/>
          <path class="cls-116" d="M71.87,81.21c-.85,.77-.51,2.54,.76,3.94,1.27,1.4,3,1.91,3.85,1.14,.85-.77,.51-2.54-.76-3.94-1.27-1.4-3-1.91-3.85-1.14Z"/>
          <path class="cls-124" d="M71.87,81.21c-.85,.77-.51,2.53,.76,3.93,1.27,1.4,2.99,1.91,3.84,1.13,.85-.77,.51-2.53-.76-3.93-1.27-1.4-2.99-1.91-3.84-1.13Z"/>
          <path class="cls-18" d="M71.87,81.22c-.85,.77-.51,2.53,.76,3.93,1.27,1.4,2.99,1.9,3.84,1.13,.85-.77,.51-2.53-.76-3.93-1.27-1.4-2.99-1.91-3.84-1.13Z"/>
          <path class="cls-57" d="M71.88,81.22c-.85,.77-.51,2.53,.76,3.92,1.27,1.4,2.98,1.9,3.83,1.13,.85-.77,.51-2.53-.76-3.92-1.27-1.4-2.98-1.9-3.83-1.13Z"/>
          <path class="cls-148" d="M71.88,81.22c-.85,.77-.51,2.52,.76,3.92,1.27,1.39,2.98,1.9,3.83,1.13,.85-.77,.51-2.52-.76-3.92-1.27-1.39-2.98-1.9-3.83-1.13Z"/>
          <path class="cls-113" d="M71.88,81.23c-.85,.77-.51,2.52,.76,3.91,1.26,1.39,2.98,1.9,3.82,1.13,.85-.77,.51-2.52-.76-3.91-1.26-1.39-2.98-1.9-3.82-1.13Z"/>
          <path class="cls-125" d="M71.89,81.23c-.84,.77-.51,2.52,.76,3.91s2.97,1.89,3.82,1.13c.84-.77,.51-2.52-.76-3.91-1.26-1.39-2.97-1.89-3.82-1.13Z"/>
          <path class="cls-209" d="M71.89,81.23c-.84,.77-.5,2.51,.76,3.9,1.26,1.39,2.97,1.89,3.81,1.12,.84-.77,.5-2.51-.76-3.9-1.26-1.39-2.97-1.89-3.81-1.12Z"/>
          <path class="cls-142" d="M71.89,81.24c-.84,.77-.5,2.51,.75,3.9,1.26,1.39,2.96,1.89,3.81,1.12,.84-.77,.5-2.51-.75-3.9-1.26-1.39-2.96-1.89-3.81-1.12Z"/>
          <path class="cls-183" d="M71.89,81.24c-.84,.76-.5,2.51,.75,3.89,1.26,1.38,2.96,1.89,3.8,1.12,.84-.76,.5-2.51-.75-3.89-1.26-1.38-2.96-1.89-3.8-1.12Z"/>
          <path class="cls-127" d="M71.9,81.24c-.84,.76-.5,2.5,.75,3.88,1.26,1.38,2.96,1.88,3.8,1.12,.84-.76,.5-2.5-.75-3.89-1.26-1.38-2.96-1.88-3.8-1.12Z"/>
          <path class="cls-10" d="M71.9,81.25c-.84,.76-.5,2.5,.75,3.88,1.25,1.38,2.95,1.88,3.79,1.12,.84-.76,.5-2.5-.75-3.88-1.25-1.38-2.95-1.88-3.79-1.12Z"/>
          <path class="cls-149" d="M71.9,81.25c-.84,.76-.5,2.5,.75,3.88,1.25,1.38,2.95,1.88,3.79,1.12,.84-.76,.5-2.5-.75-3.88-1.25-1.38-2.95-1.88-3.79-1.12Z"/>
          <path class="cls-95" d="M71.91,81.25c-.84,.76-.5,2.49,.75,3.87,1.25,1.38,2.94,1.88,3.78,1.12,.84-.76,.5-2.49-.75-3.87-1.25-1.38-2.94-1.88-3.78-1.12Z"/>
          <path class="cls-102" d="M71.91,81.26c-.84,.76-.5,2.49,.75,3.87,1.25,1.38,2.94,1.87,3.78,1.11,.84-.76,.5-2.49-.75-3.86-1.25-1.38-2.94-1.87-3.78-1.12Z"/>
          <path class="cls-106" d="M71.91,81.26c-.83,.76-.5,2.49,.75,3.86,1.25,1.37,2.94,1.87,3.77,1.11,.83-.76,.5-2.49-.75-3.86-1.25-1.37-2.94-1.87-3.77-1.11Z"/>
          <path class="cls-109" d="M71.92,81.26c-.83,.76-.5,2.48,.75,3.85,1.25,1.37,2.93,1.87,3.77,1.11,.83-.76,.5-2.48-.75-3.85-1.25-1.37-2.93-1.87-3.77-1.11Z"/>
          <path class="cls-119" d="M71.92,81.27c-.83,.76-.5,2.48,.75,3.85s2.93,1.87,3.76,1.11c.83-.76,.5-2.48-.75-3.85-1.24-1.37-2.93-1.87-3.76-1.11Z"/>
          <path class="cls-66" d="M71.92,81.27c-.83,.75-.5,2.48,.74,3.84,1.24,1.37,2.92,1.86,3.75,1.11,.83-.75,.5-2.48-.75-3.84-1.24-1.37-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-172" d="M71.93,81.27c-.83,.75-.5,2.47,.74,3.84,1.24,1.37,2.92,1.86,3.75,1.11,.83-.75,.5-2.47-.74-3.84-1.24-1.37-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-178" d="M71.93,81.28c-.83,.75-.5,2.47,.74,3.83,1.24,1.36,2.92,1.86,3.74,1.11s.5-2.47-.74-3.83c-1.24-1.36-2.91-1.86-3.74-1.11Z"/>
          <path class="cls-93" d="M71.93,81.28c-.83,.75-.5,2.47,.74,3.83,1.24,1.36,2.91,1.86,3.74,1.1,.83-.75,.5-2.46-.74-3.83-1.24-1.36-2.91-1.86-3.74-1.1Z"/>
          <path class="cls-65" d="M71.93,81.28c-.83,.75-.49,2.46,.74,3.82,1.24,1.36,2.91,1.85,3.73,1.1,.83-.75,.49-2.46-.74-3.82-1.24-1.36-2.91-1.85-3.73-1.1Z"/>
          <path class="cls-164" d="M71.94,81.29c-.83,.75-.49,2.46,.74,3.82,1.23,1.36,2.9,1.85,3.73,1.1,.83-.75,.49-2.46-.74-3.82-1.23-1.36-2.9-1.85-3.73-1.1Z"/>
          <path class="cls-174" d="M71.94,81.29c-.82,.75-.49,2.46,.74,3.81,1.23,1.36,2.9,1.85,3.72,1.1,.82-.75,.49-2.46-.74-3.81-1.23-1.36-2.9-1.85-3.72-1.1Z"/>
          <path class="cls-92" d="M71.94,81.29c-.82,.75-.49,2.45,.74,3.81,1.23,1.35,2.9,1.84,3.72,1.1,.82-.75,.49-2.45-.74-3.81-1.23-1.35-2.9-1.85-3.72-1.1Z"/>
          <path class="cls-203" d="M71.95,81.3c-.82,.75-.49,2.45,.74,3.8,1.23,1.35,2.89,1.84,3.71,1.1,.82-.75,.49-2.45-.74-3.8-1.23-1.35-2.89-1.84-3.71-1.1Z"/>
          <path class="cls-144" d="M71.95,81.3c-.82,.75-.49,2.45,.74,3.8,1.23,1.35,2.89,1.84,3.71,1.09,.82-.75,.49-2.45-.74-3.8-1.23-1.35-2.89-1.84-3.71-1.09Z"/>
          <path class="cls-195" d="M71.95,81.3c-.82,.75-.49,2.44,.74,3.79,1.23,1.35,2.88,1.84,3.7,1.09,.82-.75,.49-2.44-.74-3.79-1.23-1.35-2.88-1.84-3.7-1.09Z"/>
          <path class="cls-140" d="M71.96,81.31c-.82,.74-.49,2.44,.73,3.79s2.88,1.83,3.7,1.09c.82-.74,.49-2.44-.73-3.79-1.22-1.35-2.88-1.84-3.7-1.09Z"/>
          <path class="cls-120" d="M71.96,81.31c-.82,.74-.49,2.44,.73,3.78,1.22,1.34,2.88,1.83,3.69,1.09,.82-.74,.49-2.43-.73-3.78-1.22-1.35-2.88-1.83-3.69-1.09Z"/>
          <path class="cls-173" d="M71.96,81.32c-.82,.74-.49,2.43,.73,3.77,1.22,1.34,2.87,1.83,3.69,1.09,.82-.74,.49-2.43-.73-3.78-1.22-1.34-2.87-1.83-3.69-1.09Z"/>
          <path class="cls-79" d="M71.97,81.32c-.82,.74-.49,2.43,.73,3.77,1.22,1.34,2.87,1.83,3.68,1.09,.82-.74,.49-2.43-.73-3.77-1.22-1.34-2.87-1.83-3.68-1.09Z"/>
          <path class="cls-179" d="M71.97,81.32c-.81,.74-.49,2.42,.73,3.76,1.22,1.34,2.86,1.83,3.68,1.09,.81-.74,.49-2.42-.73-3.76-1.22-1.34-2.86-1.83-3.68-1.08Z"/>
          <path class="cls-179" d="M71.97,81.32c-.81,.74-.49,2.42,.73,3.76,1.22,1.34,2.86,1.82,3.67,1.08,.81-.74,.49-2.42-.73-3.76-1.22-1.34-2.86-1.82-3.67-1.08Z"/>
          <path class="cls-173" d="M71.98,81.33c-.81,.74-.49,2.41,.73,3.75s2.85,1.82,3.66,1.08c.81-.74,.48-2.41-.73-3.75-1.21-1.33-2.85-1.82-3.66-1.08Z"/>
          <path class="cls-140" d="M71.99,81.34c-.81,.73-.48,2.41,.72,3.74,1.21,1.33,2.84,1.81,3.65,1.08,.81-.73,.48-2.41-.72-3.74s-2.84-1.81-3.65-1.08Z"/>
          <path class="cls-144" d="M71.99,81.35c-.8,.73-.48,2.4,.72,3.72,1.2,1.33,2.83,1.81,3.64,1.07,.8-.73,.48-2.4-.72-3.72-1.2-1.33-2.83-1.81-3.64-1.07Z"/>
          <path class="cls-115" d="M72,81.36c-.8,.73-.48,2.39,.72,3.71,1.2,1.32,2.82,1.8,3.63,1.07,.8-.73,.48-2.39-.72-3.71-1.2-1.32-2.82-1.8-3.63-1.07Z"/>
          <path class="cls-88" d="M72.01,81.36c-.8,.73-.48,2.38,.72,3.7,1.2,1.32,2.81,1.79,3.62,1.07,.8-.73,.48-2.38-.72-3.7-1.2-1.32-2.81-1.79-3.62-1.07Z"/>
          <path class="cls-162" d="M72.01,81.37c-.8,.73-.48,2.38,.71,3.69,1.19,1.31,2.81,1.79,3.6,1.06,.8-.72,.48-2.38-.72-3.69-1.19-1.31-2.81-1.79-3.6-1.06Z"/>
          <path class="cls-133" d="M72.02,81.38c-.79,.72-.48,2.37,.71,3.68,1.19,1.31,2.8,1.78,3.59,1.06,.79-.72,.48-2.37-.71-3.68-1.19-1.31-2.8-1.78-3.59-1.06Z"/>
          <path class="cls-66" d="M72.03,81.39c-.79,.72-.47,2.36,.71,3.67,1.18,1.3,2.79,1.78,3.58,1.06,.79-.72,.47-2.36-.71-3.67-1.18-1.3-2.79-1.78-3.58-1.06Z"/>
          <path class="cls-109" d="M72.03,81.39c-.79,.72-.47,2.35,.71,3.65,1.18,1.3,2.78,1.77,3.57,1.05,.79-.72,.47-2.35-.71-3.65-1.18-1.3-2.78-1.77-3.57-1.05Z"/>
          <path class="cls-43" d="M72.04,81.4c-.79,.72-.47,2.35,.71,3.64,1.18,1.3,2.77,1.77,3.56,1.05,.79-.72,.47-2.35-.71-3.64-1.18-1.3-2.77-1.77-3.56-1.05Z"/>
          <path class="cls-95" d="M72.05,81.41c-.79,.71-.47,2.34,.7,3.63,1.17,1.29,2.76,1.76,3.55,1.05,.79-.71,.47-2.34-.7-3.63s-2.76-1.76-3.55-1.05Z"/>
          <path class="cls-99" d="M72.05,81.41c-.78,.71-.47,2.33,.7,3.62,1.17,1.29,2.75,1.75,3.54,1.04,.78-.71,.47-2.33-.7-3.62-1.17-1.29-2.75-1.75-3.54-1.04Z"/>
          <path class="cls-181" d="M72.06,81.42c-.78,.71-.47,2.32,.7,3.61,1.17,1.28,2.74,1.75,3.52,1.04,.78-.71,.47-2.32-.7-3.61-1.17-1.28-2.74-1.75-3.52-1.04Z"/>
          <path class="cls-152" d="M72.07,81.43c-.78,.71-.47,2.32,.7,3.6,1.16,1.28,2.74,1.74,3.51,1.04,.78-.71,.47-2.32-.7-3.6-1.16-1.28-2.74-1.74-3.51-1.04Z"/>
          <path class="cls-125" d="M72.07,81.44c-.78,.71-.46,2.31,.69,3.58,1.16,1.27,2.73,1.74,3.5,1.03s.46-2.31-.7-3.58c-1.16-1.28-2.73-1.74-3.5-1.03Z"/>
          <path class="cls-148" d="M72.08,81.45c-.77,.7-.46,2.3,.69,3.57,1.16,1.27,2.72,1.73,3.49,1.03,.77-.7,.46-2.3-.69-3.57-1.16-1.27-2.72-1.73-3.49-1.03Z"/>
          <path class="cls-68" d="M72.09,81.45c-.77,.7-.46,2.29,.69,3.56,1.15,1.27,2.71,1.73,3.48,1.03,.77-.7,.46-2.29-.69-3.56-1.15-1.27-2.71-1.73-3.48-1.03Z"/>
          <path class="cls-157" d="M72.09,81.46c-.77,.7-.46,2.29,.69,3.55,1.15,1.26,2.7,1.72,3.47,1.02,.77-.7,.46-2.29-.69-3.55-1.15-1.26-2.7-1.72-3.47-1.02Z"/>
          <path class="cls-143" d="M72.1,81.47c-.76,.7-.46,2.28,.69,3.54,1.14,1.26,2.69,1.71,3.46,1.02,.76-.7,.46-2.28-.69-3.54-1.14-1.26-2.69-1.72-3.46-1.02Z"/>
          <path class="cls-196" d="M72.11,81.48c-.76,.69-.46,2.27,.68,3.53,1.14,1.25,2.68,1.71,3.44,1.02,.76-.69,.46-2.27-.68-3.53-1.14-1.25-2.68-1.71-3.44-1.02Z"/>
          <path class="cls-104" d="M72.12,81.48c-.76,.69-.46,2.26,.68,3.51,1.14,1.25,2.67,1.7,3.43,1.01,.76-.69,.46-2.26-.68-3.51-1.14-1.25-2.67-1.7-3.43-1.01Z"/>
          <path class="cls-105" d="M72.12,81.49c-.76,.69-.45,2.26,.68,3.5,1.13,1.25,2.67,1.7,3.42,1.01,.76-.69,.45-2.26-.68-3.5-1.13-1.25-2.66-1.7-3.42-1.01Z"/>
          <path class="cls-137" d="M72.13,81.5c-.75,.69-.45,2.25,.68,3.49,1.13,1.24,2.66,1.69,3.41,1.01,.75-.69,.45-2.25-.68-3.49s-2.66-1.69-3.41-1.01Z"/>
          <path class="cls-167" d="M72.14,81.51c-.75,.68-.45,2.24,.67,3.48,1.12,1.24,2.65,1.69,3.4,1,.75-.68,.45-2.24-.67-3.48-1.12-1.24-2.65-1.69-3.4-1Z"/>
          <path class="cls-67" d="M72.14,81.51c-.75,.68-.45,2.23,.67,3.47,1.12,1.23,2.64,1.68,3.39,1s.45-2.23-.67-3.47c-1.12-1.23-2.64-1.68-3.39-1Z"/>
          <path class="cls-96" d="M72.15,81.52c-.75,.68-.45,2.23,.67,3.46,1.12,1.23,2.63,1.68,3.38,1,.75-.68,.45-2.23-.67-3.46-1.12-1.23-2.63-1.68-3.38-1Z"/>
          <path class="cls-134" d="M72.16,81.53c-.75,.68-.45,2.22,.67,3.45,1.11,1.23,2.62,1.67,3.37,.99,.75-.68,.45-2.22-.67-3.44-1.11-1.23-2.62-1.67-3.36-.99Z"/>
          <path class="cls-56" d="M72.16,81.53c-.74,.67-.44,2.21,.67,3.43,1.11,1.22,2.61,1.67,3.35,.99,.74-.67,.44-2.21-.66-3.43-1.11-1.22-2.61-1.67-3.35-.99Z"/>
          <path class="cls-132" d="M72.17,81.54c-.74,.67-.44,2.2,.66,3.42,1.11,1.22,2.6,1.66,3.34,.99,.74-.67,.44-2.2-.66-3.42-1.11-1.22-2.6-1.66-3.34-.99Z"/>
          <path class="cls-118" d="M72.18,81.55c-.74,.67-.44,2.2,.66,3.41,1.1,1.21,2.59,1.65,3.33,.98s.44-2.2-.66-3.41c-1.1-1.21-2.59-1.65-3.33-.98Z"/>
          <path class="cls-155" d="M72.18,81.56c-.74,.67-.44,2.19,.66,3.4,1.1,1.21,2.58,1.65,3.32,.98,.74-.67,.44-2.19-.66-3.4-1.1-1.21-2.58-1.65-3.32-.98Z"/>
          <path class="cls-122" d="M72.19,81.57c-.73,.67-.44,2.18,.66,3.39,1.09,1.2,2.58,1.64,3.31,.98,.73-.67,.44-2.18-.66-3.39-1.09-1.21-2.58-1.64-3.31-.98Z"/>
          <path class="cls-128" d="M72.2,81.57c-.73,.66-.44,2.17,.65,3.37,1.09,1.2,2.57,1.64,3.3,.97,.73-.66,.44-2.17-.65-3.38-1.09-1.2-2.57-1.64-3.3-.97Z"/>
          <path class="cls-158" d="M72.2,81.58c-.73,.66-.43,2.17,.65,3.36,1.09,1.2,2.56,1.63,3.29,.97,.73-.66,.44-2.17-.65-3.36-1.09-1.2-2.56-1.63-3.29-.97Z"/>
          <path class="cls-168" d="M72.21,81.59c-.72,.66-.43,2.16,.65,3.35,1.08,1.19,2.55,1.62,3.27,.97,.73-.66,.43-2.16-.65-3.35-1.08-1.19-2.55-1.62-3.27-.97Z"/>
          <path class="cls-73" d="M72.22,81.6c-.72,.66-.43,2.15,.65,3.34,1.08,1.19,2.54,1.62,3.26,.96,.72-.66,.43-2.15-.65-3.34-1.08-1.19-2.54-1.62-3.26-.96Z"/>
          <path class="cls-58" d="M72.22,81.6c-.72,.65-.43,2.14,.65,3.33,1.08,1.18,2.53,1.61,3.25,.96,.72-.65,.43-2.14-.64-3.33-1.08-1.18-2.53-1.61-3.25-.96Z"/>
          <path class="cls-184" d="M72.23,81.61c-.72,.65-.43,2.14,.64,3.32,1.07,1.18,2.52,1.61,3.24,.96,.72-.65,.43-2.14-.64-3.32-1.07-1.18-2.52-1.61-3.24-.96Z"/>
          <path class="cls-185" d="M72.24,81.62c-.71,.65-.43,2.13,.64,3.3,1.07,1.18,2.51,1.6,3.23,.95,.71-.65,.43-2.13-.64-3.3-1.07-1.18-2.51-1.6-3.23-.95Z"/>
          <path class="cls-186" d="M72.24,81.62c-.71,.65-.43,2.12,.64,3.29,1.06,1.17,2.5,1.6,3.22,.95,.71-.65,.43-2.12-.64-3.29-1.06-1.17-2.5-1.6-3.22-.95Z"/>
          <path class="cls-74" d="M72.25,81.63c-.71,.64-.42,2.11,.64,3.28,1.06,1.17,2.5,1.59,3.21,.95,.71-.64,.42-2.11-.64-3.28-1.06-1.17-2.5-1.59-3.21-.95Z"/>
          <path class="cls-90" d="M72.26,81.64c-.71,.64-.42,2.11,.63,3.27,1.06,1.16,2.49,1.59,3.2,.94,.71-.64,.42-2.11-.63-3.27-1.06-1.16-2.49-1.58-3.2-.94Z"/>
          <path class="cls-15" d="M72.26,81.65c-.7,.64-.42,2.1,.63,3.26,1.05,1.16,2.48,1.58,3.18,.94,.7-.64,.42-2.1-.63-3.26-1.05-1.16-2.48-1.58-3.18-.94Z"/>
          <path class="cls-156" d="M72.27,81.66c-.7,.64-.42,2.09,.63,3.25,1.05,1.15,2.47,1.57,3.17,.94,.7-.64,.42-2.09-.63-3.25-1.05-1.16-2.47-1.57-3.17-.94Z"/>
          <path class="cls-123" d="M72.28,81.66c-.7,.64-.42,2.08,.63,3.23,1.05,1.15,2.46,1.57,3.16,.93,.7-.64,.42-2.08-.63-3.24-1.05-1.15-2.46-1.57-3.16-.93Z"/>
          <path class="cls-175" d="M72.29,81.67c-.7,.63-.42,2.08,.62,3.22,1.04,1.15,2.45,1.56,3.15,.93,.7-.63,.42-2.08-.62-3.22-1.04-1.15-2.45-1.56-3.15-.93Z"/>
          <path class="cls-188" d="M72.29,81.68c-.69,.63-.42,2.07,.62,3.21,1.04,1.14,2.44,1.56,3.14,.93,.69-.63,.41-2.07-.62-3.21-1.04-1.14-2.44-1.56-3.14-.93Z"/>
          <path class="cls-1" d="M72.3,81.69c-.69,.63-.41,2.06,.62,3.2,1.04,1.14,2.43,1.55,3.13,.92,.69-.63,.41-2.06-.62-3.2-1.03-1.14-2.43-1.55-3.13-.92Z"/>
          <path class="cls-189" d="M72.31,81.69c-.69,.63-.41,2.05,.62,3.19,1.03,1.13,2.43,1.55,3.12,.92,.69-.63,.41-2.05-.62-3.19-1.03-1.13-2.43-1.55-3.12-.92Z"/>
          <path class="cls-71" d="M72.31,81.7c-.69,.62-.41,2.05,.62,3.18,1.03,1.13,2.42,1.54,3.1,.92,.69-.62,.41-2.05-.62-3.18-1.03-1.13-2.42-1.54-3.1-.92Z"/>
          <path class="cls-103" d="M72.32,81.71c-.68,.62-.41,2.04,.61,3.17,1.02,1.13,2.41,1.53,3.09,.91,.68-.62,.41-2.04-.61-3.17-1.02-1.13-2.41-1.53-3.09-.91Z"/>
          <path class="cls-76" d="M72.33,81.71c-.68,.62-.41,2.03,.61,3.15,1.02,1.12,2.4,1.53,3.08,.91,.68-.62,.41-2.03-.61-3.15s-2.4-1.53-3.08-.91Z"/>
          <path class="cls-136" d="M72.33,81.72c-.68,.62-.41,2.02,.61,3.14,1.02,1.12,2.39,1.52,3.07,.91,.68-.62,.41-2.02-.61-3.14-1.02-1.12-2.39-1.52-3.07-.91Z"/>
          <path class="cls-154" d="M72.34,81.73c-.68,.62-.41,2.02,.61,3.13,1.01,1.11,2.38,1.52,3.06,.9,.68-.62,.41-2.02-.61-3.13-1.01-1.11-2.38-1.52-3.06-.9Z"/>
          <path class="cls-112" d="M72.35,81.74c-.67,.61-.4,2.01,.6,3.12,1.01,1.11,2.37,1.51,3.05,.9,.67-.61,.4-2.01-.6-3.12-1.01-1.11-2.37-1.51-3.05-.9Z"/>
          <path class="cls-191" d="M72.35,81.74c-.67,.61-.4,2,.6,3.11,1,1.11,2.36,1.51,3.04,.9,.67-.61,.4-2-.6-3.11-1-1.11-2.36-1.51-3.04-.9Z"/>
          <path class="cls-82" d="M72.36,81.75c-.67,.61-.4,1.99,.6,3.09,1,1.1,2.35,1.5,3.02,.89,.67-.61,.4-1.99-.6-3.1-1-1.1-2.35-1.5-3.02-.89Z"/>
          <path class="cls-5" d="M72.37,81.76c-.67,.61-.4,1.99,.6,3.08,1,1.1,2.35,1.5,3.01,.89,.67-.61,.4-1.99-.6-3.08-1-1.1-2.35-1.5-3.01-.89Z"/>
          <path class="cls-42" d="M72.37,81.77c-.66,.6-.4,1.98,.6,3.07,.99,1.09,2.34,1.49,3,.89,.66-.6,.4-1.98-.6-3.07-.99-1.09-2.34-1.49-3-.89Z"/>
          <path class="cls-207" d="M72.38,81.78c-.66,.6-.4,1.97,.59,3.06,.99,1.09,2.33,1.48,2.99,.88,.66-.6,.4-1.97-.59-3.06-.99-1.09-2.33-1.48-2.99-.88Z"/>
          <path class="cls-107" d="M72.39,81.78c-.66,.6-.39,1.96,.59,3.05,.99,1.08,2.32,1.48,2.98,.88,.66-.6,.39-1.96-.59-3.05-.99-1.08-2.32-1.48-2.98-.88Z"/>
          <path class="cls-78" d="M72.39,81.79c-.66,.6-.39,1.96,.59,3.04,.98,1.08,2.31,1.47,2.97,.88,.66-.6,.39-1.96-.59-3.04-.98-1.08-2.31-1.47-2.97-.88Z"/>
          <path class="cls-75" d="M72.4,81.8c-.65,.59-.39,1.95,.59,3.03,.98,1.08,2.3,1.47,2.96,.87,.65-.59,.39-1.95-.59-3.03-.98-1.08-2.3-1.47-2.96-.87Z"/>
          <path class="cls-20" d="M72.41,81.81c-.65,.59-.39,1.94,.58,3.01,.97,1.07,2.29,1.46,2.94,.87,.65-.59,.39-1.94-.58-3.01-.97-1.07-2.29-1.46-2.94-.87Z"/>
          <path class="cls-201" d="M72.41,81.81c-.65,.59-.39,1.93,.58,3,.97,1.07,2.28,1.46,2.93,.87,.65-.59,.39-1.93-.58-3-.97-1.07-2.28-1.46-2.93-.87Z"/>
          <path class="cls-201" d="M72.42,81.82c-.65,.59-.39,1.93,.58,2.99,.97,1.06,2.28,1.45,2.92,.86,.65-.59,.39-1.93-.58-2.99-.97-1.06-2.27-1.45-2.92-.86Z"/>
          <path class="cls-126" d="M72.43,81.83c-.64,.58-.39,1.92,.58,2.98s2.27,1.44,2.91,.86c.64-.59,.39-1.92-.58-2.98-.96-1.06-2.27-1.44-2.91-.86Z"/>
          <path class="cls-35" d="M72.44,81.83c-.64,.58-.38,1.91,.58,2.97,.96,1.06,2.26,1.44,2.9,.86s.38-1.91-.58-2.97c-.96-1.06-2.26-1.44-2.9-.86Z"/>
          <path class="cls-204" d="M72.44,81.84c-.64,.58-.38,1.9,.57,2.96,.96,1.05,2.25,1.43,2.89,.85,.64-.58,.38-1.9-.57-2.96-.96-1.05-2.25-1.43-2.89-.85Z"/>
          <path class="cls-89" d="M72.45,81.85c-.64,.58-.38,1.9,.57,2.94,.95,1.05,2.24,1.43,2.88,.85,.64-.58,.38-1.9-.57-2.94-.95-1.05-2.24-1.43-2.88-.85Z"/>
          <path class="cls-91" d="M72.46,81.86c-.63,.58-.38,1.89,.57,2.93,.95,1.04,2.23,1.42,2.86,.85,.63-.58,.38-1.89-.57-2.93-.95-1.04-2.23-1.42-2.86-.85Z"/>
          <path class="cls-94" d="M72.46,81.86c-.63,.57-.38,1.88,.57,2.92,.94,1.04,2.22,1.42,2.85,.84,.63-.57,.38-1.88-.57-2.92-.94-1.04-2.22-1.42-2.85-.84Z"/>
          <path class="cls-94" d="M72.47,81.87c-.63,.57-.38,1.87,.56,2.91,.94,1.04,2.21,1.41,2.84,.84,.63-.57,.38-1.87-.56-2.91-.94-1.04-2.21-1.41-2.84-.84Z"/>
          <path class="cls-80" d="M72.48,81.88c-.63,.57-.38,1.87,.56,2.9,.94,1.03,2.2,1.41,2.83,.84,.63-.57,.37-1.87-.56-2.9s-2.2-1.41-2.83-.84Z"/>
          <path class="cls-141" d="M72.48,81.89c-.62,.57-.37,1.86,.56,2.89,.93,1.03,2.2,1.4,2.82,.83,.62-.57,.37-1.86-.56-2.89-.93-1.03-2.2-1.4-2.82-.83Z"/>
          <path class="cls-130" d="M72.49,81.9c-.62,.56-.37,1.85,.56,2.87s2.19,1.39,2.81,.83c.62-.57,.37-1.85-.56-2.88-.93-1.02-2.19-1.39-2.81-.83Z"/>
          <path class="cls-21" d="M72.5,81.9c-.62,.56-.37,1.84,.56,2.86,.92,1.02,2.18,1.39,2.8,.83,.62-.56,.37-1.84-.55-2.86-.93-1.02-2.18-1.39-2.8-.83Z"/>
          <path class="cls-21" d="M72.5,81.91c-.62,.56-.37,1.84,.55,2.85,.92,1.01,2.17,1.38,2.79,.82,.62-.56,.37-1.84-.55-2.85-.92-1.01-2.17-1.38-2.79-.82Z"/>
          <path class="cls-187" d="M72.51,81.92c-.61,.56-.37,1.83,.55,2.84,.92,1.01,2.16,1.38,2.77,.82,.61-.56,.37-1.83-.55-2.84-.92-1.01-2.16-1.38-2.77-.82Z"/>
          <path class="cls-192" d="M72.52,81.92c-.61,.56-.37,1.82,.55,2.83,.91,1.01,2.15,1.37,2.76,.82,.61-.56,.37-1.82-.55-2.83-.91-1.01-2.15-1.37-2.76-.82Z"/>
          <path class="cls-192" d="M72.52,81.93c-.61,.55-.36,1.81,.55,2.82,.91,1,2.14,1.37,2.75,.81,.61-.55,.37-1.81-.55-2.82-.91-1-2.14-1.37-2.75-.81Z"/>
          <path class="cls-129" d="M72.53,81.94c-.61,.55-.36,1.81,.54,2.8,.91,1,2.13,1.36,2.74,.81,.61-.55,.36-1.81-.54-2.8-.91-1-2.13-1.36-2.74-.81Z"/>
          <path class="cls-129" d="M72.54,81.95c-.6,.55-.36,1.8,.54,2.79,.9,.99,2.12,1.35,2.73,.81,.6-.55,.36-1.8-.54-2.79-.9-.99-2.12-1.35-2.73-.81Z"/>
          <path class="cls-83" d="M72.54,81.95c-.6,.55-.36,1.79,.54,2.78,.9,.99,2.12,1.35,2.72,.8,.6-.55,.36-1.79-.54-2.78-.9-.99-2.12-1.35-2.72-.8Z"/>
          <path class="cls-177" d="M72.55,81.96c-.6,.54-.36,1.78,.54,2.77,.9,.99,2.11,1.34,2.71,.8,.6-.54,.36-1.78-.54-2.77-.9-.99-2.11-1.34-2.71-.8Z"/>
          <path class="cls-177" d="M72.56,81.97c-.6,.54-.36,1.78,.54,2.76,.89,.98,2.1,1.34,2.69,.8,.6-.54,.36-1.78-.54-2.76-.89-.98-2.1-1.34-2.69-.8Z"/>
          <path class="cls-37" d="M72.56,81.98c-.59,.54-.36,1.77,.53,2.75,.89,.98,2.09,1.33,2.68,.79,.59-.54,.36-1.77-.53-2.75-.89-.98-2.09-1.33-2.68-.79Z"/>
          <path class="cls-169" d="M72.57,81.99c-.59,.54-.35,1.76,.53,2.73,.88,.97,2.08,1.33,2.67,.79,.59-.54,.35-1.76-.53-2.74-.88-.97-2.08-1.33-2.67-.79Z"/>
          <path class="cls-19" d="M72.58,81.99c-.59,.53-.35,1.75,.53,2.72,.88,.97,2.07,1.32,2.66,.79,.59-.54,.35-1.75-.53-2.72-.88-.97-2.07-1.32-2.66-.79Z"/>
          <path class="cls-19" d="M72.58,82c-.59,.53-.35,1.75,.53,2.71,.88,.96,2.06,1.31,2.65,.78,.59-.53,.35-1.75-.53-2.71-.88-.96-2.06-1.31-2.65-.78Z"/>
          <path class="cls-19" d="M72.59,82.01c-.58,.53-.35,1.74,.52,2.7,.87,.96,2.05,1.31,2.64,.78,.58-.53,.35-1.74-.52-2.7-.87-.96-2.05-1.31-2.64-.78Z"/>
          <path class="cls-6" d="M72.6,82.02c-.58,.53-.35,1.73,.52,2.69,.87,.96,2.04,1.3,2.63,.78,.58-.53,.35-1.73-.52-2.69-.87-.96-2.04-1.3-2.63-.77Z"/>
          <path class="cls-6" d="M72.61,82.02c-.58,.53-.35,1.72,.52,2.68,.87,.95,2.04,1.3,2.62,.77,.58-.53,.35-1.72-.52-2.68-.87-.95-2.04-1.3-2.62-.77Z"/>
          <path class="cls-6" d="M72.61,82.03c-.58,.52-.34,1.72,.52,2.67,.86,.95,2.03,1.29,2.6,.77,.58-.52,.34-1.72-.52-2.67-.86-.95-2.03-1.29-2.6-.77Z"/>
          <path class="cls-6" d="M72.62,82.04c-.57,.52-.34,1.71,.51,2.65,.86,.94,2.02,1.29,2.59,.77,.57-.52,.34-1.71-.51-2.65-.86-.94-2.02-1.29-2.59-.76Z"/>
          <path class="cls-146" d="M72.63,82.04c-.57,.52-.34,1.7,.51,2.64,.85,.94,2.01,1.28,2.58,.76,.57-.52,.34-1.7-.51-2.64-.85-.94-2.01-1.28-2.58-.76Z"/>
          <path class="cls-161" d="M72.63,82.05c-.57,.52-.34,1.69,.51,2.63,.85,.94,2,1.28,2.57,.76,.57-.52,.34-1.69-.51-2.63-.85-.94-2-1.28-2.57-.76Z"/>
          <path class="cls-161" d="M72.64,82.06c-.57,.51-.34,1.69,.51,2.62,.85,.93,1.99,1.27,2.56,.75s.34-1.69-.51-2.62-1.99-1.27-2.56-.75Z"/>
          <path class="cls-161" d="M72.65,82.07c-.56,.51-.34,1.68,.5,2.61,.84,.93,1.98,1.26,2.55,.75,.56-.51,.34-1.68-.51-2.61-.84-.93-1.98-1.26-2.55-.75Z"/>
          <path class="cls-14" d="M72.65,82.07c-.56,.51-.34,1.67,.5,2.59,.84,.92,1.97,1.26,2.54,.75,.56-.51,.34-1.67-.5-2.59-.84-.92-1.97-1.26-2.54-.75Z"/>
          <path class="cls-14" d="M72.66,82.08c-.56,.51-.33,1.66,.5,2.58,.83,.92,1.96,1.25,2.52,.75s.33-1.66-.5-2.58c-.83-.92-1.96-1.25-2.52-.74Z"/>
          <path class="cls-176" d="M72.67,82.09c-.56,.51-.33,1.66,.5,2.57,.83,.92,1.96,1.25,2.51,.74,.56-.5,.33-1.66-.5-2.57-.83-.92-1.96-1.25-2.51-.74Z"/>
          <path class="cls-176" d="M72.67,82.1c-.55,.5-.33,1.65,.5,2.56,.83,.91,1.95,1.24,2.5,.74,.55-.5,.33-1.65-.5-2.56-.83-.91-1.95-1.24-2.5-.74Z"/>
          <path class="cls-176" d="M72.68,82.11c-.55,.5-.33,1.64,.49,2.55,.82,.91,1.94,1.24,2.49,.74s.33-1.64-.49-2.55c-.82-.91-1.94-1.24-2.49-.73Z"/>
          <path class="cls-40" d="M72.69,82.11c-.55,.5-.33,1.63,.49,2.54,.82,.9,1.93,1.23,2.48,.73,.55-.5,.33-1.63-.49-2.54-.82-.9-1.93-1.23-2.48-.73Z"/>
          <path class="cls-40" d="M72.69,82.12c-.55,.5-.33,1.63,.49,2.53,.82,.9,1.92,1.22,2.47,.73,.55-.5,.33-1.63-.49-2.52s-1.92-1.22-2.47-.73Z"/>
          <path class="cls-199" d="M72.7,82.13c-.54,.49-.33,1.62,.49,2.51,.81,.89,1.91,1.22,2.46,.72,.54-.49,.33-1.62-.49-2.51s-1.91-1.22-2.46-.72Z"/>
          <path class="cls-199" d="M72.71,82.13c-.54,.49-.32,1.61,.48,2.5,.81,.89,1.9,1.21,2.44,.72s.32-1.61-.49-2.5c-.81-.89-1.9-1.21-2.44-.72Z"/>
          <path class="cls-199" d="M72.71,82.14c-.54,.49-.32,1.6,.48,2.49s1.89,1.21,2.43,.72c.54-.49,.32-1.6-.48-2.49-.81-.89-1.89-1.21-2.43-.72Z"/>
          <path class="cls-199" d="M72.72,82.15c-.54,.49-.32,1.6,.48,2.48,.8,.88,1.88,1.2,2.42,.71,.54-.49,.32-1.6-.48-2.48-.8-.88-1.88-1.2-2.42-.71Z"/>
          <path class="cls-199" d="M72.73,82.16c-.53,.49-.32,1.59,.48,2.47,.8,.88,1.88,1.2,2.41,.71,.53-.48,.32-1.59-.48-2.47-.8-.88-1.88-1.2-2.41-.71Z"/>
          <path class="cls-199" d="M72.74,82.16c-.53,.48-.32,1.58,.48,2.46,.79,.87,1.87,1.19,2.4,.71,.53-.48,.32-1.58-.48-2.46-.79-.87-1.87-1.19-2.4-.71Z"/>
          <path class="cls-199" d="M72.74,82.17c-.53,.48-.32,1.57,.47,2.44,.79,.87,1.86,1.18,2.39,.71,.53-.48,.32-1.57-.47-2.44-.79-.87-1.86-1.18-2.39-.7Z"/>
          <path class="cls-199" d="M72.75,82.18c-.53,.48-.32,1.57,.47,2.43,.79,.87,1.85,1.18,2.38,.7,.53-.48,.32-1.57-.47-2.43-.79-.87-1.85-1.18-2.38-.7Z"/>
          <path class="cls-199" d="M72.76,82.19c-.52,.48-.31,1.56,.47,2.42,.78,.86,1.84,1.17,2.36,.7,.52-.48,.31-1.56-.47-2.42-.78-.86-1.84-1.17-2.36-.7Z"/>
          <path class="cls-199" d="M72.76,82.2c-.52,.47-.31,1.55,.47,2.41,.78,.86,1.83,1.17,2.35,.7,.52-.47,.31-1.55-.47-2.41-.78-.86-1.83-1.17-2.35-.69Z"/>
          <path class="cls-199" d="M72.77,82.2c-.52,.47-.31,1.54,.46,2.4,.78,.85,1.82,1.16,2.34,.69,.52-.47,.31-1.54-.46-2.4-.78-.85-1.82-1.16-2.34-.69Z"/>
        </g>
        <g class="cls-98">
          <rect class="cls-199" x="96.33" y="94.96" width="7.44" height="7.85"/>
          <path class="cls-199" d="M96.66,96.47c-.95,.86-.57,2.82,.85,4.38,1.42,1.56,3.33,2.12,4.28,1.26,.95-.86,.57-2.82-.85-4.38-1.42-1.56-3.33-2.12-4.28-1.26Z"/>
          <path class="cls-199" d="M96.66,96.48c-.95,.86-.57,2.82,.85,4.38,1.41,1.56,3.33,2.12,4.28,1.26s.57-2.82-.85-4.38c-1.41-1.56-3.33-2.12-4.28-1.26Z"/>
          <path class="cls-176" d="M96.67,96.48c-.95,.86-.57,2.82,.85,4.37,1.41,1.56,3.33,2.12,4.27,1.26,.95-.86,.57-2.82-.85-4.37-1.41-1.56-3.33-2.12-4.27-1.26Z"/>
          <path class="cls-14" d="M96.67,96.48c-.94,.86-.57,2.81,.85,4.37,1.41,1.55,3.32,2.12,4.27,1.26,.94-.86,.57-2.81-.85-4.37-1.41-1.55-3.32-2.12-4.27-1.26Z"/>
          <path class="cls-161" d="M96.67,96.49c-.94,.86-.56,2.81,.85,4.36,1.41,1.55,3.32,2.12,4.26,1.26,.94-.86,.56-2.81-.85-4.36-1.41-1.55-3.32-2.11-4.26-1.26Z"/>
          <path class="cls-6" d="M96.67,96.49c-.94,.86-.56,2.81,.84,4.36,1.41,1.55,3.31,2.11,4.26,1.26,.94-.86,.56-2.81-.84-4.36-1.41-1.55-3.31-2.11-4.26-1.26Z"/>
          <path class="cls-6" d="M96.68,96.49c-.94,.85-.56,2.8,.84,4.35,1.41,1.55,3.31,2.11,4.25,1.25,.94-.85,.56-2.8-.84-4.35-1.41-1.55-3.31-2.11-4.25-1.25Z"/>
          <path class="cls-19" d="M96.68,96.5c-.94,.85-.56,2.8,.84,4.35,1.41,1.55,3.31,2.11,4.25,1.25,.94-.85,.56-2.8-.84-4.35-1.41-1.55-3.31-2.11-4.25-1.25Z"/>
          <path class="cls-169" d="M96.68,96.5c-.94,.85-.56,2.8,.84,4.34,1.4,1.54,3.3,2.1,4.24,1.25,.94-.85,.56-2.8-.84-4.34-1.4-1.54-3.3-2.1-4.24-1.25Z"/>
          <path class="cls-37" d="M96.69,96.5c-.94,.85-.56,2.79,.84,4.34,1.4,1.54,3.3,2.1,4.24,1.25,.94-.85,.56-2.79-.84-4.34-1.4-1.54-3.3-2.1-4.23-1.25Z"/>
          <path class="cls-60" d="M96.69,96.51c-.94,.85-.56,2.79,.84,4.33,1.4,1.54,3.29,2.1,4.23,1.25,.94-.85,.56-2.79-.84-4.33-1.4-1.54-3.29-2.1-4.23-1.25Z"/>
          <path class="cls-129" d="M96.69,96.51c-.93,.85-.56,2.79,.84,4.32,1.4,1.54,3.29,2.1,4.22,1.25,.93-.85,.56-2.79-.84-4.32-1.4-1.54-3.29-2.1-4.22-1.25Z"/>
          <path class="cls-192" d="M96.7,96.51c-.93,.85-.56,2.78,.84,4.32,1.4,1.54,3.29,2.09,4.22,1.25,.93-.85,.56-2.78-.84-4.32-1.4-1.54-3.29-2.09-4.22-1.25Z"/>
          <path class="cls-205" d="M96.7,96.52c-.93,.85-.56,2.78,.84,4.31,1.39,1.54,3.28,2.09,4.21,1.24,.93-.85,.56-2.78-.84-4.31-1.39-1.54-3.28-2.09-4.21-1.24Z"/>
          <path class="cls-64" d="M96.7,96.52c-.93,.85-.56,2.78,.83,4.31,1.39,1.53,3.28,2.09,4.21,1.24,.93-.85,.56-2.78-.83-4.31-1.39-1.53-3.28-2.09-4.21-1.24Z"/>
          <path class="cls-21" d="M96.71,96.52c-.93,.85-.56,2.77,.83,4.3,1.39,1.53,3.27,2.09,4.2,1.24,.93-.85,.56-2.77-.83-4.3-1.39-1.53-3.27-2.09-4.2-1.24Z"/>
          <path class="cls-130" d="M96.71,96.53c-.93,.84-.56,2.77,.83,4.3,1.39,1.53,3.27,2.08,4.2,1.24,.93-.84,.56-2.77-.83-4.3-1.39-1.53-3.27-2.08-4.2-1.24Z"/>
          <path class="cls-80" d="M96.71,96.53c-.93,.84-.56,2.77,.83,4.29,1.39,1.53,3.26,2.08,4.19,1.24,.93-.84,.56-2.77-.83-4.29-1.39-1.53-3.26-2.08-4.19-1.24Z"/>
          <path class="cls-94" d="M96.72,96.53c-.93,.84-.56,2.76,.83,4.29,1.39,1.52,3.26,2.08,4.19,1.24,.93-.84,.56-2.76-.83-4.29-1.39-1.53-3.26-2.08-4.19-1.24Z"/>
          <path class="cls-163" d="M96.72,96.54c-.93,.84-.55,2.76,.83,4.28,1.38,1.52,3.26,2.08,4.18,1.23s.55-2.76-.83-4.28c-1.38-1.52-3.26-2.08-4.18-1.23Z"/>
          <path class="cls-91" d="M96.72,96.54c-.92,.84-.55,2.76,.83,4.28,1.38,1.52,3.25,2.07,4.18,1.23,.92-.84,.55-2.76-.83-4.28s-3.25-2.07-4.18-1.23Z"/>
          <path class="cls-204" d="M96.72,96.54c-.92,.84-.55,2.75,.83,4.27s3.25,2.07,4.17,1.23c.92-.84,.55-2.75-.83-4.27-1.38-1.52-3.25-2.07-4.17-1.23Z"/>
          <path class="cls-35" d="M96.73,96.55c-.92,.84-.55,2.75,.83,4.27,1.38,1.52,3.25,2.07,4.17,1.23,.92-.84,.55-2.75-.83-4.27s-3.25-2.07-4.17-1.23Z"/>
          <path class="cls-126" d="M96.73,96.55c-.92,.84-.55,2.75,.83,4.26,1.38,1.52,3.24,2.07,4.16,1.23,.92-.84,.55-2.75-.83-4.26-1.38-1.52-3.24-2.07-4.16-1.23Z"/>
          <path class="cls-201" d="M96.73,96.55c-.92,.84-.55,2.74,.82,4.26,1.38,1.51,3.24,2.06,4.16,1.23,.92-.84,.55-2.74-.82-4.26-1.38-1.51-3.24-2.06-4.16-1.23Z"/>
          <path class="cls-170" d="M96.74,96.56c-.92,.83-.55,2.74,.82,4.25s3.23,2.06,4.15,1.23c.92-.83,.55-2.74-.82-4.25s-3.23-2.06-4.15-1.23Z"/>
          <path class="cls-75" d="M96.74,96.56c-.92,.84-.55,2.74,.82,4.25,1.37,1.51,3.23,2.06,4.15,1.22,.92-.83,.55-2.74-.82-4.25-1.37-1.51-3.23-2.06-4.15-1.22Z"/>
          <path class="cls-121" d="M96.74,96.57c-.92,.83-.55,2.73,.82,4.24,1.37,1.51,3.22,2.06,4.14,1.22s.55-2.73-.82-4.24c-1.37-1.51-3.22-2.06-4.14-1.22Z"/>
          <path class="cls-78" d="M96.75,96.57c-.92,.83-.55,2.73,.82,4.23,1.37,1.51,3.22,2.05,4.14,1.22,.91-.83,.55-2.73-.82-4.23-1.37-1.51-3.22-2.05-4.14-1.22Z"/>
          <path class="cls-107" d="M96.75,96.57c-.91,.83-.55,2.72,.82,4.23,1.37,1.5,3.22,2.05,4.13,1.22,.91-.83,.55-2.72-.82-4.23-1.37-1.5-3.22-2.05-4.13-1.22Z"/>
          <path class="cls-202" d="M96.75,96.57c-.91,.83-.55,2.72,.82,4.22,1.37,1.5,3.21,2.05,4.13,1.22,.91-.83,.55-2.72-.82-4.22-1.37-1.5-3.21-2.05-4.13-1.22Z"/>
          <path class="cls-42" d="M96.76,96.58c-.91,.83-.55,2.72,.82,4.22,1.36,1.5,3.21,2.05,4.12,1.22,.91-.83,.55-2.72-.82-4.22-1.36-1.5-3.21-2.05-4.12-1.22Z"/>
          <path class="cls-5" d="M96.76,96.58c-.91,.83-.55,2.71,.82,4.21,1.36,1.5,3.2,2.04,4.12,1.21,.91-.83,.55-2.71-.82-4.21-1.36-1.5-3.21-2.04-4.12-1.21Z"/>
          <path class="cls-2" d="M96.76,96.59c-.91,.83-.54,2.71,.82,4.21,1.36,1.5,3.2,2.04,4.11,1.21,.91-.83,.54-2.71-.82-4.21-1.36-1.5-3.2-2.04-4.11-1.21Z"/>
          <path class="cls-191" d="M96.76,96.59c-.91,.83-.54,2.71,.81,4.2,1.36,1.5,3.2,2.04,4.11,1.21,.91-.83,.54-2.71-.81-4.2-1.36-1.5-3.2-2.04-4.11-1.21Z"/>
          <path class="cls-112" d="M96.77,96.59c-.91,.82-.54,2.7,.81,4.2,1.36,1.49,3.19,2.04,4.1,1.21,.91-.82,.54-2.7-.81-4.2-1.36-1.49-3.19-2.04-4.1-1.21Z"/>
          <path class="cls-101" d="M96.77,96.6c-.91,.82-.54,2.7,.81,4.19,1.36,1.49,3.19,2.03,4.09,1.21,.91-.82,.54-2.7-.81-4.19-1.36-1.49-3.19-2.03-4.09-1.21Z"/>
          <path class="cls-136" d="M96.77,96.6c-.91,.82-.54,2.7,.81,4.19,1.35,1.49,3.18,2.03,4.09,1.21,.91-.82,.54-2.7-.81-4.19-1.35-1.49-3.18-2.03-4.09-1.21Z"/>
          <path class="cls-100" d="M96.78,96.6c-.9,.82-.54,2.69,.81,4.18,1.35,1.49,3.18,2.03,4.08,1.21,.9-.82,.54-2.69-.81-4.18-1.35-1.49-3.18-2.03-4.08-1.21Z"/>
          <path class="cls-103" d="M96.78,96.61c-.9,.82-.54,2.69,.81,4.18,1.35,1.49,3.18,2.03,4.08,1.2,.9-.82,.54-2.69-.81-4.18-1.35-1.49-3.18-2.03-4.08-1.2Z"/>
          <path class="cls-71" d="M96.78,96.61c-.9,.82-.54,2.69,.81,4.17,1.35,1.48,3.17,2.02,4.08,1.2,.9-.82,.54-2.69-.81-4.17-1.35-1.48-3.17-2.02-4.08-1.2Z"/>
          <path class="cls-189" d="M96.79,96.61c-.9,.82-.54,2.68,.81,4.16,1.35,1.48,3.17,2.02,4.07,1.2,.9-.82,.54-2.68-.81-4.17-1.35-1.48-3.17-2.02-4.07-1.2Z"/>
          <path class="cls-1" d="M96.79,96.62c-.9,.82-.54,2.68,.81,4.16,1.35,1.48,3.16,2.02,4.06,1.2,.9-.82,.54-2.68-.81-4.16-1.35-1.48-3.16-2.02-4.06-1.2Z"/>
          <path class="cls-85" d="M96.79,96.62c-.9,.82-.54,2.68,.8,4.15,1.34,1.48,3.16,2.01,4.06,1.2,.9-.82,.54-2.68-.81-4.15-1.34-1.48-3.16-2.01-4.06-1.2Z"/>
          <path class="cls-160" d="M96.8,96.62c-.9,.82-.54,2.67,.8,4.15,1.34,1.48,3.16,2.01,4.05,1.2,.9-.82,.54-2.67-.8-4.15-1.34-1.48-3.16-2.01-4.05-1.2Z"/>
          <path class="cls-175" d="M96.8,96.63c-.9,.81-.54,2.67,.8,4.14,1.34,1.47,3.15,2.01,4.05,1.19,.9-.81,.54-2.67-.8-4.14-1.34-1.47-3.15-2.01-4.05-1.19Z"/>
          <path class="cls-123" d="M96.8,96.63c-.89,.81-.54,2.67,.8,4.14,1.34,1.47,3.15,2.01,4.04,1.19,.89-.81,.54-2.67-.8-4.14-1.34-1.47-3.15-2.01-4.04-1.19Z"/>
          <path class="cls-16" d="M96.8,96.63c-.89,.81-.54,2.66,.8,4.13,1.34,1.47,3.14,2,4.04,1.19,.89-.81,.54-2.66-.8-4.13s-3.14-2-4.04-1.19Z"/>
          <path class="cls-15" d="M96.81,96.64c-.89,.81-.54,2.66,.8,4.13,1.33,1.47,3.14,2,4.03,1.19,.89-.81,.54-2.66-.8-4.13-1.33-1.47-3.14-2-4.03-1.19Z"/>
          <path class="cls-38" d="M96.81,96.64c-.89,.81-.53,2.66,.8,4.12s3.14,2,4.03,1.19c.89-.81,.53-2.66-.8-4.12s-3.14-2-4.03-1.19Z"/>
          <path class="cls-90" d="M96.81,96.64c-.89,.81-.53,2.65,.8,4.12,1.33,1.46,3.13,2,4.02,1.19,.89-.81,.53-2.65-.8-4.12-1.33-1.46-3.13-2-4.02-1.19Z"/>
          <path class="cls-74" d="M96.82,96.65c-.89,.81-.53,2.65,.8,4.11,1.33,1.46,3.13,1.99,4.02,1.19,.89-.81,.53-2.65-.8-4.11-1.33-1.46-3.13-1.99-4.02-1.19Z"/>
          <path class="cls-186" d="M96.82,96.65c-.89,.81-.53,2.65,.8,4.11,1.33,1.46,3.12,1.99,4.01,1.18,.89-.81,.53-2.65-.8-4.11-1.33-1.46-3.12-1.99-4.01-1.18Z"/>
          <path class="cls-193" d="M96.82,96.65c-.89,.81-.53,2.64,.8,4.1,1.33,1.46,3.12,1.99,4.01,1.18,.89-.81,.53-2.64-.8-4.1-1.33-1.46-3.12-1.99-4.01-1.18Z"/>
          <path class="cls-185" d="M96.83,96.66c-.89,.8-.53,2.64,.79,4.1,1.32,1.46,3.12,1.99,4,1.18,.89-.8,.53-2.64-.79-4.1-1.32-1.46-3.12-1.99-4-1.18Z"/>
          <path class="cls-111" d="M96.83,96.66c-.88,.8-.53,2.64,.79,4.09,1.32,1.46,3.11,1.98,4,1.18,.88-.8,.53-2.64-.79-4.09-1.32-1.46-3.11-1.98-4-1.18Z"/>
          <path class="cls-58" d="M96.83,96.66c-.88,.8-.53,2.63,.79,4.09,1.32,1.45,3.11,1.98,3.99,1.18,.88-.8,.53-2.63-.79-4.09-1.32-1.45-3.11-1.98-3.99-1.18Z"/>
          <path class="cls-69" d="M96.84,96.67c-.88,.8-.53,2.63,.79,4.08,1.32,1.45,3.1,1.98,3.99,1.18,.88-.8,.53-2.63-.79-4.08-1.32-1.45-3.1-1.98-3.99-1.18Z"/>
          <path class="cls-73" d="M96.84,96.67c-.88,.8-.53,2.63,.79,4.08,1.32,1.45,3.1,1.98,3.98,1.18,.88-.8,.53-2.63-.79-4.08-1.32-1.45-3.1-1.98-3.98-1.17Z"/>
          <path class="cls-168" d="M96.84,96.67c-.88,.8-.53,2.62,.79,4.07,1.32,1.45,3.1,1.97,3.98,1.17,.88-.8,.53-2.62-.79-4.07-1.32-1.45-3.1-1.97-3.98-1.17Z"/>
          <path class="cls-87" d="M96.85,96.68c-.88,.8-.53,2.62,.79,4.07,1.31,1.45,3.09,1.97,3.97,1.17,.88-.8,.53-2.62-.79-4.07-1.31-1.45-3.09-1.97-3.97-1.17Z"/>
          <path class="cls-4" d="M96.85,96.68c-.88,.8-.53,2.62,.79,4.06s3.09,1.97,3.97,1.17,.53-2.62-.79-4.06c-1.31-1.44-3.09-1.97-3.97-1.17Z"/>
          <path class="cls-128" d="M96.85,96.68c-.88,.8-.53,2.61,.79,4.05,1.31,1.44,3.08,1.97,3.96,1.17,.88-.8,.52-2.61-.79-4.05-1.31-1.44-3.08-1.97-3.96-1.17Z"/>
          <path class="cls-62" d="M96.85,96.69c-.88,.8-.52,2.61,.79,4.05s3.08,1.96,3.96,1.17c.88-.8,.52-2.61-.79-4.05-1.31-1.44-3.08-1.96-3.96-1.17Z"/>
          <path class="cls-114" d="M96.86,96.69c-.87,.79-.52,2.61,.78,4.04,1.31,1.44,3.08,1.96,3.95,1.17,.87-.8,.52-2.61-.78-4.04-1.31-1.44-3.08-1.96-3.95-1.17Z"/>
          <path class="cls-41" d="M96.86,96.69c-.87,.79-.52,2.6,.78,4.04s3.07,1.96,3.95,1.16c.87-.79,.52-2.6-.78-4.04-1.31-1.44-3.07-1.96-3.95-1.17Z"/>
          <path class="cls-72" d="M96.86,96.7c-.87,.79-.52,2.6,.78,4.03,1.3,1.44,3.07,1.96,3.94,1.16,.87-.79,.52-2.6-.78-4.03-1.3-1.44-3.07-1.96-3.94-1.16Z"/>
          <path class="cls-52" d="M96.87,96.7c-.87,.79-.52,2.6,.78,4.03,1.3,1.43,3.06,1.95,3.94,1.16,.87-.79,.52-2.6-.78-4.03-1.3-1.43-3.06-1.95-3.94-1.16Z"/>
          <path class="cls-147" d="M96.87,96.7c-.87,.79-.52,2.59,.78,4.02,1.3,1.43,3.06,1.95,3.93,1.16,.87-.79,.52-2.59-.78-4.02-1.3-1.43-3.06-1.95-3.93-1.16Z"/>
          <path class="cls-56" d="M96.87,96.71c-.87,.79-.52,2.59,.78,4.02,1.3,1.43,3.06,1.95,3.92,1.16,.87-.79,.52-2.59-.78-4.02-1.3-1.43-3.06-1.95-3.92-1.16Z"/>
          <path class="cls-86" d="M96.88,96.71c-.87,.79-.52,2.59,.78,4.01,1.3,1.43,3.05,1.95,3.92,1.16,.87-.79,.52-2.58-.78-4.01-1.3-1.43-3.05-1.95-3.92-1.16Z"/>
          <path class="cls-134" d="M96.88,96.71c-.87,.79-.52,2.58,.78,4.01,1.29,1.43,3.05,1.94,3.91,1.16,.87-.79,.52-2.58-.78-4.01-1.3-1.42-3.05-1.94-3.91-1.16Z"/>
          <path class="cls-96" d="M96.88,96.72c-.87,.79-.52,2.58,.78,4,1.29,1.42,3.04,1.94,3.91,1.15,.87-.79,.52-2.58-.78-4-1.29-1.42-3.04-1.94-3.91-1.15Z"/>
          <path class="cls-150" d="M96.89,96.72c-.86,.79-.52,2.58,.77,4,1.29,1.42,3.04,1.94,3.9,1.15,.86-.79,.52-2.58-.77-4s-3.04-1.94-3.9-1.15Z"/>
          <path class="cls-81" d="M96.89,96.73c-.86,.78-.52,2.57,.77,3.99,1.29,1.42,3.04,1.93,3.9,1.15s.52-2.57-.77-3.99c-1.29-1.42-3.04-1.94-3.9-1.15Z"/>
          <path class="cls-97" d="M96.89,96.73c-.86,.78-.52,2.57,.77,3.99,1.29,1.42,3.03,1.93,3.89,1.15,.86-.78,.52-2.57-.77-3.99-1.29-1.42-3.03-1.93-3.89-1.15Z"/>
          <path class="cls-194" d="M96.89,96.73c-.86,.78-.51,2.56,.77,3.98,1.29,1.42,3.03,1.93,3.89,1.15,.86-.78,.51-2.56-.77-3.98-1.29-1.42-3.03-1.93-3.89-1.15Z"/>
          <path class="cls-55" d="M96.9,96.74c-.86,.78-.51,2.56,.77,3.98,1.29,1.41,3.02,1.93,3.88,1.15,.86-.78,.52-2.56-.77-3.98-1.29-1.41-3.02-1.93-3.88-1.15Z"/>
          <path class="cls-105" d="M96.9,96.74c-.86,.78-.51,2.56,.77,3.97,1.28,1.41,3.02,1.92,3.88,1.14,.86-.78,.51-2.56-.77-3.97-1.28-1.41-3.02-1.92-3.88-1.14Z"/>
          <path class="cls-9" d="M96.9,96.74c-.86,.78-.51,2.55,.77,3.96,1.28,1.41,3.02,1.92,3.87,1.14,.86-.78,.51-2.55-.77-3.96-1.28-1.41-3.02-1.92-3.87-1.14Z"/>
          <path class="cls-3" d="M96.91,96.74c-.86,.78-.51,2.55,.77,3.96,1.28,1.41,3.01,1.92,3.87,1.14,.86-.78,.51-2.55-.77-3.96-1.28-1.41-3.01-1.92-3.87-1.14Z"/>
          <path class="cls-22" d="M96.91,96.75c-.86,.78-.51,2.55,.77,3.95,1.28,1.41,3.01,1.92,3.86,1.14,.85-.78,.51-2.55-.77-3.95-1.28-1.41-3.01-1.92-3.86-1.14Z"/>
          <path class="cls-196" d="M96.91,96.75c-.85,.78-.51,2.55,.77,3.95,1.28,1.41,3,1.92,3.86,1.14,.85-.78,.51-2.54-.77-3.95-1.28-1.41-3-1.92-3.86-1.14Z"/>
          <path class="cls-110" d="M96.92,96.76c-.85,.78-.51,2.54,.76,3.94,1.28,1.4,3,1.91,3.85,1.14,.85-.78,.51-2.54-.76-3.94-1.28-1.4-3-1.91-3.85-1.14Z"/>
          <path class="cls-116" d="M96.92,96.76c-.85,.77-.51,2.54,.76,3.94,1.27,1.4,3,1.91,3.85,1.14,.85-.77,.51-2.54-.76-3.94-1.27-1.4-3-1.91-3.85-1.14Z"/>
          <path class="cls-124" d="M96.92,96.76c-.85,.77-.51,2.53,.76,3.93,1.27,1.4,2.99,1.91,3.84,1.13,.85-.77,.51-2.53-.76-3.93-1.27-1.4-2.99-1.91-3.84-1.13Z"/>
          <path class="cls-18" d="M96.93,96.77c-.85,.77-.51,2.53,.76,3.93,1.27,1.4,2.99,1.9,3.84,1.13,.85-.77,.51-2.53-.76-3.93-1.27-1.4-2.99-1.9-3.84-1.13Z"/>
          <path class="cls-57" d="M96.93,96.77c-.85,.77-.51,2.53,.76,3.92,1.27,1.4,2.98,1.9,3.83,1.13,.85-.77,.51-2.53-.76-3.92-1.27-1.4-2.98-1.9-3.83-1.13Z"/>
          <path class="cls-148" d="M96.93,96.77c-.85,.77-.51,2.52,.76,3.92,1.27,1.39,2.98,1.9,3.83,1.13,.85-.77,.51-2.52-.76-3.92-1.27-1.39-2.98-1.9-3.83-1.13Z"/>
          <path class="cls-113" d="M96.93,96.78c-.85,.77-.51,2.52,.76,3.91,1.26,1.39,2.98,1.9,3.82,1.13,.85-.77,.51-2.52-.76-3.91-1.26-1.39-2.98-1.9-3.82-1.13Z"/>
          <path class="cls-125" d="M96.94,96.78c-.84,.77-.51,2.52,.76,3.91,1.26,1.39,2.97,1.89,3.82,1.13,.84-.77,.51-2.52-.76-3.91-1.26-1.39-2.97-1.89-3.82-1.13Z"/>
          <path class="cls-209" d="M96.94,96.78c-.84,.77-.5,2.51,.76,3.9,1.26,1.39,2.97,1.89,3.81,1.12,.84-.77,.5-2.51-.76-3.9-1.26-1.39-2.97-1.89-3.81-1.12Z"/>
          <path class="cls-142" d="M96.94,96.79c-.84,.77-.5,2.51,.75,3.9,1.26,1.39,2.96,1.89,3.81,1.12,.84-.77,.5-2.51-.75-3.9-1.26-1.39-2.96-1.89-3.81-1.12Z"/>
          <path class="cls-183" d="M96.95,96.79c-.84,.76-.5,2.51,.75,3.89,1.26,1.38,2.96,1.89,3.8,1.12,.84-.76,.5-2.51-.75-3.89-1.26-1.38-2.96-1.89-3.8-1.12Z"/>
          <path class="cls-127" d="M96.95,96.79c-.84,.76-.5,2.5,.75,3.89,1.26,1.38,2.95,1.88,3.8,1.12,.84-.76,.5-2.5-.75-3.89-1.26-1.38-2.96-1.88-3.8-1.12Z"/>
          <path class="cls-10" d="M96.95,96.8c-.84,.76-.5,2.5,.75,3.88,1.25,1.38,2.95,1.88,3.79,1.12,.84-.76,.5-2.5-.75-3.88-1.25-1.38-2.95-1.88-3.79-1.12Z"/>
          <path class="cls-149" d="M96.96,96.8c-.84,.76-.5,2.5,.75,3.88,1.25,1.38,2.95,1.88,3.79,1.12,.84-.76,.5-2.5-.75-3.88-1.25-1.38-2.95-1.88-3.79-1.12Z"/>
          <path class="cls-95" d="M96.96,96.8c-.84,.76-.5,2.49,.75,3.87,1.25,1.38,2.94,1.88,3.78,1.12,.84-.76,.5-2.49-.75-3.87-1.25-1.38-2.94-1.88-3.78-1.12Z"/>
          <path class="cls-102" d="M96.96,96.81c-.84,.76-.5,2.49,.75,3.86,1.25,1.38,2.94,1.87,3.78,1.12,.84-.76,.5-2.49-.75-3.87-1.25-1.37-2.94-1.87-3.78-1.11Z"/>
          <path class="cls-106" d="M96.97,96.81c-.83,.76-.5,2.49,.75,3.86,1.25,1.37,2.94,1.87,3.77,1.11,.83-.76,.5-2.49-.75-3.86-1.25-1.37-2.94-1.87-3.77-1.11Z"/>
          <path class="cls-109" d="M96.97,96.81c-.83,.76-.5,2.48,.75,3.85s2.93,1.87,3.77,1.11c.83-.76,.5-2.48-.75-3.85-1.25-1.37-2.93-1.87-3.77-1.11Z"/>
          <path class="cls-119" d="M96.97,96.82c-.83,.76-.5,2.48,.75,3.85,1.25,1.37,2.93,1.87,3.76,1.11,.83-.76,.5-2.48-.75-3.85-1.25-1.37-2.93-1.87-3.76-1.11Z"/>
          <path class="cls-66" d="M96.98,96.82c-.83,.76-.5,2.48,.75,3.84,1.24,1.37,2.92,1.86,3.75,1.11,.83-.76,.5-2.48-.75-3.84-1.24-1.37-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-172" d="M96.98,96.82c-.83,.75-.5,2.47,.74,3.84,1.24,1.37,2.92,1.86,3.75,1.11,.83-.75,.5-2.47-.74-3.84-1.24-1.37-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-178" d="M96.98,96.83c-.83,.75-.5,2.47,.74,3.83,1.24,1.36,2.91,1.86,3.74,1.11s.5-2.47-.74-3.83c-1.24-1.36-2.91-1.86-3.74-1.11Z"/>
          <path class="cls-93" d="M96.98,96.83c-.83,.75-.5,2.47,.74,3.83s2.91,1.86,3.74,1.1c.83-.75,.5-2.47-.74-3.83-1.24-1.36-2.91-1.86-3.74-1.1Z"/>
          <path class="cls-65" d="M96.99,96.83c-.83,.75-.49,2.46,.74,3.82,1.24,1.36,2.91,1.85,3.73,1.1,.83-.75,.49-2.46-.74-3.82-1.24-1.36-2.91-1.85-3.73-1.1Z"/>
          <path class="cls-164" d="M96.99,96.84c-.83,.75-.49,2.46,.74,3.82,1.23,1.36,2.9,1.85,3.73,1.1,.83-.75,.49-2.46-.74-3.82-1.23-1.36-2.9-1.85-3.73-1.1Z"/>
          <path class="cls-174" d="M96.99,96.84c-.82,.75-.49,2.46,.74,3.81,1.23,1.36,2.9,1.85,3.72,1.1,.82-.75,.49-2.46-.74-3.81-1.23-1.36-2.9-1.85-3.72-1.1Z"/>
          <path class="cls-92" d="M97,96.84c-.82,.75-.49,2.45,.74,3.81,1.23,1.35,2.9,1.85,3.72,1.1,.82-.75,.49-2.45-.74-3.81-1.23-1.35-2.9-1.85-3.72-1.1Z"/>
          <path class="cls-203" d="M97,96.85c-.82,.75-.49,2.45,.74,3.8,1.23,1.35,2.89,1.84,3.71,1.1,.82-.75,.49-2.45-.74-3.8-1.23-1.35-2.89-1.84-3.71-1.1Z"/>
          <path class="cls-144" d="M97,96.85c-.82,.75-.49,2.45,.74,3.79,1.23,1.35,2.89,1.84,3.71,1.09,.82-.75,.49-2.45-.74-3.79-1.23-1.35-2.89-1.84-3.71-1.09Z"/>
          <path class="cls-195" d="M97.01,96.85c-.82,.75-.49,2.44,.74,3.79,1.23,1.35,2.88,1.84,3.7,1.09,.82-.75,.49-2.44-.74-3.79-1.23-1.35-2.88-1.84-3.7-1.09Z"/>
          <path class="cls-140" d="M97.01,96.86c-.82,.74-.49,2.44,.73,3.79,1.22,1.35,2.88,1.84,3.7,1.09,.82-.74,.49-2.44-.73-3.79s-2.88-1.84-3.7-1.09Z"/>
          <path class="cls-120" d="M97.01,96.86c-.82,.74-.49,2.44,.73,3.78s2.87,1.83,3.69,1.09c.82-.74,.49-2.44-.73-3.78-1.22-1.34-2.87-1.83-3.69-1.09Z"/>
          <path class="cls-173" d="M97.02,96.86c-.82,.74-.49,2.43,.73,3.77,1.22,1.34,2.87,1.83,3.69,1.09,.82-.74,.49-2.43-.73-3.77s-2.87-1.83-3.69-1.09Z"/>
          <path class="cls-79" d="M97.02,96.87c-.82,.74-.49,2.43,.73,3.77,1.22,1.34,2.87,1.83,3.68,1.09,.82-.74,.49-2.43-.73-3.77-1.22-1.34-2.87-1.83-3.68-1.09Z"/>
          <path class="cls-179" d="M97.02,96.87c-.81,.74-.49,2.42,.73,3.76,1.22,1.34,2.86,1.83,3.68,1.08,.81-.74,.49-2.42-.73-3.76-1.22-1.34-2.86-1.83-3.68-1.08Z"/>
          <path class="cls-179" d="M97.02,96.87c-.81,.74-.49,2.42,.73,3.76,1.22,1.34,2.86,1.82,3.67,1.08,.81-.74,.49-2.42-.73-3.76-1.22-1.34-2.86-1.82-3.67-1.08Z"/>
          <path class="cls-173" d="M97.03,96.88c-.81,.74-.48,2.41,.73,3.75,1.21,1.33,2.85,1.82,3.66,1.08,.81-.74,.48-2.41-.73-3.75-1.21-1.33-2.85-1.82-3.66-1.08Z"/>
          <path class="cls-140" d="M97.04,96.89c-.81,.73-.48,2.41,.72,3.74,1.21,1.33,2.84,1.81,3.65,1.08,.81-.73,.48-2.41-.72-3.74-1.21-1.33-2.84-1.81-3.65-1.08Z"/>
          <path class="cls-144" d="M97.04,96.9c-.8,.73-.48,2.4,.72,3.72,1.2,1.33,2.83,1.81,3.64,1.07,.8-.73,.48-2.4-.72-3.72-1.2-1.33-2.83-1.81-3.64-1.07Z"/>
          <path class="cls-115" d="M97.05,96.91c-.8,.73-.48,2.39,.72,3.71s2.82,1.8,3.63,1.07,.48-2.39-.72-3.71-2.82-1.8-3.63-1.07Z"/>
          <path class="cls-88" d="M97.06,96.91c-.8,.73-.48,2.38,.72,3.7,1.2,1.32,2.81,1.79,3.62,1.07,.8-.73,.48-2.38-.72-3.7s-2.81-1.79-3.62-1.07Z"/>
          <path class="cls-162" d="M97.07,96.92c-.8,.72-.48,2.38,.71,3.69,1.19,1.31,2.81,1.79,3.6,1.06,.8-.72,.48-2.38-.71-3.69-1.19-1.31-2.81-1.79-3.6-1.06Z"/>
          <path class="cls-133" d="M97.07,96.93c-.79,.72-.48,2.37,.71,3.68,1.19,1.31,2.8,1.78,3.59,1.06,.79-.72,.48-2.37-.71-3.68-1.19-1.31-2.8-1.78-3.59-1.06Z"/>
          <path class="cls-66" d="M97.08,96.93c-.79,.72-.47,2.36,.71,3.67,1.18,1.3,2.79,1.78,3.58,1.06,.79-.72,.47-2.36-.71-3.66-1.18-1.3-2.79-1.78-3.58-1.06Z"/>
          <path class="cls-109" d="M97.09,96.94c-.79,.72-.47,2.35,.71,3.65,1.18,1.3,2.78,1.77,3.57,1.05,.79-.72,.47-2.35-.71-3.65-1.18-1.3-2.78-1.77-3.57-1.05Z"/>
          <path class="cls-43" d="M97.09,96.95c-.79,.72-.47,2.35,.71,3.64s2.77,1.77,3.56,1.05c.79-.72,.47-2.35-.71-3.64-1.18-1.3-2.77-1.77-3.56-1.05Z"/>
          <path class="cls-95" d="M97.1,96.96c-.79,.71-.47,2.34,.7,3.63,1.17,1.29,2.76,1.76,3.55,1.05,.79-.71,.47-2.34-.7-3.63-1.17-1.29-2.76-1.76-3.55-1.05Z"/>
          <path class="cls-99" d="M97.11,96.96c-.78,.71-.47,2.33,.7,3.62,1.17,1.29,2.75,1.75,3.54,1.04,.78-.71,.47-2.33-.7-3.62-1.17-1.29-2.75-1.75-3.54-1.04Z"/>
          <path class="cls-181" d="M97.11,96.97c-.78,.71-.47,2.32,.7,3.61,1.17,1.28,2.74,1.75,3.52,1.04,.78-.71,.47-2.32-.7-3.61-1.17-1.28-2.74-1.75-3.52-1.04Z"/>
          <path class="cls-152" d="M97.12,96.98c-.78,.71-.47,2.32,.7,3.6,1.16,1.28,2.74,1.74,3.51,1.04,.78-.71,.47-2.32-.7-3.6-1.16-1.28-2.74-1.74-3.51-1.04Z"/>
          <path class="cls-125" d="M97.13,96.99c-.78,.7-.46,2.31,.69,3.58,1.16,1.28,2.73,1.74,3.5,1.03s.46-2.31-.69-3.58c-1.16-1.28-2.73-1.74-3.5-1.03Z"/>
          <path class="cls-148" d="M97.13,96.99c-.77,.7-.46,2.3,.69,3.57,1.16,1.27,2.72,1.73,3.49,1.03,.77-.7,.46-2.3-.69-3.57-1.16-1.27-2.72-1.73-3.49-1.03Z"/>
          <path class="cls-68" d="M97.14,97c-.77,.7-.46,2.3,.69,3.56s2.71,1.73,3.48,1.03c.77-.7,.46-2.29-.69-3.56s-2.71-1.73-3.48-1.03Z"/>
          <path class="cls-157" d="M97.15,97.01c-.77,.7-.46,2.29,.69,3.55,1.15,1.26,2.7,1.72,3.47,1.02,.77-.7,.46-2.29-.69-3.55-1.15-1.26-2.7-1.72-3.47-1.02Z"/>
          <path class="cls-143" d="M97.15,97.02c-.77,.7-.46,2.28,.69,3.54,1.14,1.26,2.69,1.72,3.46,1.02,.76-.7,.46-2.28-.69-3.54-1.14-1.26-2.69-1.72-3.46-1.02Z"/>
          <path class="cls-196" d="M97.16,97.02c-.76,.69-.46,2.27,.68,3.53,1.14,1.25,2.68,1.71,3.44,1.02,.76-.69,.46-2.27-.68-3.53-1.14-1.25-2.68-1.71-3.44-1.02Z"/>
          <path class="cls-104" d="M97.17,97.03c-.76,.69-.46,2.26,.68,3.51,1.14,1.25,2.67,1.7,3.43,1.01,.76-.69,.46-2.26-.68-3.51-1.14-1.25-2.67-1.7-3.43-1.01Z"/>
          <path class="cls-105" d="M97.17,97.04c-.76,.69-.45,2.26,.68,3.5,1.13,1.25,2.66,1.7,3.42,1.01,.76-.69,.45-2.26-.68-3.5-1.13-1.25-2.66-1.7-3.42-1.01Z"/>
          <path class="cls-137" d="M97.18,97.05c-.75,.69-.45,2.25,.68,3.49,1.13,1.24,2.66,1.69,3.41,1.01,.75-.69,.45-2.25-.68-3.49-1.13-1.24-2.66-1.69-3.41-1.01Z"/>
          <path class="cls-167" d="M97.19,97.05c-.75,.68-.45,2.24,.67,3.48,1.12,1.24,2.65,1.69,3.4,1,.75-.68,.45-2.24-.67-3.48-1.12-1.24-2.65-1.69-3.4-1Z"/>
          <path class="cls-67" d="M97.2,97.06c-.75,.68-.45,2.23,.67,3.47,1.12,1.23,2.64,1.68,3.39,1,.75-.68,.45-2.23-.67-3.47s-2.64-1.68-3.39-1Z"/>
          <path class="cls-96" d="M97.2,97.07c-.75,.68-.45,2.23,.67,3.46,1.12,1.23,2.63,1.68,3.38,1,.75-.68,.45-2.23-.67-3.46-1.12-1.23-2.63-1.68-3.38-1Z"/>
          <path class="cls-134" d="M97.21,97.08c-.75,.68-.45,2.22,.67,3.44,1.11,1.23,2.62,1.67,3.37,.99,.75-.68,.45-2.22-.67-3.44-1.11-1.23-2.62-1.67-3.37-.99Z"/>
          <path class="cls-56" d="M97.22,97.08c-.74,.67-.44,2.21,.66,3.43,1.11,1.22,2.61,1.66,3.35,.99,.74-.67,.44-2.21-.67-3.43-1.11-1.22-2.61-1.67-3.35-.99Z"/>
          <path class="cls-132" d="M97.22,97.09c-.74,.67-.44,2.2,.66,3.42,1.11,1.22,2.6,1.66,3.34,.99,.74-.67,.44-2.2-.66-3.42-1.11-1.22-2.6-1.66-3.34-.99Z"/>
          <path class="cls-118" d="M97.23,97.1c-.74,.67-.44,2.2,.66,3.41,1.1,1.21,2.59,1.65,3.33,.98,.74-.67,.44-2.2-.66-3.41-1.1-1.21-2.59-1.65-3.33-.98Z"/>
          <path class="cls-155" d="M97.24,97.11c-.74,.67-.44,2.19,.66,3.4,1.1,1.21,2.58,1.65,3.32,.98,.74-.67,.44-2.19-.66-3.4-1.1-1.21-2.58-1.65-3.32-.98Z"/>
          <path class="cls-122" d="M97.24,97.11c-.73,.67-.44,2.18,.66,3.39,1.09,1.2,2.58,1.64,3.31,.98,.73-.67,.44-2.18-.66-3.39-1.09-1.21-2.58-1.64-3.31-.98Z"/>
          <path class="cls-128" d="M97.25,97.12c-.73,.66-.44,2.17,.65,3.38,1.09,1.2,2.57,1.64,3.3,.97,.73-.66,.44-2.17-.65-3.38-1.09-1.2-2.57-1.64-3.3-.97Z"/>
          <path class="cls-158" d="M97.26,97.13c-.73,.66-.43,2.17,.65,3.36,1.09,1.2,2.56,1.63,3.29,.97,.73-.66,.44-2.17-.65-3.36-1.09-1.2-2.56-1.63-3.29-.97Z"/>
          <path class="cls-168" d="M97.26,97.14c-.72,.66-.43,2.16,.65,3.35,1.08,1.19,2.55,1.62,3.27,.97,.73-.66,.43-2.16-.65-3.35-1.08-1.19-2.55-1.62-3.27-.97Z"/>
          <path class="cls-73" d="M97.27,97.14c-.72,.66-.43,2.15,.65,3.34,1.08,1.19,2.54,1.62,3.26,.96,.72-.66,.43-2.15-.65-3.34-1.08-1.19-2.54-1.62-3.26-.96Z"/>
          <path class="cls-58" d="M97.28,97.15c-.72,.65-.43,2.14,.65,3.33,1.08,1.18,2.53,1.61,3.25,.96,.72-.65,.43-2.14-.65-3.33-1.08-1.18-2.53-1.61-3.25-.96Z"/>
          <path class="cls-184" d="M97.28,97.16c-.72,.65-.43,2.14,.64,3.32,1.07,1.18,2.52,1.61,3.24,.96,.72-.65,.43-2.14-.64-3.32s-2.52-1.61-3.24-.96Z"/>
          <path class="cls-185" d="M97.29,97.17c-.71,.65-.43,2.13,.64,3.31,1.07,1.18,2.51,1.6,3.23,.95,.71-.65,.43-2.13-.64-3.31-1.07-1.18-2.51-1.6-3.23-.95Z"/>
          <path class="cls-186" d="M97.3,97.17c-.71,.65-.43,2.12,.64,3.29,1.06,1.17,2.5,1.6,3.22,.95,.71-.65,.43-2.12-.64-3.29-1.06-1.17-2.5-1.6-3.22-.95Z"/>
          <path class="cls-74" d="M97.3,97.18c-.71,.64-.42,2.11,.64,3.28,1.06,1.17,2.5,1.59,3.21,.95,.71-.64,.42-2.11-.64-3.28-1.06-1.17-2.5-1.59-3.21-.95Z"/>
          <path class="cls-90" d="M97.31,97.19c-.71,.64-.42,2.11,.63,3.27,1.06,1.16,2.49,1.59,3.2,.94,.71-.64,.42-2.11-.63-3.27-1.06-1.16-2.49-1.59-3.2-.94Z"/>
          <path class="cls-15" d="M97.32,97.2c-.7,.64-.42,2.1,.63,3.26,1.05,1.16,2.48,1.58,3.18,.94,.7-.64,.42-2.1-.63-3.26s-2.48-1.58-3.18-.94Z"/>
          <path class="cls-156" d="M97.32,97.2c-.7,.64-.42,2.09,.63,3.25,1.05,1.16,2.47,1.57,3.17,.94,.7-.64,.42-2.09-.63-3.25-1.05-1.16-2.47-1.57-3.17-.94Z"/>
          <path class="cls-123" d="M97.33,97.21c-.7,.64-.42,2.09,.63,3.24,1.05,1.15,2.46,1.57,3.16,.93,.7-.64,.42-2.08-.63-3.23-1.05-1.15-2.46-1.57-3.16-.93Z"/>
          <path class="cls-175" d="M97.34,97.22c-.7,.63-.42,2.08,.62,3.22,1.04,1.15,2.45,1.56,3.15,.93,.7-.63,.42-2.08-.62-3.22s-2.45-1.56-3.15-.93Z"/>
          <path class="cls-188" d="M97.34,97.23c-.69,.63-.42,2.07,.62,3.21,1.04,1.14,2.44,1.56,3.14,.93,.69-.63,.42-2.07-.62-3.21-1.04-1.14-2.44-1.56-3.14-.93Z"/>
          <path class="cls-1" d="M97.35,97.24c-.69,.63-.41,2.06,.62,3.2,1.04,1.14,2.43,1.55,3.13,.92,.69-.63,.41-2.06-.62-3.2-1.04-1.14-2.43-1.55-3.13-.92Z"/>
          <path class="cls-189" d="M97.36,97.24c-.69,.63-.41,2.05,.62,3.19,1.03,1.13,2.43,1.55,3.12,.92,.69-.63,.41-2.05-.62-3.19-1.03-1.13-2.43-1.55-3.12-.92Z"/>
          <path class="cls-71" d="M97.37,97.25c-.69,.62-.41,2.05,.62,3.18,1.03,1.13,2.42,1.54,3.1,.92,.69-.62,.41-2.05-.62-3.18-1.03-1.13-2.42-1.54-3.1-.92Z"/>
          <path class="cls-103" d="M97.37,97.26c-.68,.62-.41,2.04,.61,3.16,1.02,1.13,2.41,1.53,3.09,.91,.68-.62,.41-2.04-.61-3.17-1.02-1.13-2.41-1.53-3.09-.91Z"/>
          <path class="cls-76" d="M97.38,97.26c-.68,.62-.41,2.03,.61,3.15,1.02,1.12,2.4,1.53,3.08,.91,.68-.62,.41-2.03-.61-3.15-1.02-1.12-2.4-1.53-3.08-.91Z"/>
          <path class="cls-136" d="M97.39,97.27c-.68,.62-.41,2.02,.61,3.14,1.02,1.12,2.39,1.52,3.07,.91s.41-2.02-.61-3.14c-1.02-1.12-2.39-1.52-3.07-.91Z"/>
          <path class="cls-154" d="M97.39,97.28c-.68,.61-.41,2.02,.61,3.13,1.01,1.11,2.38,1.52,3.06,.9,.68-.62,.41-2.02-.61-3.13-1.01-1.11-2.38-1.52-3.06-.9Z"/>
          <path class="cls-112" d="M97.4,97.29c-.67,.61-.4,2.01,.6,3.12,1.01,1.11,2.37,1.51,3.05,.9,.67-.61,.4-2.01-.6-3.12-1.01-1.11-2.37-1.51-3.05-.9Z"/>
          <path class="cls-191" d="M97.41,97.29c-.67,.61-.4,2,.6,3.11,1,1.11,2.36,1.51,3.04,.9,.67-.61,.4-2-.6-3.11-1-1.1-2.36-1.51-3.04-.9Z"/>
          <path class="cls-82" d="M97.41,97.3c-.67,.61-.4,1.99,.6,3.09,1,1.1,2.36,1.5,3.02,.89,.67-.61,.4-1.99-.6-3.09-1-1.1-2.36-1.5-3.02-.89Z"/>
          <path class="cls-5" d="M97.42,97.31c-.67,.61-.4,1.99,.6,3.08,1,1.1,2.35,1.5,3.01,.89,.67-.61,.4-1.99-.6-3.08-1-1.1-2.35-1.5-3.01-.89Z"/>
          <path class="cls-42" d="M97.43,97.32c-.66,.6-.4,1.98,.6,3.07,.99,1.09,2.34,1.49,3,.89,.66-.6,.4-1.98-.6-3.07s-2.34-1.49-3-.89Z"/>
          <path class="cls-207" d="M97.43,97.32c-.66,.6-.4,1.97,.59,3.06,.99,1.09,2.33,1.48,2.99,.88,.66-.6,.4-1.97-.59-3.06-.99-1.09-2.33-1.48-2.99-.88Z"/>
          <path class="cls-107" d="M97.44,97.33c-.66,.6-.39,1.96,.59,3.05,.99,1.08,2.32,1.48,2.98,.88,.66-.6,.39-1.96-.59-3.05-.99-1.08-2.32-1.48-2.98-.88Z"/>
          <path class="cls-78" d="M97.45,97.34c-.66,.6-.39,1.96,.59,3.04,.98,1.08,2.31,1.47,2.97,.88,.66-.6,.39-1.96-.59-3.04-.98-1.08-2.31-1.47-2.97-.88Z"/>
          <path class="cls-75" d="M97.45,97.35c-.65,.59-.39,1.95,.59,3.03,.98,1.08,2.3,1.47,2.96,.87,.65-.59,.39-1.95-.59-3.03-.98-1.08-2.3-1.47-2.96-.87Z"/>
          <path class="cls-20" d="M97.46,97.35c-.65,.59-.39,1.94,.58,3.01,.97,1.07,2.29,1.46,2.94,.87,.65-.59,.39-1.94-.58-3.01-.97-1.07-2.29-1.46-2.94-.87Z"/>
          <path class="cls-201" d="M97.47,97.36c-.65,.59-.39,1.93,.58,3,.97,1.07,2.28,1.46,2.93,.87,.65-.59,.39-1.93-.58-3-.97-1.07-2.28-1.46-2.93-.87Z"/>
          <path class="cls-201" d="M97.47,97.37c-.65,.59-.39,1.93,.58,2.99,.97,1.06,2.28,1.45,2.92,.86,.65-.59,.39-1.93-.58-2.99-.97-1.06-2.28-1.45-2.92-.86Z"/>
          <path class="cls-126" d="M97.48,97.38c-.64,.59-.39,1.92,.58,2.98,.96,1.06,2.27,1.44,2.91,.86,.64-.59,.39-1.92-.58-2.98-.96-1.06-2.27-1.44-2.91-.86Z"/>
          <path class="cls-35" d="M97.49,97.38c-.64,.58-.38,1.91,.58,2.97,.96,1.06,2.26,1.44,2.9,.86,.64-.58,.38-1.91-.58-2.97-.96-1.06-2.26-1.44-2.9-.86Z"/>
          <path class="cls-204" d="M97.5,97.39c-.64,.58-.38,1.9,.57,2.95,.96,1.05,2.25,1.43,2.89,.85,.64-.58,.38-1.9-.57-2.96-.96-1.05-2.25-1.43-2.89-.85Z"/>
          <path class="cls-89" d="M97.5,97.4c-.64,.58-.38,1.9,.57,2.94,.95,1.05,2.24,1.43,2.88,.85,.64-.58,.38-1.9-.57-2.94-.95-1.05-2.24-1.43-2.88-.85Z"/>
          <path class="cls-91" d="M97.51,97.41c-.63,.58-.38,1.89,.57,2.93,.95,1.04,2.23,1.42,2.86,.85,.63-.58,.38-1.89-.57-2.93-.95-1.04-2.23-1.42-2.86-.85Z"/>
          <path class="cls-94" d="M97.52,97.41c-.63,.57-.38,1.88,.57,2.92,.94,1.04,2.22,1.42,2.85,.84,.63-.57,.38-1.88-.57-2.92-.94-1.04-2.22-1.42-2.85-.84Z"/>
          <path class="cls-94" d="M97.52,97.42c-.63,.57-.38,1.88,.56,2.91,.94,1.04,2.21,1.41,2.84,.84,.63-.57,.38-1.88-.56-2.91-.94-1.03-2.21-1.41-2.84-.84Z"/>
          <path class="cls-80" d="M97.53,97.43c-.63,.57-.38,1.87,.56,2.9,.94,1.03,2.2,1.41,2.83,.84,.63-.57,.37-1.87-.56-2.9-.94-1.03-2.2-1.41-2.83-.84Z"/>
          <path class="cls-141" d="M97.54,97.44c-.62,.57-.37,1.86,.56,2.89,.93,1.03,2.2,1.4,2.82,.83,.62-.57,.37-1.86-.56-2.89-.93-1.03-2.2-1.4-2.82-.83Z"/>
          <path class="cls-130" d="M97.54,97.44c-.62,.57-.37,1.85,.56,2.88,.93,1.02,2.19,1.39,2.81,.83,.62-.56,.37-1.85-.56-2.87-.93-1.02-2.19-1.39-2.81-.83Z"/>
          <path class="cls-21" d="M97.55,97.45c-.62,.56-.37,1.84,.55,2.86,.92,1.02,2.18,1.39,2.8,.83,.62-.56,.37-1.84-.55-2.86-.92-1.02-2.18-1.39-2.8-.83Z"/>
          <path class="cls-21" d="M97.56,97.46c-.62,.56-.37,1.84,.55,2.85,.92,1.01,2.17,1.38,2.79,.82,.62-.56,.37-1.84-.55-2.85-.92-1.01-2.17-1.38-2.79-.82Z"/>
          <path class="cls-187" d="M97.56,97.47c-.61,.56-.37,1.83,.55,2.84,.92,1.01,2.16,1.38,2.77,.82,.61-.56,.37-1.83-.55-2.84-.92-1.01-2.16-1.38-2.77-.82Z"/>
          <path class="cls-192" d="M97.57,97.47c-.61,.56-.37,1.82,.55,2.83,.91,1.01,2.15,1.37,2.76,.82,.61-.56,.37-1.82-.55-2.83-.91-1.01-2.15-1.37-2.76-.82Z"/>
          <path class="cls-192" d="M97.58,97.48c-.61,.55-.36,1.81,.55,2.82,.91,1,2.14,1.37,2.75,.81,.61-.55,.37-1.81-.54-2.82-.91-1-2.14-1.37-2.75-.81Z"/>
          <path class="cls-129" d="M97.58,97.49c-.61,.55-.36,1.81,.54,2.8,.91,1,2.13,1.36,2.74,.81,.61-.55,.36-1.81-.54-2.8-.91-1-2.13-1.36-2.74-.81Z"/>
          <path class="cls-129" d="M97.59,97.5c-.6,.55-.36,1.8,.54,2.79,.9,.99,2.12,1.35,2.73,.81,.6-.55,.36-1.8-.54-2.79-.9-.99-2.12-1.35-2.73-.81Z"/>
          <path class="cls-83" d="M97.6,97.5c-.6,.55-.36,1.79,.54,2.78,.9,.99,2.12,1.35,2.72,.8,.6-.55,.36-1.79-.54-2.78-.9-.99-2.12-1.35-2.72-.8Z"/>
          <path class="cls-177" d="M97.6,97.51c-.6,.54-.36,1.78,.54,2.77,.9,.99,2.11,1.34,2.71,.8,.6-.54,.36-1.78-.54-2.77-.9-.99-2.11-1.34-2.71-.8Z"/>
          <path class="cls-177" d="M97.61,97.52c-.6,.54-.36,1.78,.54,2.76,.89,.98,2.1,1.34,2.69,.8,.6-.54,.36-1.78-.54-2.76-.89-.98-2.1-1.34-2.69-.8Z"/>
          <path class="cls-37" d="M97.62,97.53c-.59,.54-.36,1.77,.53,2.75,.89,.98,2.09,1.33,2.68,.79,.59-.54,.36-1.77-.53-2.75-.89-.98-2.09-1.33-2.68-.79Z"/>
          <path class="cls-169" d="M97.62,97.53c-.59,.54-.35,1.76,.53,2.73,.88,.97,2.08,1.33,2.67,.79,.59-.54,.35-1.76-.53-2.73-.88-.97-2.08-1.33-2.67-.79Z"/>
          <path class="cls-19" d="M97.63,97.54c-.59,.54-.35,1.75,.53,2.72,.88,.97,2.07,1.32,2.66,.79,.59-.54,.35-1.75-.53-2.72-.88-.97-2.07-1.32-2.66-.79Z"/>
          <path class="cls-19" d="M97.64,97.55c-.59,.53-.35,1.75,.53,2.71,.88,.96,2.06,1.32,2.65,.78,.59-.53,.35-1.75-.53-2.71-.88-.96-2.06-1.32-2.65-.78Z"/>
          <path class="cls-19" d="M97.64,97.56c-.58,.53-.35,1.74,.52,2.7,.87,.96,2.05,1.31,2.64,.78,.58-.53,.35-1.74-.52-2.7-.87-.96-2.05-1.31-2.64-.78Z"/>
          <path class="cls-6" d="M97.65,97.56c-.58,.53-.35,1.73,.52,2.69,.87,.96,2.04,1.3,2.63,.78,.58-.53,.35-1.73-.52-2.69-.87-.96-2.04-1.3-2.63-.78Z"/>
          <path class="cls-6" d="M97.66,97.57c-.58,.53-.35,1.72,.52,2.68,.87,.95,2.04,1.3,2.62,.77,.58-.53,.35-1.72-.52-2.68-.87-.95-2.04-1.3-2.62-.77Z"/>
          <path class="cls-6" d="M97.66,97.58c-.58,.52-.34,1.72,.52,2.66,.86,.95,2.03,1.29,2.6,.77,.58-.52,.34-1.72-.52-2.66-.86-.95-2.03-1.29-2.6-.77Z"/>
          <path class="cls-6" d="M97.67,97.59c-.57,.52-.34,1.71,.51,2.65,.86,.94,2.02,1.29,2.59,.77,.57-.52,.34-1.71-.51-2.65-.86-.94-2.02-1.29-2.59-.77Z"/>
          <path class="cls-146" d="M97.68,97.59c-.57,.52-.34,1.7,.51,2.64,.85,.94,2.01,1.28,2.58,.76,.57-.52,.34-1.7-.51-2.64-.85-.94-2.01-1.28-2.58-.76Z"/>
          <path class="cls-161" d="M97.69,97.6c-.57,.52-.34,1.69,.51,2.63,.85,.94,2,1.27,2.57,.76s.34-1.69-.51-2.63c-.85-.94-2-1.27-2.57-.76Z"/>
          <path class="cls-161" d="M97.69,97.61c-.57,.51-.34,1.69,.51,2.62,.85,.93,1.99,1.27,2.56,.76,.57-.51,.34-1.69-.51-2.62-.85-.93-1.99-1.27-2.56-.76Z"/>
          <path class="cls-161" d="M97.7,97.62c-.56,.51-.34,1.68,.5,2.61,.84,.93,1.98,1.26,2.55,.75,.56-.51,.34-1.68-.5-2.61-.84-.93-1.98-1.26-2.55-.75Z"/>
          <path class="cls-14" d="M97.71,97.62c-.56,.51-.34,1.67,.5,2.59,.84,.92,1.97,1.26,2.54,.75,.56-.51,.34-1.67-.5-2.59-.84-.92-1.97-1.26-2.54-.75Z"/>
          <path class="cls-14" d="M97.71,97.63c-.56,.51-.33,1.67,.5,2.58,.83,.92,1.96,1.25,2.52,.75,.56-.51,.33-1.66-.5-2.58-.84-.92-1.96-1.25-2.52-.75Z"/>
          <path class="cls-176" d="M97.72,97.64c-.56,.51-.33,1.66,.5,2.57,.83,.92,1.96,1.25,2.51,.74,.56-.51,.33-1.66-.5-2.57-.83-.91-1.96-1.25-2.51-.74Z"/>
          <path class="cls-176" d="M97.73,97.65c-.55,.5-.33,1.65,.5,2.56,.83,.91,1.95,1.24,2.5,.74,.55-.5,.33-1.65-.5-2.56-.83-.91-1.95-1.24-2.5-.74Z"/>
          <path class="cls-176" d="M97.73,97.65c-.55,.5-.33,1.64,.49,2.55,.82,.91,1.94,1.24,2.49,.74,.55-.5,.33-1.64-.49-2.55-.82-.91-1.94-1.24-2.49-.74Z"/>
          <path class="cls-40" d="M97.74,97.66c-.55,.5-.33,1.63,.49,2.54,.82,.9,1.93,1.23,2.48,.73,.55-.5,.33-1.63-.49-2.54-.82-.9-1.93-1.23-2.48-.73Z"/>
          <path class="cls-40" d="M97.75,97.67c-.55,.5-.33,1.63,.49,2.52,.82,.9,1.92,1.22,2.47,.73s.33-1.63-.49-2.53c-.82-.9-1.92-1.22-2.47-.73Z"/>
          <path class="cls-199" d="M97.75,97.68c-.54,.49-.33,1.62,.49,2.51,.81,.89,1.91,1.22,2.45,.72,.54-.49,.33-1.62-.49-2.51-.81-.89-1.91-1.22-2.45-.73Z"/>
          <path class="cls-199" d="M97.76,97.68c-.54,.49-.32,1.61,.48,2.5,.81,.89,1.9,1.21,2.44,.72,.54-.49,.32-1.61-.48-2.5-.81-.89-1.9-1.21-2.44-.72Z"/>
          <path class="cls-199" d="M97.77,97.69c-.54,.49-.32,1.6,.48,2.49,.8,.89,1.89,1.21,2.43,.72,.54-.49,.32-1.6-.48-2.49-.8-.89-1.89-1.21-2.43-.72Z"/>
          <path class="cls-199" d="M97.77,97.7c-.54,.49-.32,1.6,.48,2.48,.8,.88,1.88,1.2,2.42,.71,.54-.49,.32-1.6-.48-2.48-.8-.88-1.88-1.2-2.42-.71Z"/>
          <path class="cls-199" d="M97.78,97.71c-.53,.49-.32,1.59,.48,2.47,.8,.88,1.88,1.2,2.41,.71,.53-.49,.32-1.59-.48-2.47-.8-.88-1.88-1.2-2.41-.71Z"/>
          <path class="cls-199" d="M97.79,97.71c-.53,.48-.32,1.58,.48,2.46,.79,.87,1.87,1.19,2.4,.71,.53-.48,.32-1.58-.48-2.46-.79-.87-1.87-1.19-2.4-.71Z"/>
          <path class="cls-199" d="M97.79,97.72c-.53,.48-.32,1.57,.47,2.44,.79,.87,1.86,1.18,2.39,.7,.53-.48,.32-1.57-.47-2.44-.79-.87-1.86-1.18-2.39-.7Z"/>
          <path class="cls-199" d="M97.8,97.73c-.53,.48-.32,1.57,.47,2.43,.79,.87,1.85,1.18,2.38,.7,.53-.48,.32-1.57-.47-2.43-.79-.87-1.85-1.18-2.38-.7Z"/>
          <path class="cls-199" d="M97.81,97.74c-.52,.48-.31,1.56,.47,2.42,.78,.86,1.84,1.17,2.36,.7,.52-.48,.31-1.56-.47-2.42-.78-.86-1.84-1.17-2.36-.7Z"/>
          <path class="cls-199" d="M97.82,97.74c-.52,.47-.31,1.55,.47,2.41,.78,.86,1.83,1.17,2.35,.69,.52-.47,.31-1.55-.47-2.41-.78-.86-1.83-1.17-2.35-.69Z"/>
          <path class="cls-199" d="M97.82,97.75c-.52,.47-.31,1.55,.46,2.4,.78,.85,1.82,1.16,2.34,.69,.52-.47,.31-1.54-.46-2.4-.78-.85-1.82-1.16-2.34-.69Z"/>
        </g>
        <path class="cls-138" d="M76.15,82.83s-.1-1.06,1.34-1.06l-1.59-1.5-.38,1.77,.63,.79Z"/>
        <path class="cls-138" d="M101.2,98.38s-.1-1.06,1.34-1.06l-1.59-1.5-.38,1.77,.63,.79Z"/>
        <g class="cls-63">
          <rect class="cls-199" x="77.6" y="105.79" width="7.44" height="7.85"/>
          <path class="cls-199" d="M77.93,107.3c-.95,.86-.57,2.82,.85,4.38,1.42,1.56,3.33,2.12,4.28,1.26,.95-.86,.57-2.82-.85-4.38-1.42-1.56-3.33-2.12-4.28-1.26Z"/>
          <path class="cls-199" d="M77.93,107.3c-.95,.86-.57,2.82,.85,4.38,1.42,1.56,3.33,2.12,4.28,1.26,.95-.86,.57-2.82-.85-4.38-1.42-1.56-3.33-2.12-4.28-1.26Z"/>
          <path class="cls-176" d="M77.93,107.31c-.95,.86-.57,2.82,.85,4.37,1.41,1.56,3.33,2.12,4.27,1.26,.95-.86,.57-2.82-.85-4.37-1.41-1.56-3.33-2.12-4.27-1.26Z"/>
          <path class="cls-14" d="M77.94,107.31c-.94,.86-.57,2.81,.85,4.37,1.41,1.55,3.32,2.12,4.27,1.26,.94-.86,.57-2.81-.85-4.37-1.41-1.55-3.32-2.12-4.27-1.26Z"/>
          <path class="cls-161" d="M77.94,107.31c-.94,.86-.56,2.81,.84,4.36,1.41,1.55,3.32,2.11,4.26,1.26,.94-.86,.56-2.81-.84-4.36-1.41-1.55-3.32-2.12-4.26-1.26Z"/>
          <path class="cls-6" d="M77.94,107.32c-.94,.86-.56,2.81,.84,4.36,1.41,1.55,3.31,2.11,4.25,1.26,.94-.86,.56-2.81-.84-4.36-1.41-1.55-3.31-2.11-4.26-1.26Z"/>
          <path class="cls-6" d="M77.95,107.32c-.94,.85-.56,2.8,.84,4.35,1.41,1.55,3.31,2.11,4.25,1.25,.94-.85,.56-2.8-.84-4.35-1.41-1.55-3.31-2.11-4.25-1.25Z"/>
          <path class="cls-19" d="M77.95,107.32c-.94,.85-.56,2.8,.84,4.35,1.4,1.55,3.31,2.11,4.25,1.25,.94-.85,.56-2.8-.84-4.35-1.4-1.55-3.31-2.11-4.25-1.25Z"/>
          <path class="cls-169" d="M77.95,107.33c-.94,.85-.56,2.8,.84,4.34,1.4,1.54,3.3,2.1,4.24,1.25,.94-.85,.56-2.8-.84-4.34-1.4-1.54-3.3-2.1-4.24-1.25Z"/>
          <path class="cls-37" d="M77.96,107.33c-.94,.85-.56,2.79,.84,4.34,1.4,1.54,3.3,2.1,4.23,1.25,.94-.85,.56-2.79-.84-4.34-1.4-1.54-3.3-2.1-4.23-1.25Z"/>
          <path class="cls-60" d="M77.96,107.33c-.94,.85-.56,2.79,.84,4.33,1.4,1.54,3.29,2.1,4.23,1.25,.94-.85,.56-2.79-.84-4.33s-3.29-2.1-4.23-1.25Z"/>
          <path class="cls-129" d="M77.96,107.34c-.94,.85-.56,2.79,.84,4.32,1.4,1.54,3.29,2.1,4.22,1.25,.94-.85,.56-2.79-.84-4.32-1.4-1.54-3.29-2.1-4.22-1.25Z"/>
          <path class="cls-192" d="M77.97,107.34c-.93,.85-.56,2.78,.84,4.32,1.4,1.54,3.29,2.09,4.22,1.25,.93-.85,.56-2.78-.84-4.32s-3.29-2.09-4.22-1.25Z"/>
          <path class="cls-205" d="M77.97,107.34c-.93,.85-.56,2.78,.84,4.31,1.39,1.54,3.28,2.09,4.21,1.24,.93-.85,.56-2.78-.84-4.31-1.4-1.54-3.28-2.09-4.21-1.24Z"/>
          <path class="cls-64" d="M77.97,107.35c-.93,.85-.56,2.78,.84,4.31,1.39,1.53,3.28,2.09,4.21,1.24,.93-.85,.56-2.78-.84-4.31-1.39-1.53-3.28-2.09-4.21-1.24Z"/>
          <path class="cls-21" d="M77.98,107.35c-.93,.85-.56,2.77,.83,4.3,1.39,1.53,3.27,2.09,4.2,1.24,.93-.85,.56-2.77-.83-4.3-1.39-1.53-3.27-2.09-4.2-1.24Z"/>
          <path class="cls-130" d="M77.98,107.36c-.93,.84-.56,2.77,.83,4.3,1.39,1.53,3.27,2.08,4.2,1.24,.93-.84,.56-2.77-.83-4.3-1.39-1.53-3.27-2.08-4.2-1.24Z"/>
          <path class="cls-80" d="M77.98,107.36c-.93,.84-.56,2.77,.83,4.29,1.39,1.53,3.27,2.08,4.19,1.24,.93-.84,.56-2.77-.83-4.29-1.39-1.53-3.27-2.08-4.19-1.24Z"/>
          <path class="cls-94" d="M77.98,107.36c-.93,.84-.55,2.76,.83,4.29,1.39,1.52,3.26,2.08,4.19,1.24,.93-.84,.55-2.76-.83-4.29-1.39-1.53-3.26-2.08-4.19-1.24Z"/>
          <path class="cls-163" d="M77.99,107.36c-.93,.84-.55,2.76,.83,4.28,1.38,1.52,3.26,2.08,4.18,1.23,.93-.84,.55-2.76-.83-4.28-1.38-1.52-3.26-2.08-4.18-1.24Z"/>
          <path class="cls-91" d="M77.99,107.37c-.92,.84-.55,2.76,.83,4.28,1.38,1.52,3.25,2.07,4.18,1.23,.92-.84,.55-2.76-.83-4.28-1.38-1.52-3.25-2.07-4.18-1.23Z"/>
          <path class="cls-204" d="M77.99,107.37c-.92,.84-.55,2.75,.83,4.27,1.38,1.52,3.25,2.07,4.17,1.23,.92-.84,.55-2.75-.83-4.27-1.38-1.52-3.25-2.07-4.17-1.23Z"/>
          <path class="cls-35" d="M78,107.37c-.92,.84-.55,2.75,.83,4.27,1.38,1.52,3.25,2.07,4.17,1.23,.92-.84,.55-2.75-.83-4.27-1.38-1.52-3.25-2.07-4.17-1.23Z"/>
          <path class="cls-126" d="M78,107.38c-.92,.84-.55,2.75,.83,4.26,1.38,1.52,3.24,2.07,4.16,1.23,.92-.84,.55-2.75-.83-4.26-1.38-1.52-3.24-2.07-4.16-1.23Z"/>
          <path class="cls-201" d="M78,107.38c-.92,.84-.55,2.74,.83,4.26s3.24,2.06,4.16,1.23c.92-.84,.55-2.74-.83-4.26-1.38-1.51-3.24-2.06-4.16-1.23Z"/>
          <path class="cls-170" d="M78.01,107.39c-.92,.83-.55,2.74,.82,4.25,1.37,1.51,3.23,2.06,4.15,1.23,.92-.83,.55-2.74-.82-4.25-1.37-1.51-3.23-2.06-4.15-1.23Z"/>
          <path class="cls-75" d="M78.01,107.39c-.92,.83-.55,2.74,.82,4.25,1.37,1.51,3.23,2.06,4.15,1.22,.92-.83,.55-2.74-.82-4.25-1.37-1.51-3.23-2.06-4.15-1.22Z"/>
          <path class="cls-121" d="M78.01,107.39c-.92,.83-.55,2.73,.82,4.24,1.37,1.51,3.23,2.06,4.14,1.22,.92-.83,.55-2.73-.82-4.24-1.37-1.51-3.23-2.06-4.14-1.22Z"/>
          <path class="cls-78" d="M78.02,107.4c-.92,.83-.55,2.73,.82,4.23,1.37,1.51,3.22,2.05,4.14,1.22,.92-.83,.55-2.73-.82-4.23-1.37-1.51-3.22-2.05-4.14-1.22Z"/>
          <path class="cls-107" d="M78.02,107.4c-.91,.83-.55,2.72,.82,4.23,1.37,1.5,3.22,2.05,4.13,1.22,.91-.83,.55-2.72-.82-4.23-1.37-1.5-3.22-2.05-4.13-1.22Z"/>
          <path class="cls-202" d="M78.02,107.4c-.91,.83-.55,2.72,.82,4.22,1.37,1.5,3.21,2.05,4.13,1.22,.91-.83,.55-2.72-.82-4.22-1.37-1.5-3.21-2.05-4.13-1.22Z"/>
          <path class="cls-42" d="M78.02,107.41c-.91,.83-.55,2.72,.82,4.22,1.36,1.5,3.21,2.04,4.12,1.22,.91-.83,.55-2.72-.82-4.22-1.36-1.5-3.21-2.05-4.12-1.22Z"/>
          <path class="cls-5" d="M78.03,107.41c-.91,.83-.54,2.71,.82,4.21,1.36,1.5,3.21,2.04,4.12,1.21s.54-2.71-.82-4.21c-1.36-1.5-3.21-2.04-4.12-1.21Z"/>
          <path class="cls-2" d="M78.03,107.41c-.91,.83-.54,2.71,.82,4.21,1.36,1.5,3.2,2.04,4.11,1.21,.91-.83,.54-2.71-.82-4.21-1.36-1.5-3.2-2.04-4.11-1.21Z"/>
          <path class="cls-191" d="M78.03,107.42c-.91,.83-.54,2.71,.81,4.2s3.2,2.04,4.11,1.21c.91-.83,.54-2.71-.81-4.2s-3.2-2.04-4.11-1.21Z"/>
          <path class="cls-112" d="M78.04,107.42c-.91,.82-.54,2.7,.81,4.2,1.36,1.49,3.19,2.04,4.1,1.21,.91-.82,.54-2.7-.81-4.2-1.36-1.49-3.19-2.04-4.1-1.21Z"/>
          <path class="cls-101" d="M78.04,107.42c-.91,.82-.54,2.7,.81,4.19s3.19,2.03,4.1,1.21c.91-.82,.54-2.7-.81-4.19s-3.19-2.03-4.1-1.21Z"/>
          <path class="cls-136" d="M78.04,107.43c-.91,.82-.54,2.7,.81,4.19,1.35,1.49,3.19,2.03,4.09,1.21,.91-.82,.54-2.7-.81-4.19-1.35-1.49-3.19-2.03-4.09-1.21Z"/>
          <path class="cls-100" d="M78.05,107.43c-.9,.82-.54,2.69,.81,4.18,1.35,1.49,3.18,2.03,4.08,1.21,.9-.82,.54-2.69-.81-4.18-1.35-1.49-3.18-2.03-4.09-1.21Z"/>
          <path class="cls-103" d="M78.05,107.43c-.9,.82-.54,2.69,.81,4.18,1.35,1.49,3.18,2.03,4.08,1.2,.9-.82,.54-2.69-.81-4.18-1.35-1.49-3.18-2.03-4.08-1.2Z"/>
          <path class="cls-71" d="M78.05,107.44c-.9,.82-.54,2.69,.81,4.17,1.35,1.48,3.17,2.02,4.07,1.2,.9-.82,.54-2.69-.81-4.17-1.35-1.48-3.17-2.02-4.07-1.2Z"/>
          <path class="cls-189" d="M78.06,107.44c-.9,.82-.54,2.68,.81,4.17,1.35,1.48,3.17,2.02,4.07,1.2,.9-.82,.54-2.68-.81-4.16-1.35-1.48-3.17-2.02-4.07-1.2Z"/>
          <path class="cls-1" d="M78.06,107.44c-.9,.82-.54,2.68,.81,4.16,1.34,1.48,3.16,2.02,4.06,1.2,.9-.82,.54-2.68-.81-4.16-1.34-1.48-3.16-2.02-4.06-1.2Z"/>
          <path class="cls-85" d="M78.06,107.45c-.9,.82-.54,2.68,.81,4.15,1.34,1.48,3.16,2.02,4.06,1.2,.9-.82,.54-2.68-.81-4.16-1.34-1.48-3.16-2.01-4.06-1.2Z"/>
          <path class="cls-160" d="M78.06,107.45c-.9,.82-.54,2.67,.8,4.15,1.34,1.48,3.16,2.01,4.05,1.2,.9-.82,.54-2.67-.8-4.15-1.34-1.48-3.16-2.01-4.05-1.2Z"/>
          <path class="cls-175" d="M78.07,107.45c-.9,.81-.54,2.67,.8,4.14,1.34,1.47,3.15,2.01,4.05,1.2,.9-.81,.54-2.67-.8-4.14-1.34-1.47-3.15-2.01-4.05-1.2Z"/>
          <path class="cls-123" d="M78.07,107.46c-.9,.81-.54,2.67,.8,4.14,1.34,1.47,3.15,2.01,4.04,1.19,.9-.81,.54-2.67-.8-4.14-1.34-1.47-3.15-2.01-4.04-1.19Z"/>
          <path class="cls-16" d="M78.07,107.46c-.89,.81-.54,2.66,.8,4.13,1.34,1.47,3.14,2,4.04,1.19,.89-.81,.54-2.66-.8-4.13-1.34-1.47-3.15-2-4.04-1.19Z"/>
          <path class="cls-15" d="M78.08,107.46c-.89,.81-.53,2.66,.8,4.13,1.33,1.47,3.14,2,4.03,1.19s.53-2.66-.8-4.13c-1.33-1.47-3.14-2-4.03-1.19Z"/>
          <path class="cls-38" d="M78.08,107.47c-.89,.81-.53,2.66,.8,4.12s3.14,2,4.03,1.19c.89-.81,.53-2.66-.8-4.12-1.33-1.47-3.14-2-4.03-1.19Z"/>
          <path class="cls-90" d="M78.08,107.47c-.89,.81-.53,2.65,.8,4.12,1.33,1.46,3.13,2,4.02,1.19,.89-.81,.53-2.65-.8-4.12-1.33-1.46-3.13-2-4.02-1.19Z"/>
          <path class="cls-74" d="M78.09,107.47c-.89,.81-.53,2.65,.8,4.11,1.33,1.46,3.13,1.99,4.02,1.19,.89-.81,.53-2.65-.8-4.11-1.33-1.46-3.13-1.99-4.02-1.19Z"/>
          <path class="cls-186" d="M78.09,107.48c-.89,.81-.53,2.65,.8,4.11,1.33,1.46,3.12,1.99,4.01,1.18,.89-.81,.53-2.65-.8-4.11s-3.12-1.99-4.01-1.18Z"/>
          <path class="cls-193" d="M78.09,107.48c-.89,.81-.53,2.64,.79,4.1,1.33,1.46,3.12,1.99,4.01,1.18,.89-.81,.53-2.64-.79-4.1-1.33-1.46-3.12-1.99-4.01-1.18Z"/>
          <path class="cls-185" d="M78.1,107.48c-.89,.8-.53,2.64,.79,4.1,1.33,1.46,3.12,1.99,4,1.18,.89-.8,.53-2.64-.79-4.1-1.33-1.46-3.12-1.99-4-1.18Z"/>
          <path class="cls-111" d="M78.1,107.49c-.88,.8-.53,2.64,.79,4.09,1.32,1.46,3.11,1.98,4,1.18,.88-.8,.53-2.64-.79-4.09-1.32-1.46-3.11-1.98-4-1.18Z"/>
          <path class="cls-58" d="M78.1,107.49c-.88,.8-.53,2.63,.79,4.09,1.32,1.45,3.11,1.98,3.99,1.18,.88-.8,.53-2.63-.79-4.09-1.32-1.45-3.11-1.98-3.99-1.18Z"/>
          <path class="cls-69" d="M78.11,107.49c-.88,.8-.53,2.63,.79,4.08,1.32,1.45,3.1,1.98,3.99,1.18,.88-.8,.53-2.63-.79-4.08-1.32-1.45-3.1-1.98-3.99-1.18Z"/>
          <path class="cls-73" d="M78.11,107.5c-.88,.8-.53,2.63,.79,4.08,1.32,1.45,3.1,1.98,3.98,1.17,.88-.8,.53-2.63-.79-4.08-1.32-1.45-3.1-1.98-3.98-1.18Z"/>
          <path class="cls-168" d="M78.11,107.5c-.88,.8-.53,2.62,.79,4.07,1.32,1.45,3.1,1.97,3.98,1.17s.53-2.62-.79-4.07c-1.32-1.45-3.1-1.97-3.98-1.17Z"/>
          <path class="cls-87" d="M78.11,107.5c-.88,.8-.53,2.62,.79,4.07,1.31,1.45,3.09,1.97,3.97,1.17,.88-.8,.53-2.62-.79-4.07-1.31-1.45-3.09-1.97-3.97-1.17Z"/>
          <path class="cls-4" d="M78.12,107.51c-.88,.8-.53,2.62,.79,4.06,1.31,1.44,3.09,1.97,3.97,1.17,.88-.8,.53-2.62-.79-4.06s-3.09-1.97-3.97-1.17Z"/>
          <path class="cls-128" d="M78.12,107.51c-.88,.8-.52,2.61,.79,4.05,1.31,1.44,3.08,1.97,3.96,1.17,.88-.8,.52-2.61-.79-4.05-1.31-1.44-3.08-1.97-3.96-1.17Z"/>
          <path class="cls-62" d="M78.12,107.51c-.88,.8-.52,2.61,.78,4.05,1.31,1.44,3.08,1.96,3.96,1.17,.88-.8,.52-2.61-.78-4.05-1.31-1.44-3.08-1.96-3.96-1.17Z"/>
          <path class="cls-114" d="M78.13,107.52c-.87,.8-.52,2.61,.78,4.04,1.31,1.44,3.08,1.96,3.95,1.17,.88-.79,.52-2.61-.78-4.04-1.31-1.44-3.08-1.96-3.95-1.17Z"/>
          <path class="cls-41" d="M78.13,107.52c-.87,.79-.52,2.6,.78,4.04,1.31,1.44,3.07,1.96,3.95,1.17,.87-.79,.52-2.6-.78-4.04-1.31-1.44-3.07-1.96-3.95-1.16Z"/>
          <path class="cls-72" d="M78.13,107.53c-.87,.79-.52,2.6,.78,4.03,1.3,1.44,3.07,1.96,3.94,1.16,.87-.79,.52-2.6-.78-4.03-1.3-1.44-3.07-1.96-3.94-1.16Z"/>
          <path class="cls-52" d="M78.14,107.53c-.87,.79-.52,2.6,.78,4.03,1.3,1.43,3.06,1.95,3.94,1.16,.87-.79,.52-2.6-.78-4.03-1.3-1.43-3.06-1.95-3.94-1.16Z"/>
          <path class="cls-147" d="M78.14,107.53c-.87,.79-.52,2.59,.78,4.02,1.3,1.43,3.06,1.95,3.93,1.16,.87-.79,.52-2.59-.78-4.02-1.3-1.43-3.06-1.95-3.93-1.16Z"/>
          <path class="cls-56" d="M78.14,107.53c-.87,.79-.52,2.59,.78,4.02,1.3,1.43,3.06,1.95,3.92,1.16,.87-.79,.52-2.59-.78-4.02-1.3-1.43-3.06-1.95-3.92-1.16Z"/>
          <path class="cls-86" d="M78.15,107.54c-.87,.79-.52,2.58,.78,4.01,1.3,1.43,3.05,1.95,3.92,1.16,.87-.79,.52-2.59-.78-4.01-1.3-1.43-3.05-1.95-3.92-1.16Z"/>
          <path class="cls-134" d="M78.15,107.54c-.87,.79-.52,2.58,.78,4.01,1.3,1.42,3.05,1.94,3.91,1.16s.52-2.58-.78-4.01c-1.3-1.43-3.05-1.94-3.91-1.16Z"/>
          <path class="cls-96" d="M78.15,107.55c-.87,.79-.52,2.58,.78,4,1.29,1.42,3.04,1.94,3.91,1.15,.87-.79,.52-2.58-.78-4-1.29-1.42-3.04-1.94-3.91-1.15Z"/>
          <path class="cls-150" d="M78.15,107.55c-.86,.79-.52,2.58,.78,4,1.29,1.42,3.04,1.94,3.9,1.15,.86-.79,.52-2.58-.78-4-1.29-1.42-3.04-1.94-3.9-1.15Z"/>
          <path class="cls-81" d="M78.16,107.55c-.86,.78-.52,2.57,.77,3.99,1.29,1.42,3.04,1.94,3.9,1.15,.86-.78,.52-2.57-.77-3.99-1.29-1.42-3.04-1.93-3.9-1.15Z"/>
          <path class="cls-97" d="M78.16,107.56c-.86,.78-.52,2.57,.77,3.99,1.29,1.42,3.03,1.93,3.89,1.15,.86-.78,.52-2.57-.77-3.99-1.29-1.42-3.03-1.93-3.89-1.15Z"/>
          <path class="cls-194" d="M78.16,107.56c-.86,.78-.52,2.56,.77,3.98s3.03,1.93,3.89,1.15c.86-.78,.52-2.56-.77-3.98s-3.03-1.93-3.89-1.15Z"/>
          <path class="cls-55" d="M78.17,107.56c-.86,.78-.51,2.56,.77,3.98,1.29,1.41,3.02,1.93,3.88,1.15,.86-.78,.51-2.56-.77-3.98-1.29-1.41-3.02-1.93-3.88-1.15Z"/>
          <path class="cls-105" d="M78.17,107.57c-.86,.78-.51,2.56,.77,3.97,1.28,1.41,3.02,1.92,3.88,1.14,.86-.78,.51-2.56-.77-3.97-1.28-1.41-3.02-1.92-3.88-1.14Z"/>
          <path class="cls-9" d="M78.17,107.57c-.86,.78-.51,2.55,.77,3.96,1.28,1.41,3.02,1.92,3.87,1.14s.51-2.55-.77-3.96c-1.28-1.41-3.02-1.92-3.87-1.14Z"/>
          <path class="cls-3" d="M78.18,107.57c-.86,.78-.51,2.55,.77,3.96,1.28,1.41,3.01,1.92,3.87,1.14,.86-.78,.51-2.55-.77-3.96-1.28-1.41-3.01-1.92-3.87-1.14Z"/>
          <path class="cls-22" d="M78.18,107.58c-.86,.78-.51,2.55,.77,3.95,1.28,1.41,3.01,1.92,3.86,1.14,.86-.78,.51-2.55-.77-3.95-1.28-1.41-3.01-1.92-3.86-1.14Z"/>
          <path class="cls-196" d="M78.18,107.58c-.85,.78-.51,2.54,.77,3.95,1.28,1.41,3,1.92,3.86,1.14,.85-.78,.51-2.55-.77-3.95-1.28-1.41-3-1.92-3.86-1.14Z"/>
          <path class="cls-110" d="M78.19,107.58c-.85,.78-.51,2.54,.76,3.94,1.27,1.4,3,1.91,3.85,1.14,.85-.78,.51-2.54-.76-3.94-1.27-1.4-3-1.91-3.85-1.14Z"/>
          <path class="cls-116" d="M78.19,107.59c-.85,.77-.51,2.54,.76,3.94,1.27,1.4,3,1.91,3.85,1.14,.85-.77,.51-2.54-.76-3.94-1.27-1.4-3-1.91-3.85-1.14Z"/>
          <path class="cls-124" d="M78.19,107.59c-.85,.77-.51,2.53,.76,3.93,1.27,1.4,2.99,1.91,3.84,1.13,.85-.77,.51-2.53-.76-3.93-1.27-1.4-2.99-1.91-3.84-1.13Z"/>
          <path class="cls-18" d="M78.2,107.59c-.85,.77-.51,2.53,.76,3.93,1.27,1.4,2.99,1.9,3.84,1.13,.85-.77,.51-2.53-.76-3.93-1.27-1.4-2.99-1.9-3.84-1.13Z"/>
          <path class="cls-57" d="M78.2,107.6c-.85,.77-.51,2.53,.76,3.92,1.27,1.4,2.98,1.9,3.83,1.13,.85-.77,.51-2.53-.76-3.92-1.27-1.4-2.98-1.9-3.83-1.13Z"/>
          <path class="cls-148" d="M78.2,107.6c-.85,.77-.51,2.52,.76,3.92,1.27,1.39,2.98,1.9,3.83,1.13,.85-.77,.51-2.52-.76-3.92-1.27-1.39-2.98-1.9-3.83-1.13Z"/>
          <path class="cls-113" d="M78.2,107.6c-.85,.77-.51,2.52,.76,3.91,1.26,1.39,2.98,1.9,3.82,1.13,.85-.77,.51-2.52-.76-3.91-1.26-1.39-2.98-1.9-3.82-1.13Z"/>
          <path class="cls-125" d="M78.21,107.61c-.84,.77-.51,2.52,.76,3.91,1.26,1.39,2.97,1.89,3.82,1.13s.51-2.52-.76-3.91c-1.26-1.39-2.97-1.89-3.82-1.13Z"/>
          <path class="cls-209" d="M78.21,107.61c-.84,.77-.5,2.51,.76,3.9,1.26,1.39,2.97,1.89,3.81,1.12,.84-.77,.5-2.51-.76-3.9-1.26-1.39-2.97-1.89-3.81-1.12Z"/>
          <path class="cls-142" d="M78.21,107.61c-.84,.77-.5,2.51,.76,3.9,1.26,1.39,2.96,1.89,3.81,1.12,.84-.77,.5-2.51-.76-3.9-1.26-1.39-2.96-1.89-3.81-1.12Z"/>
          <path class="cls-183" d="M78.22,107.62c-.84,.76-.5,2.51,.75,3.89,1.26,1.38,2.96,1.89,3.8,1.12,.84-.76,.5-2.51-.75-3.89-1.26-1.38-2.96-1.89-3.8-1.12Z"/>
          <path class="cls-127" d="M78.22,107.62c-.84,.76-.5,2.5,.75,3.89,1.26,1.38,2.96,1.88,3.8,1.12,.84-.76,.5-2.5-.75-3.89-1.26-1.38-2.96-1.88-3.8-1.12Z"/>
          <path class="cls-10" d="M78.22,107.62c-.84,.76-.5,2.5,.75,3.88,1.25,1.38,2.95,1.88,3.79,1.12,.84-.76,.5-2.5-.75-3.88-1.25-1.38-2.95-1.88-3.79-1.12Z"/>
          <path class="cls-149" d="M78.23,107.63c-.84,.76-.5,2.5,.75,3.88,1.25,1.38,2.95,1.88,3.79,1.12,.84-.76,.5-2.5-.75-3.88-1.25-1.38-2.95-1.88-3.79-1.12Z"/>
          <path class="cls-95" d="M78.23,107.63c-.84,.76-.5,2.49,.75,3.87,1.25,1.38,2.94,1.88,3.78,1.12,.84-.76,.5-2.49-.75-3.87-1.25-1.38-2.94-1.88-3.78-1.12Z"/>
          <path class="cls-102" d="M78.23,107.63c-.84,.76-.5,2.49,.75,3.87,1.25,1.37,2.94,1.87,3.78,1.11,.83-.76,.5-2.49-.75-3.86-1.25-1.38-2.94-1.87-3.77-1.12Z"/>
          <path class="cls-106" d="M78.24,107.64c-.84,.76-.5,2.49,.75,3.86,1.25,1.37,2.94,1.87,3.77,1.11,.83-.76,.5-2.49-.75-3.86-1.25-1.37-2.94-1.87-3.77-1.11Z"/>
          <path class="cls-109" d="M78.24,107.64c-.83,.76-.5,2.48,.75,3.85,1.25,1.37,2.93,1.87,3.76,1.11,.83-.76,.5-2.48-.75-3.85s-2.93-1.87-3.76-1.11Z"/>
          <path class="cls-119" d="M78.24,107.64c-.83,.76-.5,2.48,.75,3.85,1.24,1.37,2.93,1.87,3.76,1.11,.83-.76,.5-2.48-.75-3.85-1.24-1.37-2.93-1.87-3.76-1.11Z"/>
          <path class="cls-66" d="M78.24,107.65c-.83,.76-.5,2.48,.75,3.84,1.24,1.37,2.92,1.86,3.75,1.11,.83-.76,.5-2.48-.75-3.84-1.24-1.37-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-172" d="M78.25,107.65c-.83,.75-.5,2.47,.74,3.84,1.24,1.37,2.92,1.86,3.75,1.11,.83-.75,.5-2.47-.74-3.84-1.24-1.37-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-178" d="M78.25,107.65c-.83,.75-.5,2.47,.74,3.83,1.24,1.36,2.92,1.86,3.75,1.11,.83-.75,.5-2.47-.74-3.83-1.24-1.36-2.92-1.86-3.74-1.11Z"/>
          <path class="cls-93" d="M78.25,107.66c-.83,.75-.5,2.47,.74,3.83,1.24,1.36,2.91,1.86,3.74,1.1,.83-.75,.5-2.47-.74-3.83-1.24-1.36-2.91-1.86-3.74-1.1Z"/>
          <path class="cls-65" d="M78.26,107.66c-.83,.75-.5,2.46,.74,3.82,1.24,1.36,2.91,1.85,3.73,1.1,.83-.75,.5-2.46-.74-3.82-1.24-1.36-2.91-1.85-3.73-1.1Z"/>
          <path class="cls-164" d="M78.26,107.66c-.83,.75-.49,2.46,.74,3.82,1.23,1.36,2.9,1.85,3.73,1.1,.83-.75,.49-2.46-.74-3.82-1.23-1.36-2.9-1.85-3.73-1.1Z"/>
          <path class="cls-174" d="M78.26,107.67c-.82,.75-.49,2.46,.74,3.81,1.23,1.36,2.9,1.85,3.72,1.1,.82-.75,.49-2.46-.74-3.81-1.23-1.36-2.9-1.85-3.72-1.1Z"/>
          <path class="cls-92" d="M78.27,107.67c-.82,.75-.49,2.45,.74,3.81,1.23,1.35,2.9,1.85,3.72,1.1,.82-.75,.49-2.45-.74-3.81-1.23-1.35-2.9-1.85-3.72-1.1Z"/>
          <path class="cls-203" d="M78.27,107.67c-.82,.75-.49,2.45,.74,3.8,1.23,1.35,2.89,1.84,3.71,1.1,.82-.75,.49-2.45-.74-3.8-1.23-1.35-2.89-1.84-3.71-1.1Z"/>
          <path class="cls-144" d="M78.27,107.68c-.82,.75-.49,2.45,.74,3.8,1.23,1.35,2.89,1.84,3.71,1.09,.82-.75,.49-2.45-.74-3.79-1.23-1.35-2.89-1.84-3.71-1.09Z"/>
          <path class="cls-195" d="M78.28,107.68c-.82,.75-.49,2.44,.73,3.79,1.23,1.35,2.88,1.84,3.7,1.09,.82-.75,.49-2.44-.73-3.79-1.23-1.35-2.88-1.84-3.7-1.09Z"/>
          <path class="cls-140" d="M78.28,107.69c-.82,.74-.49,2.44,.73,3.79,1.22,1.35,2.88,1.84,3.7,1.09,.82-.74,.49-2.44-.73-3.79-1.22-1.35-2.88-1.84-3.7-1.09Z"/>
          <path class="cls-120" d="M78.28,107.69c-.82,.74-.49,2.44,.73,3.78,1.22,1.34,2.88,1.83,3.69,1.09,.82-.74,.49-2.44-.73-3.78-1.22-1.34-2.88-1.83-3.69-1.09Z"/>
          <path class="cls-173" d="M78.28,107.69c-.82,.74-.49,2.43,.73,3.77,1.22,1.34,2.87,1.83,3.69,1.09,.82-.74,.49-2.43-.73-3.78-1.22-1.34-2.87-1.83-3.69-1.09Z"/>
          <path class="cls-79" d="M78.29,107.7c-.82,.74-.49,2.43,.73,3.77,1.22,1.34,2.87,1.83,3.68,1.09,.81-.74,.49-2.43-.73-3.77-1.22-1.34-2.87-1.83-3.68-1.09Z"/>
          <path class="cls-179" d="M78.29,107.7c-.81,.74-.49,2.42,.73,3.76,1.22,1.34,2.86,1.83,3.68,1.09,.81-.74,.49-2.43-.73-3.76-1.22-1.34-2.86-1.83-3.68-1.08Z"/>
          <path class="cls-179" d="M78.29,107.7c-.81,.74-.49,2.42,.73,3.76,1.21,1.34,2.86,1.82,3.67,1.08,.81-.74,.49-2.42-.73-3.76-1.21-1.34-2.86-1.82-3.67-1.08Z"/>
          <path class="cls-173" d="M78.3,107.71c-.81,.74-.49,2.41,.73,3.75,1.21,1.33,2.85,1.82,3.66,1.08,.81-.74,.49-2.41-.73-3.75-1.21-1.33-2.85-1.82-3.66-1.08Z"/>
          <path class="cls-140" d="M78.31,107.72c-.81,.73-.48,2.41,.72,3.74,1.21,1.33,2.84,1.81,3.65,1.08,.81-.73,.48-2.41-.72-3.74-1.21-1.33-2.84-1.81-3.65-1.08Z"/>
          <path class="cls-144" d="M78.31,107.72c-.81,.73-.48,2.4,.72,3.72,1.2,1.33,2.83,1.81,3.64,1.07,.81-.73,.48-2.4-.72-3.72-1.2-1.33-2.83-1.81-3.64-1.07Z"/>
          <path class="cls-115" d="M78.32,107.73c-.8,.73-.48,2.39,.72,3.71s2.82,1.8,3.63,1.07c.8-.73,.48-2.39-.72-3.71s-2.82-1.8-3.63-1.07Z"/>
          <path class="cls-88" d="M78.33,107.74c-.8,.73-.48,2.38,.72,3.7s2.82,1.79,3.62,1.07c.8-.73,.48-2.38-.72-3.7-1.2-1.32-2.82-1.79-3.62-1.07Z"/>
          <path class="cls-162" d="M78.33,107.75c-.8,.72-.48,2.38,.71,3.69,1.19,1.31,2.81,1.79,3.6,1.06,.8-.72,.48-2.38-.71-3.69-1.19-1.31-2.81-1.79-3.6-1.06Z"/>
          <path class="cls-133" d="M78.34,107.75c-.8,.72-.48,2.37,.71,3.68,1.19,1.31,2.8,1.78,3.59,1.06,.8-.72,.48-2.37-.71-3.68-1.19-1.31-2.8-1.78-3.59-1.06Z"/>
          <path class="cls-66" d="M78.35,107.76c-.79,.72-.47,2.36,.71,3.66,1.19,1.3,2.79,1.78,3.58,1.06,.79-.72,.47-2.36-.71-3.67-1.19-1.3-2.79-1.78-3.58-1.06Z"/>
          <path class="cls-109" d="M78.36,107.77c-.79,.72-.47,2.35,.71,3.65,1.18,1.3,2.78,1.77,3.57,1.05,.79-.72,.47-2.35-.71-3.65-1.18-1.3-2.78-1.77-3.57-1.05Z"/>
          <path class="cls-43" d="M78.36,107.78c-.79,.72-.47,2.35,.71,3.64,1.18,1.3,2.77,1.77,3.56,1.05,.79-.72,.47-2.35-.71-3.64-1.18-1.29-2.77-1.77-3.56-1.05Z"/>
          <path class="cls-95" d="M78.37,107.78c-.79,.71-.47,2.34,.7,3.63,1.17,1.29,2.76,1.76,3.55,1.05,.78-.71,.47-2.34-.7-3.63-1.17-1.29-2.76-1.76-3.55-1.05Z"/>
          <path class="cls-99" d="M78.38,107.79c-.78,.71-.47,2.33,.7,3.62,1.17,1.29,2.75,1.75,3.54,1.04,.78-.71,.47-2.33-.7-3.62-1.17-1.29-2.75-1.75-3.54-1.04Z"/>
          <path class="cls-181" d="M78.38,107.8c-.78,.71-.47,2.32,.7,3.61,1.17,1.28,2.74,1.75,3.52,1.04,.78-.71,.47-2.32-.7-3.61-1.17-1.28-2.74-1.75-3.52-1.04Z"/>
          <path class="cls-152" d="M78.39,107.81c-.78,.71-.47,2.32,.7,3.6,1.16,1.28,2.74,1.74,3.51,1.04,.78-.71,.46-2.32-.7-3.6-1.16-1.28-2.74-1.74-3.51-1.04Z"/>
          <path class="cls-125" d="M78.4,107.81c-.78,.7-.46,2.31,.69,3.58,1.16,1.28,2.73,1.74,3.5,1.03,.77-.7,.46-2.31-.7-3.58-1.16-1.28-2.73-1.74-3.5-1.03Z"/>
          <path class="cls-148" d="M78.4,107.82c-.77,.7-.46,2.3,.69,3.57,1.15,1.27,2.72,1.73,3.49,1.03,.77-.7,.46-2.3-.69-3.57-1.15-1.27-2.72-1.73-3.49-1.03Z"/>
          <path class="cls-68" d="M78.41,107.83c-.77,.7-.46,2.29,.69,3.56,1.15,1.27,2.71,1.73,3.48,1.03,.77-.7,.46-2.3-.69-3.56-1.15-1.27-2.71-1.73-3.48-1.03Z"/>
          <path class="cls-157" d="M78.42,107.84c-.77,.7-.46,2.29,.69,3.55,1.15,1.26,2.7,1.72,3.47,1.02,.77-.7,.46-2.29-.69-3.55-1.15-1.26-2.7-1.72-3.47-1.02Z"/>
          <path class="cls-143" d="M78.42,107.84c-.76,.7-.46,2.28,.69,3.54,1.14,1.26,2.69,1.72,3.46,1.02,.76-.7,.46-2.28-.69-3.54-1.14-1.26-2.69-1.72-3.46-1.02Z"/>
          <path class="cls-196" d="M78.43,107.85c-.76,.69-.46,2.27,.68,3.53,1.14,1.25,2.68,1.71,3.45,1.02,.76-.69,.46-2.27-.68-3.53-1.14-1.25-2.68-1.71-3.45-1.02Z"/>
          <path class="cls-104" d="M78.44,107.86c-.76,.69-.45,2.26,.68,3.51,1.14,1.25,2.67,1.7,3.43,1.01s.46-2.26-.68-3.51c-1.14-1.25-2.67-1.7-3.43-1.01Z"/>
          <path class="cls-105" d="M78.44,107.87c-.76,.69-.45,2.26,.68,3.5,1.13,1.25,2.66,1.7,3.42,1.01,.76-.69,.45-2.26-.68-3.5-1.13-1.25-2.66-1.7-3.42-1.01Z"/>
          <path class="cls-137" d="M78.45,107.87c-.75,.69-.45,2.25,.68,3.49,1.13,1.24,2.66,1.69,3.41,1.01,.75-.69,.45-2.25-.68-3.49-1.13-1.24-2.66-1.69-3.41-1.01Z"/>
          <path class="cls-167" d="M78.46,107.88c-.75,.68-.45,2.24,.67,3.48,1.12,1.24,2.65,1.69,3.4,1,.75-.68,.45-2.24-.67-3.48-1.12-1.24-2.65-1.69-3.4-1Z"/>
          <path class="cls-67" d="M78.46,107.89c-.75,.68-.45,2.23,.67,3.47s2.64,1.68,3.39,1c.75-.68,.45-2.23-.67-3.47s-2.64-1.68-3.39-1Z"/>
          <path class="cls-96" d="M78.47,107.9c-.75,.68-.45,2.23,.67,3.46,1.12,1.23,2.63,1.68,3.38,1,.75-.68,.45-2.23-.67-3.46-1.12-1.23-2.63-1.68-3.38-1Z"/>
          <path class="cls-134" d="M78.48,107.9c-.74,.68-.45,2.22,.67,3.45,1.11,1.23,2.62,1.67,3.37,.99,.75-.68,.45-2.22-.67-3.44-1.11-1.23-2.62-1.67-3.37-.99Z"/>
          <path class="cls-56" d="M78.48,107.91c-.74,.67-.44,2.21,.67,3.43,1.11,1.22,2.61,1.67,3.35,.99,.74-.67,.44-2.21-.67-3.43-1.11-1.22-2.61-1.66-3.35-.99Z"/>
          <path class="cls-132" d="M78.49,107.92c-.74,.67-.44,2.2,.66,3.42,1.11,1.22,2.6,1.66,3.34,.99,.74-.67,.44-2.2-.66-3.42-1.11-1.22-2.6-1.66-3.34-.99Z"/>
          <path class="cls-118" d="M78.5,107.93c-.74,.67-.44,2.2,.66,3.41,1.1,1.21,2.59,1.65,3.33,.98s.44-2.2-.66-3.41c-1.1-1.21-2.59-1.65-3.33-.98Z"/>
          <path class="cls-155" d="M78.5,107.93c-.73,.67-.44,2.19,.66,3.4,1.1,1.21,2.58,1.65,3.32,.98,.73-.67,.44-2.19-.66-3.4-1.1-1.21-2.58-1.65-3.32-.98Z"/>
          <path class="cls-122" d="M78.51,107.94c-.73,.67-.44,2.18,.66,3.39,1.09,1.21,2.58,1.64,3.31,.98,.73-.67,.44-2.18-.66-3.39-1.09-1.2-2.58-1.64-3.31-.98Z"/>
          <path class="cls-128" d="M78.52,107.95c-.73,.66-.44,2.17,.65,3.37,1.09,1.2,2.57,1.64,3.3,.97,.73-.66,.44-2.17-.65-3.38s-2.57-1.64-3.3-.97Z"/>
          <path class="cls-158" d="M78.53,107.96c-.73,.66-.44,2.17,.65,3.36,1.09,1.2,2.56,1.63,3.29,.97,.73-.66,.44-2.17-.65-3.36-1.09-1.2-2.56-1.63-3.29-.97Z"/>
          <path class="cls-168" d="M78.53,107.96c-.72,.66-.43,2.16,.65,3.35,1.08,1.19,2.55,1.62,3.27,.97,.72-.66,.43-2.16-.65-3.35-1.08-1.19-2.55-1.63-3.27-.97Z"/>
          <path class="cls-73" d="M78.54,107.97c-.72,.66-.43,2.15,.65,3.34,1.08,1.19,2.54,1.62,3.26,.96,.72-.66,.43-2.15-.65-3.34-1.08-1.19-2.54-1.62-3.26-.96Z"/>
          <path class="cls-58" d="M78.55,107.98c-.72,.65-.43,2.14,.65,3.33,1.08,1.18,2.53,1.61,3.25,.96,.72-.65,.43-2.14-.65-3.33-1.08-1.18-2.53-1.61-3.25-.96Z"/>
          <path class="cls-184" d="M78.55,107.99c-.72,.65-.43,2.14,.64,3.32,1.07,1.18,2.52,1.61,3.24,.96,.72-.65,.43-2.14-.64-3.32-1.07-1.18-2.52-1.61-3.24-.96Z"/>
          <path class="cls-185" d="M78.56,107.99c-.71,.65-.43,2.13,.64,3.31,1.07,1.18,2.51,1.6,3.23,.95,.71-.65,.43-2.13-.64-3.31-1.07-1.18-2.51-1.6-3.23-.95Z"/>
          <path class="cls-186" d="M78.57,108c-.71,.65-.43,2.12,.64,3.29,1.06,1.17,2.5,1.6,3.22,.95s.43-2.12-.64-3.29c-1.06-1.17-2.5-1.6-3.22-.95Z"/>
          <path class="cls-74" d="M78.57,108.01c-.71,.64-.42,2.11,.64,3.28,1.06,1.17,2.5,1.59,3.21,.95,.71-.64,.42-2.11-.64-3.28-1.06-1.17-2.5-1.59-3.21-.95Z"/>
          <path class="cls-90" d="M78.58,108.02c-.71,.64-.42,2.11,.63,3.27s2.49,1.59,3.19,.94c.71-.64,.42-2.11-.63-3.27-1.06-1.16-2.49-1.59-3.19-.94Z"/>
          <path class="cls-15" d="M78.59,108.02c-.71,.64-.42,2.1,.63,3.26,1.05,1.16,2.48,1.58,3.18,.94,.71-.64,.42-2.1-.63-3.26-1.05-1.16-2.48-1.58-3.18-.94Z"/>
          <path class="cls-156" d="M78.59,108.03c-.7,.64-.42,2.09,.63,3.25,1.05,1.16,2.47,1.58,3.17,.94,.7-.64,.42-2.09-.63-3.25-1.05-1.16-2.47-1.57-3.17-.94Z"/>
          <path class="cls-123" d="M78.6,108.04c-.7,.64-.42,2.08,.63,3.23,1.05,1.15,2.46,1.57,3.16,.93,.7-.64,.42-2.09-.63-3.24-1.05-1.15-2.46-1.57-3.16-.93Z"/>
          <path class="cls-175" d="M78.61,108.05c-.7,.63-.42,2.08,.62,3.22,1.04,1.15,2.45,1.56,3.15,.93,.7-.63,.42-2.08-.62-3.22-1.04-1.15-2.45-1.56-3.15-.93Z"/>
          <path class="cls-188" d="M78.61,108.05c-.69,.63-.42,2.07,.62,3.21,1.04,1.14,2.44,1.56,3.14,.93,.69-.63,.42-2.07-.62-3.21-1.04-1.14-2.44-1.56-3.14-.93Z"/>
          <path class="cls-1" d="M78.62,108.06c-.69,.63-.41,2.06,.62,3.2,1.03,1.14,2.43,1.55,3.13,.92,.69-.63,.41-2.06-.62-3.2-1.03-1.14-2.43-1.55-3.13-.92Z"/>
          <path class="cls-189" d="M78.63,108.07c-.69,.63-.41,2.05,.62,3.19,1.03,1.13,2.42,1.55,3.11,.92,.69-.63,.41-2.05-.62-3.19-1.03-1.13-2.42-1.55-3.11-.92Z"/>
          <path class="cls-71" d="M78.63,108.08c-.69,.62-.41,2.05,.62,3.18,1.03,1.13,2.42,1.54,3.1,.92,.69-.62,.41-2.05-.62-3.18-1.03-1.13-2.42-1.54-3.1-.92Z"/>
          <path class="cls-103" d="M78.64,108.08c-.68,.62-.41,2.04,.61,3.16,1.02,1.13,2.41,1.53,3.09,.91,.68-.62,.41-2.04-.61-3.17-1.02-1.13-2.41-1.53-3.09-.91Z"/>
          <path class="cls-76" d="M78.65,108.09c-.68,.62-.41,2.03,.61,3.15,1.02,1.12,2.4,1.53,3.08,.91,.68-.62,.41-2.03-.61-3.15-1.02-1.12-2.4-1.53-3.08-.91Z"/>
          <path class="cls-136" d="M78.66,108.1c-.68,.62-.41,2.02,.61,3.14,1.02,1.12,2.39,1.52,3.07,.91,.68-.62,.41-2.02-.61-3.14-1.02-1.12-2.39-1.52-3.07-.91Z"/>
          <path class="cls-154" d="M78.66,108.11c-.68,.62-.41,2.02,.61,3.13,1.01,1.11,2.38,1.52,3.06,.9,.68-.61,.41-2.02-.61-3.13-1.01-1.11-2.38-1.52-3.06-.9Z"/>
          <path class="cls-112" d="M78.67,108.11c-.67,.61-.4,2.01,.6,3.12,1.01,1.11,2.37,1.51,3.05,.9,.67-.61,.4-2.01-.61-3.12-1.01-1.11-2.37-1.51-3.05-.9Z"/>
          <path class="cls-191" d="M78.68,108.12c-.67,.61-.4,2,.6,3.11,1,1.11,2.36,1.51,3.04,.9,.67-.61,.4-2-.6-3.11-1-1.11-2.36-1.51-3.04-.9Z"/>
          <path class="cls-82" d="M78.68,108.13c-.67,.61-.4,1.99,.6,3.09,1,1.1,2.35,1.5,3.02,.89,.67-.61,.4-1.99-.6-3.09-1-1.1-2.35-1.5-3.02-.89Z"/>
          <path class="cls-5" d="M78.69,108.14c-.67,.61-.4,1.99,.6,3.08,1,1.1,2.35,1.5,3.01,.89,.67-.61,.4-1.99-.6-3.08-1-1.1-2.35-1.5-3.01-.89Z"/>
          <path class="cls-42" d="M78.7,108.14c-.66,.6-.4,1.98,.6,3.07,.99,1.09,2.34,1.49,3,.89,.66-.6,.4-1.98-.6-3.07-.99-1.09-2.34-1.49-3-.89Z"/>
          <path class="cls-207" d="M78.7,108.15c-.66,.6-.4,1.97,.59,3.06,.99,1.09,2.33,1.48,2.99,.88,.66-.6,.4-1.97-.59-3.06-.99-1.09-2.33-1.48-2.99-.88Z"/>
          <path class="cls-107" d="M78.71,108.16c-.66,.6-.39,1.96,.59,3.05,.99,1.08,2.32,1.48,2.98,.88,.66-.6,.39-1.96-.59-3.05-.99-1.08-2.32-1.48-2.98-.88Z"/>
          <path class="cls-78" d="M78.72,108.17c-.66,.6-.39,1.96,.59,3.04,.98,1.08,2.31,1.47,2.97,.88,.66-.6,.39-1.96-.59-3.04-.98-1.08-2.31-1.47-2.97-.88Z"/>
          <path class="cls-75" d="M78.72,108.17c-.65,.59-.39,1.95,.59,3.03s2.3,1.47,2.96,.87c.65-.59,.39-1.95-.59-3.03-.98-1.08-2.3-1.47-2.96-.87Z"/>
          <path class="cls-20" d="M78.73,108.18c-.65,.59-.39,1.94,.58,3.01,.97,1.07,2.29,1.46,2.94,.87,.65-.59,.39-1.94-.58-3.01-.97-1.07-2.29-1.46-2.94-.87Z"/>
          <path class="cls-201" d="M78.74,108.19c-.65,.59-.39,1.93,.58,3,.97,1.07,2.28,1.46,2.93,.87,.65-.59,.39-1.93-.58-3-.97-1.07-2.28-1.46-2.93-.87Z"/>
          <path class="cls-201" d="M78.74,108.2c-.65,.59-.39,1.93,.58,2.99,.97,1.06,2.27,1.45,2.92,.86,.65-.59,.39-1.93-.58-2.99-.97-1.06-2.27-1.45-2.92-.86Z"/>
          <path class="cls-126" d="M78.75,108.2c-.64,.58-.39,1.92,.58,2.98,.96,1.06,2.27,1.44,2.91,.86,.64-.59,.39-1.92-.58-2.98-.96-1.06-2.27-1.44-2.91-.86Z"/>
          <path class="cls-35" d="M78.76,108.21c-.64,.58-.38,1.91,.58,2.97,.96,1.06,2.26,1.44,2.9,.86,.64-.58,.38-1.91-.58-2.97-.96-1.06-2.26-1.44-2.9-.86Z"/>
          <path class="cls-204" d="M78.76,108.22c-.64,.58-.38,1.9,.57,2.96,.95,1.05,2.25,1.43,2.89,.85,.64-.58,.38-1.9-.57-2.95-.96-1.05-2.25-1.43-2.89-.85Z"/>
          <path class="cls-89" d="M78.77,108.23c-.64,.58-.38,1.9,.57,2.94,.95,1.05,2.24,1.43,2.88,.85,.64-.58,.38-1.9-.57-2.94-.95-1.05-2.24-1.43-2.88-.85Z"/>
          <path class="cls-91" d="M78.78,108.23c-.63,.58-.38,1.89,.57,2.93,.95,1.04,2.23,1.42,2.87,.85s.38-1.89-.57-2.93c-.95-1.04-2.23-1.42-2.87-.85Z"/>
          <path class="cls-94" d="M78.78,108.24c-.63,.57-.38,1.88,.57,2.92,.94,1.04,2.22,1.42,2.85,.84,.63-.57,.38-1.88-.57-2.92-.94-1.04-2.22-1.42-2.85-.84Z"/>
          <path class="cls-94" d="M78.79,108.25c-.63,.57-.38,1.87,.56,2.91,.94,1.03,2.21,1.41,2.84,.84,.63-.57,.38-1.88-.56-2.91-.94-1.04-2.21-1.41-2.84-.84Z"/>
          <path class="cls-80" d="M78.8,108.26c-.63,.57-.37,1.87,.56,2.9,.94,1.03,2.2,1.41,2.83,.84,.63-.57,.37-1.87-.56-2.9-.94-1.03-2.2-1.41-2.83-.84Z"/>
          <path class="cls-141" d="M78.8,108.26c-.62,.57-.37,1.86,.56,2.89,.93,1.03,2.2,1.4,2.82,.83,.62-.57,.37-1.86-.56-2.89-.93-1.03-2.2-1.4-2.82-.83Z"/>
          <path class="cls-130" d="M78.81,108.27c-.62,.56-.37,1.85,.56,2.87,.93,1.02,2.19,1.39,2.81,.83,.62-.57,.37-1.85-.56-2.88-.93-1.02-2.19-1.39-2.81-.83Z"/>
          <path class="cls-21" d="M78.82,108.28c-.62,.56-.37,1.84,.55,2.86,.93,1.02,2.18,1.39,2.8,.83,.62-.56,.37-1.84-.55-2.86-.93-1.02-2.18-1.39-2.8-.83Z"/>
          <path class="cls-21" d="M78.83,108.29c-.62,.56-.37,1.84,.55,2.85,.92,1.01,2.17,1.38,2.79,.82,.62-.56,.37-1.84-.55-2.85-.92-1.01-2.17-1.38-2.79-.82Z"/>
          <path class="cls-187" d="M78.83,108.29c-.61,.56-.37,1.83,.55,2.84,.92,1.01,2.16,1.38,2.77,.82,.61-.56,.37-1.83-.55-2.84-.92-1.01-2.16-1.38-2.77-.82Z"/>
          <path class="cls-192" d="M78.84,108.3c-.61,.56-.37,1.82,.55,2.83,.91,1.01,2.15,1.37,2.76,.82,.61-.56,.37-1.82-.55-2.83-.91-1.01-2.15-1.37-2.76-.82Z"/>
          <path class="cls-192" d="M78.85,108.31c-.61,.55-.36,1.81,.55,2.82,.91,1,2.14,1.37,2.75,.81,.61-.55,.36-1.81-.55-2.82-.91-1-2.14-1.37-2.75-.81Z"/>
          <path class="cls-129" d="M78.85,108.32c-.61,.55-.36,1.81,.54,2.8,.91,1,2.13,1.36,2.74,.81,.61-.55,.36-1.81-.54-2.8-.91-1-2.13-1.36-2.74-.81Z"/>
          <path class="cls-129" d="M78.86,108.32c-.6,.55-.36,1.8,.54,2.79,.9,.99,2.12,1.35,2.73,.81,.6-.55,.36-1.8-.54-2.79-.9-.99-2.12-1.35-2.73-.81Z"/>
          <path class="cls-83" d="M78.87,108.33c-.6,.55-.36,1.79,.54,2.78,.9,.99,2.12,1.35,2.72,.8,.6-.55,.36-1.79-.54-2.78-.9-.99-2.12-1.35-2.72-.8Z"/>
          <path class="cls-177" d="M78.87,108.34c-.6,.54-.36,1.78,.54,2.77,.9,.99,2.11,1.34,2.71,.8,.6-.54,.36-1.78-.54-2.77-.89-.99-2.11-1.34-2.71-.8Z"/>
          <path class="cls-177" d="M78.88,108.35c-.6,.54-.36,1.78,.53,2.76,.89,.98,2.1,1.34,2.69,.8,.6-.54,.36-1.78-.53-2.76-.89-.98-2.1-1.34-2.69-.8Z"/>
          <path class="cls-37" d="M78.89,108.35c-.59,.54-.36,1.77,.53,2.75,.89,.98,2.09,1.33,2.68,.79,.59-.54,.36-1.77-.53-2.75-.89-.98-2.09-1.33-2.68-.79Z"/>
          <path class="cls-169" d="M78.89,108.36c-.59,.54-.35,1.76,.53,2.73,.88,.97,2.08,1.33,2.67,.79,.59-.54,.35-1.76-.53-2.73-.88-.97-2.08-1.33-2.67-.79Z"/>
          <path class="cls-19" d="M78.9,108.37c-.59,.54-.35,1.75,.53,2.72,.88,.97,2.07,1.32,2.66,.79,.59-.54,.35-1.75-.53-2.72-.88-.97-2.07-1.32-2.66-.79Z"/>
          <path class="cls-19" d="M78.91,108.38c-.59,.53-.35,1.75,.53,2.71,.88,.96,2.06,1.32,2.65,.78,.59-.53,.35-1.75-.53-2.71-.88-.96-2.06-1.32-2.65-.78Z"/>
          <path class="cls-19" d="M78.91,108.38c-.58,.53-.35,1.74,.52,2.7,.87,.96,2.05,1.31,2.64,.78,.58-.53,.35-1.74-.52-2.7-.87-.96-2.05-1.31-2.64-.78Z"/>
          <path class="cls-6" d="M78.92,108.39c-.58,.53-.35,1.73,.52,2.69,.87,.96,2.04,1.3,2.63,.78,.58-.53,.35-1.73-.52-2.69-.87-.96-2.04-1.3-2.63-.78Z"/>
          <path class="cls-6" d="M78.93,108.4c-.58,.53-.35,1.72,.52,2.68,.87,.95,2.04,1.3,2.61,.77,.58-.53,.35-1.72-.52-2.68-.87-.95-2.04-1.3-2.61-.77Z"/>
          <path class="cls-6" d="M78.93,108.41c-.58,.52-.35,1.72,.52,2.66,.86,.95,2.03,1.29,2.6,.77,.58-.52,.35-1.72-.52-2.66-.86-.95-2.03-1.29-2.6-.77Z"/>
          <path class="cls-6" d="M78.94,108.41c-.57,.52-.34,1.71,.51,2.65,.86,.94,2.02,1.29,2.59,.77,.57-.52,.34-1.71-.51-2.65-.86-.94-2.02-1.29-2.59-.77Z"/>
          <path class="cls-146" d="M78.95,108.42c-.57,.52-.34,1.7,.51,2.64,.85,.94,2.01,1.28,2.58,.76,.57-.52,.34-1.7-.51-2.64-.85-.94-2.01-1.28-2.58-.76Z"/>
          <path class="cls-161" d="M78.95,108.43c-.57,.52-.34,1.69,.51,2.63,.85,.94,2,1.28,2.57,.76,.57-.52,.34-1.69-.51-2.63-.85-.94-2-1.27-2.57-.76Z"/>
          <path class="cls-161" d="M78.96,108.44c-.57,.51-.34,1.69,.51,2.62,.85,.93,1.99,1.27,2.56,.75,.57-.51,.34-1.69-.51-2.62-.85-.93-1.99-1.27-2.56-.76Z"/>
          <path class="cls-161" d="M78.97,108.44c-.56,.51-.34,1.68,.5,2.61,.84,.93,1.98,1.26,2.55,.75,.56-.51,.34-1.68-.51-2.61-.84-.93-1.98-1.26-2.55-.75Z"/>
          <path class="cls-14" d="M78.98,108.45c-.56,.51-.34,1.67,.5,2.59,.84,.92,1.97,1.26,2.54,.75,.56-.51,.34-1.67-.5-2.59-.84-.92-1.97-1.26-2.53-.75Z"/>
          <path class="cls-14" d="M78.98,108.46c-.56,.51-.33,1.66,.5,2.58,.84,.92,1.97,1.25,2.52,.75s.33-1.67-.5-2.58c-.83-.92-1.96-1.25-2.52-.74Z"/>
          <path class="cls-176" d="M78.99,108.47c-.56,.51-.33,1.66,.5,2.57,.83,.91,1.96,1.25,2.51,.74,.56-.51,.33-1.66-.5-2.57-.83-.92-1.96-1.25-2.51-.74Z"/>
          <path class="cls-176" d="M79,108.47c-.55,.5-.33,1.65,.5,2.56,.83,.91,1.95,1.24,2.5,.74,.55-.5,.33-1.65-.5-2.56-.83-.91-1.95-1.24-2.5-.74Z"/>
          <path class="cls-176" d="M79,108.48c-.55,.5-.33,1.64,.49,2.55,.82,.91,1.94,1.24,2.49,.74,.55-.5,.33-1.64-.49-2.55-.82-.91-1.94-1.24-2.49-.73Z"/>
          <path class="cls-40" d="M79.01,108.49c-.55,.5-.33,1.63,.49,2.54,.82,.9,1.93,1.23,2.48,.73,.55-.5,.33-1.63-.49-2.54-.82-.9-1.93-1.23-2.48-.73Z"/>
          <path class="cls-40" d="M79.02,108.5c-.55,.5-.33,1.63,.49,2.53,.82,.9,1.92,1.22,2.47,.73s.33-1.63-.49-2.52c-.82-.9-1.92-1.22-2.47-.73Z"/>
          <path class="cls-199" d="M79.02,108.5c-.54,.49-.33,1.62,.49,2.51,.81,.89,1.91,1.22,2.46,.73,.54-.49,.33-1.62-.49-2.51-.81-.89-1.91-1.22-2.46-.72Z"/>
          <path class="cls-199" d="M79.03,108.51c-.54,.49-.32,1.61,.49,2.5,.81,.89,1.9,1.21,2.44,.72,.54-.49,.32-1.61-.49-2.5-.81-.89-1.9-1.21-2.44-.72Z"/>
          <path class="cls-199" d="M79.04,108.52c-.54,.49-.32,1.6,.48,2.49,.8,.89,1.89,1.21,2.43,.72,.54-.49,.32-1.6-.48-2.49-.8-.89-1.89-1.21-2.43-.72Z"/>
          <path class="cls-199" d="M79.04,108.53c-.54,.49-.32,1.6,.48,2.48,.8,.88,1.89,1.2,2.42,.71,.54-.49,.32-1.6-.48-2.48-.8-.88-1.89-1.2-2.42-.71Z"/>
          <path class="cls-199" d="M79.05,108.53c-.53,.49-.32,1.59,.48,2.47,.8,.88,1.88,1.2,2.41,.71,.53-.49,.32-1.59-.48-2.47-.8-.88-1.88-1.2-2.41-.71Z"/>
          <path class="cls-199" d="M79.06,108.54c-.53,.48-.32,1.58,.48,2.46,.79,.87,1.87,1.19,2.4,.71,.53-.48,.32-1.58-.48-2.46-.79-.87-1.87-1.19-2.4-.71Z"/>
          <path class="cls-199" d="M79.06,108.55c-.53,.48-.32,1.57,.47,2.44,.79,.87,1.86,1.18,2.39,.7,.53-.48,.32-1.58-.47-2.44-.79-.87-1.86-1.18-2.39-.7Z"/>
          <path class="cls-199" d="M79.07,108.56c-.53,.48-.31,1.57,.47,2.43,.79,.87,1.85,1.18,2.38,.7,.53-.48,.31-1.57-.47-2.43-.79-.87-1.85-1.18-2.38-.7Z"/>
          <path class="cls-199" d="M79.08,108.56c-.52,.48-.31,1.56,.47,2.42,.78,.86,1.84,1.17,2.36,.7s.31-1.56-.47-2.42c-.78-.86-1.84-1.17-2.36-.7Z"/>
          <path class="cls-199" d="M79.08,108.57c-.52,.47-.31,1.55,.47,2.41,.78,.86,1.83,1.17,2.35,.7,.52-.47,.31-1.55-.47-2.41-.78-.86-1.83-1.17-2.35-.69Z"/>
          <path class="cls-199" d="M79.09,108.58c-.52,.47-.31,1.54,.46,2.4,.78,.85,1.82,1.16,2.34,.69,.52-.47,.31-1.55-.46-2.4-.77-.85-1.82-1.16-2.34-.69Z"/>
        </g>
        <g class="cls-159">
          <rect class="cls-199" x="50.27" y="91.44" width="7.44" height="7.85"/>
          <path class="cls-199" d="M50.61,92.95c-.95,.86-.57,2.82,.85,4.38,1.42,1.56,3.33,2.12,4.28,1.26,.95-.86,.57-2.82-.85-4.38-1.42-1.56-3.33-2.12-4.28-1.26Z"/>
          <path class="cls-199" d="M50.61,92.95c-.95,.86-.57,2.82,.85,4.38,1.41,1.56,3.33,2.12,4.28,1.26,.95-.86,.57-2.82-.85-4.38-1.41-1.56-3.33-2.12-4.28-1.26Z"/>
          <path class="cls-176" d="M50.61,92.96c-.95,.86-.57,2.82,.85,4.37,1.41,1.56,3.33,2.12,4.27,1.26s.57-2.82-.85-4.37c-1.41-1.55-3.33-2.12-4.27-1.26Z"/>
          <path class="cls-14" d="M50.62,92.96c-.94,.86-.56,2.81,.85,4.37,1.41,1.55,3.32,2.12,4.27,1.26,.94-.86,.57-2.81-.85-4.37-1.41-1.55-3.32-2.12-4.27-1.26Z"/>
          <path class="cls-161" d="M50.62,92.96c-.94,.86-.57,2.81,.84,4.36,1.41,1.55,3.32,2.12,4.26,1.26,.94-.86,.56-2.81-.85-4.36-1.41-1.55-3.32-2.11-4.26-1.26Z"/>
          <path class="cls-6" d="M50.62,92.97c-.94,.86-.56,2.81,.84,4.36s3.31,2.11,4.26,1.26c.94-.86,.56-2.81-.84-4.36s-3.31-2.11-4.25-1.26Z"/>
          <path class="cls-6" d="M50.62,92.97c-.94,.86-.56,2.8,.84,4.35,1.41,1.55,3.31,2.11,4.25,1.25,.94-.85,.56-2.8-.84-4.35-1.41-1.55-3.31-2.11-4.25-1.25Z"/>
          <path class="cls-19" d="M50.63,92.98c-.94,.85-.56,2.8,.84,4.34,1.4,1.55,3.3,2.11,4.25,1.25,.94-.85,.56-2.8-.84-4.34-1.41-1.55-3.31-2.11-4.25-1.25Z"/>
          <path class="cls-169" d="M50.63,92.98c-.94,.85-.56,2.8,.84,4.34,1.4,1.55,3.3,2.1,4.24,1.25,.94-.85,.56-2.8-.84-4.34-1.4-1.54-3.3-2.1-4.24-1.25Z"/>
          <path class="cls-37" d="M50.63,92.98c-.94,.85-.56,2.79,.84,4.33,1.4,1.54,3.3,2.1,4.23,1.25,.94-.85,.56-2.79-.84-4.33s-3.3-2.1-4.24-1.25Z"/>
          <path class="cls-60" d="M50.64,92.99c-.94,.85-.56,2.79,.84,4.33,1.4,1.54,3.29,2.1,4.23,1.25s.56-2.79-.84-4.33c-1.4-1.54-3.29-2.1-4.23-1.25Z"/>
          <path class="cls-129" d="M50.64,92.99c-.94,.85-.56,2.79,.84,4.32,1.4,1.54,3.29,2.1,4.22,1.25,.93-.85,.56-2.79-.84-4.32-1.4-1.54-3.29-2.1-4.22-1.25Z"/>
          <path class="cls-192" d="M50.64,92.99c-.93,.85-.56,2.78,.84,4.32,1.4,1.54,3.29,2.09,4.22,1.25,.93-.85,.56-2.78-.84-4.32-1.4-1.54-3.29-2.09-4.22-1.25Z"/>
          <path class="cls-205" d="M50.65,93c-.93,.85-.56,2.78,.84,4.31,1.39,1.53,3.28,2.09,4.21,1.24,.93-.85,.56-2.78-.84-4.31-1.39-1.53-3.28-2.09-4.21-1.24Z"/>
          <path class="cls-64" d="M50.65,93c-.93,.85-.56,2.78,.83,4.31,1.39,1.53,3.28,2.09,4.21,1.24,.93-.85,.56-2.78-.83-4.31-1.39-1.53-3.28-2.09-4.21-1.24Z"/>
          <path class="cls-21" d="M50.65,93c-.93,.84-.56,2.77,.84,4.3,1.39,1.53,3.27,2.09,4.2,1.24s.56-2.77-.83-4.3c-1.39-1.53-3.27-2.09-4.2-1.24Z"/>
          <path class="cls-130" d="M50.66,93.01c-.93,.84-.56,2.77,.83,4.3,1.39,1.53,3.27,2.08,4.2,1.24,.93-.84,.56-2.77-.83-4.3-1.39-1.53-3.27-2.08-4.2-1.24Z"/>
          <path class="cls-80" d="M50.66,93.01c-.93,.84-.56,2.76,.83,4.29,1.39,1.53,3.26,2.08,4.19,1.24,.93-.84,.56-2.77-.83-4.29-1.39-1.53-3.27-2.08-4.19-1.24Z"/>
          <path class="cls-94" d="M50.66,93.01c-.93,.84-.55,2.76,.83,4.29,1.39,1.53,3.26,2.08,4.19,1.24,.93-.84,.55-2.76-.83-4.29-1.39-1.53-3.26-2.08-4.19-1.24Z"/>
          <path class="cls-163" d="M50.66,93.02c-.93,.84-.55,2.76,.83,4.28,1.38,1.52,3.26,2.08,4.18,1.24,.93-.84,.55-2.76-.83-4.28-1.38-1.52-3.26-2.08-4.18-1.24Z"/>
          <path class="cls-91" d="M50.67,93.02c-.92,.84-.55,2.75,.83,4.28s3.25,2.07,4.18,1.23c.92-.84,.55-2.75-.83-4.28-1.38-1.52-3.25-2.07-4.18-1.23Z"/>
          <path class="cls-204" d="M50.67,93.02c-.92,.84-.55,2.75,.83,4.27,1.38,1.52,3.25,2.07,4.17,1.23,.92-.84,.55-2.75-.83-4.27-1.38-1.52-3.25-2.07-4.17-1.23Z"/>
          <path class="cls-35" d="M50.67,93.03c-.92,.84-.55,2.75,.83,4.27,1.38,1.52,3.25,2.07,4.17,1.23,.92-.84,.55-2.75-.83-4.27-1.38-1.52-3.25-2.07-4.17-1.23Z"/>
          <path class="cls-126" d="M50.68,93.03c-.92,.84-.55,2.74,.83,4.26,1.38,1.52,3.24,2.07,4.16,1.23,.92-.84,.55-2.75-.83-4.26-1.38-1.52-3.24-2.07-4.16-1.23Z"/>
          <path class="cls-201" d="M50.68,93.03c-.92,.84-.55,2.74,.83,4.26,1.37,1.51,3.24,2.06,4.16,1.23,.92-.84,.55-2.74-.83-4.25-1.38-1.51-3.24-2.06-4.16-1.23Z"/>
          <path class="cls-170" d="M50.68,93.04c-.92,.84-.55,2.74,.82,4.25,1.37,1.51,3.23,2.06,4.15,1.23,.92-.84,.55-2.74-.82-4.25-1.37-1.51-3.23-2.06-4.15-1.23Z"/>
          <path class="cls-75" d="M50.69,93.04c-.92,.83-.55,2.73,.82,4.25,1.37,1.51,3.23,2.06,4.15,1.22s.55-2.73-.82-4.25c-1.37-1.51-3.23-2.06-4.15-1.22Z"/>
          <path class="cls-121" d="M50.69,93.04c-.92,.83-.55,2.73,.82,4.24,1.37,1.51,3.23,2.06,4.14,1.22,.92-.83,.55-2.73-.82-4.24-1.37-1.51-3.23-2.06-4.14-1.22Z"/>
          <path class="cls-78" d="M50.69,93.05c-.92,.83-.55,2.73,.82,4.23,1.37,1.51,3.22,2.05,4.14,1.22,.92-.83,.55-2.73-.82-4.23-1.37-1.51-3.22-2.05-4.14-1.22Z"/>
          <path class="cls-107" d="M50.7,93.05c-.91,.83-.55,2.72,.82,4.23s3.22,2.05,4.13,1.22c.91-.83,.55-2.72-.82-4.23-1.37-1.5-3.22-2.05-4.13-1.22Z"/>
          <path class="cls-202" d="M50.7,93.05c-.91,.83-.55,2.72,.82,4.22,1.37,1.5,3.21,2.05,4.13,1.22,.91-.83,.55-2.72-.82-4.22-1.37-1.5-3.21-2.05-4.13-1.22Z"/>
          <path class="cls-42" d="M50.7,93.06c-.91,.83-.55,2.72,.82,4.22,1.36,1.5,3.21,2.05,4.12,1.22,.91-.83,.55-2.72-.82-4.22-1.36-1.5-3.21-2.05-4.12-1.22Z"/>
          <path class="cls-5" d="M50.7,93.06c-.91,.83-.54,2.71,.82,4.21,1.36,1.5,3.2,2.04,4.12,1.21,.91-.83,.55-2.71-.82-4.21-1.36-1.5-3.2-2.04-4.12-1.21Z"/>
          <path class="cls-2" d="M50.71,93.06c-.91,.83-.54,2.71,.82,4.21,1.36,1.5,3.2,2.04,4.11,1.21,.91-.83,.54-2.71-.82-4.21-1.36-1.5-3.2-2.04-4.11-1.21Z"/>
          <path class="cls-191" d="M50.71,93.07c-.91,.83-.54,2.71,.81,4.2,1.36,1.5,3.2,2.04,4.11,1.21,.91-.83,.54-2.71-.81-4.2-1.36-1.5-3.2-2.04-4.11-1.21Z"/>
          <path class="cls-112" d="M50.71,93.07c-.91,.83-.54,2.7,.81,4.2,1.36,1.49,3.19,2.04,4.1,1.21,.91-.83,.54-2.7-.81-4.2-1.36-1.49-3.19-2.04-4.1-1.21Z"/>
          <path class="cls-101" d="M50.72,93.07c-.91,.82-.54,2.7,.81,4.19,1.36,1.49,3.19,2.03,4.1,1.21,.91-.82,.54-2.7-.81-4.19-1.36-1.49-3.19-2.03-4.1-1.21Z"/>
          <path class="cls-136" d="M50.72,93.08c-.91,.82-.54,2.7,.81,4.19,1.35,1.49,3.18,2.03,4.09,1.21,.91-.82,.54-2.7-.81-4.19-1.35-1.49-3.18-2.03-4.09-1.21Z"/>
          <path class="cls-100" d="M50.72,93.08c-.9,.82-.54,2.69,.81,4.18,1.35,1.49,3.18,2.03,4.08,1.21,.9-.82,.54-2.69-.81-4.18-1.35-1.49-3.18-2.03-4.08-1.21Z"/>
          <path class="cls-103" d="M50.73,93.08c-.9,.82-.54,2.69,.81,4.18,1.35,1.49,3.18,2.02,4.08,1.2,.9-.82,.54-2.69-.81-4.18-1.35-1.49-3.18-2.03-4.08-1.21Z"/>
          <path class="cls-71" d="M50.73,93.09c-.9,.82-.54,2.69,.81,4.17,1.35,1.48,3.17,2.02,4.07,1.2,.9-.82,.54-2.69-.81-4.17-1.35-1.48-3.17-2.02-4.07-1.2Z"/>
          <path class="cls-189" d="M50.73,93.09c-.9,.82-.54,2.68,.81,4.17,1.35,1.48,3.17,2.02,4.07,1.2,.9-.82,.54-2.68-.81-4.17-1.35-1.48-3.17-2.02-4.07-1.2Z"/>
          <path class="cls-1" d="M50.74,93.09c-.9,.82-.54,2.68,.81,4.16,1.34,1.48,3.17,2.02,4.06,1.2,.9-.82,.54-2.68-.81-4.16-1.34-1.48-3.16-2.02-4.06-1.2Z"/>
          <path class="cls-85" d="M50.74,93.1c-.9,.82-.54,2.68,.81,4.16,1.34,1.48,3.16,2.01,4.06,1.2,.9-.82,.54-2.68-.81-4.16-1.34-1.48-3.16-2.01-4.06-1.2Z"/>
          <path class="cls-160" d="M50.74,93.1c-.9,.82-.54,2.67,.8,4.15,1.34,1.48,3.16,2.01,4.05,1.2,.9-.82,.54-2.67-.8-4.15-1.34-1.48-3.16-2.01-4.05-1.2Z"/>
          <path class="cls-175" d="M50.75,93.1c-.9,.81-.54,2.67,.8,4.14,1.34,1.47,3.15,2.01,4.05,1.2,.9-.81,.54-2.67-.8-4.14-1.34-1.47-3.15-2.01-4.05-1.2Z"/>
          <path class="cls-123" d="M50.75,93.11c-.9,.81-.54,2.67,.8,4.14,1.34,1.47,3.15,2.01,4.04,1.19,.9-.81,.54-2.67-.8-4.14-1.34-1.47-3.15-2.01-4.04-1.19Z"/>
          <path class="cls-16" d="M50.75,93.11c-.89,.81-.54,2.66,.8,4.13,1.34,1.47,3.14,2,4.04,1.19,.89-.81,.54-2.66-.8-4.13-1.34-1.47-3.14-2-4.04-1.19Z"/>
          <path class="cls-15" d="M50.75,93.11c-.89,.81-.53,2.66,.8,4.13,1.33,1.47,3.14,2,4.03,1.19,.89-.81,.53-2.66-.8-4.13-1.33-1.47-3.14-2-4.03-1.19Z"/>
          <path class="cls-38" d="M50.76,93.12c-.89,.81-.53,2.66,.8,4.12,1.33,1.47,3.14,2,4.03,1.19,.89-.81,.53-2.66-.8-4.12s-3.14-2-4.03-1.19Z"/>
          <path class="cls-90" d="M50.76,93.12c-.89,.81-.53,2.65,.8,4.12,1.33,1.46,3.13,2,4.02,1.19,.89-.81,.53-2.65-.8-4.12-1.33-1.47-3.13-2-4.02-1.19Z"/>
          <path class="cls-74" d="M50.76,93.12c-.89,.81-.53,2.65,.8,4.11,1.33,1.46,3.13,1.99,4.02,1.19,.89-.81,.53-2.65-.8-4.11-1.33-1.46-3.13-1.99-4.02-1.19Z"/>
          <path class="cls-186" d="M50.77,93.13c-.89,.81-.53,2.65,.8,4.11,1.33,1.46,3.12,1.99,4.01,1.18,.89-.81,.53-2.65-.8-4.11-1.33-1.46-3.12-1.99-4.01-1.18Z"/>
          <path class="cls-193" d="M50.77,93.13c-.89,.81-.53,2.64,.79,4.1,1.33,1.46,3.12,1.99,4.01,1.18,.89-.81,.53-2.64-.79-4.1-1.33-1.46-3.12-1.99-4.01-1.18Z"/>
          <path class="cls-185" d="M50.77,93.14c-.89,.8-.53,2.64,.79,4.1,1.33,1.46,3.12,1.99,4,1.18,.89-.81,.53-2.64-.8-4.1-1.32-1.46-3.12-1.99-4-1.18Z"/>
          <path class="cls-111" d="M50.78,93.14c-.88,.8-.53,2.64,.79,4.09,1.32,1.46,3.11,1.98,4,1.18,.88-.8,.53-2.64-.79-4.09-1.32-1.45-3.11-1.98-4-1.18Z"/>
          <path class="cls-58" d="M50.78,93.14c-.88,.8-.53,2.63,.79,4.09,1.32,1.45,3.11,1.98,3.99,1.18,.88-.8,.53-2.63-.79-4.09-1.32-1.45-3.11-1.98-3.99-1.18Z"/>
          <path class="cls-69" d="M50.78,93.15c-.88,.8-.53,2.63,.79,4.08,1.32,1.45,3.1,1.98,3.99,1.18,.88-.8,.53-2.63-.79-4.08-1.32-1.45-3.1-1.98-3.99-1.18Z"/>
          <path class="cls-73" d="M50.79,93.15c-.88,.8-.53,2.63,.79,4.08,1.32,1.45,3.1,1.98,3.98,1.18,.88-.8,.53-2.63-.79-4.08-1.32-1.45-3.1-1.98-3.98-1.18Z"/>
          <path class="cls-168" d="M50.79,93.15c-.88,.8-.53,2.62,.79,4.07,1.32,1.45,3.1,1.97,3.98,1.17,.88-.8,.53-2.62-.79-4.07-1.32-1.45-3.1-1.97-3.98-1.17Z"/>
          <path class="cls-87" d="M50.79,93.16c-.88,.8-.53,2.62,.79,4.07,1.31,1.45,3.09,1.97,3.97,1.17,.88-.8,.53-2.62-.79-4.07-1.31-1.45-3.09-1.97-3.97-1.17Z"/>
          <path class="cls-4" d="M50.79,93.16c-.88,.8-.53,2.62,.79,4.06,1.31,1.44,3.09,1.97,3.97,1.17,.88-.8,.53-2.62-.79-4.06-1.31-1.44-3.09-1.97-3.97-1.17Z"/>
          <path class="cls-128" d="M50.8,93.16c-.88,.8-.52,2.61,.79,4.05,1.31,1.44,3.08,1.97,3.96,1.17,.88-.8,.53-2.61-.79-4.05-1.31-1.44-3.08-1.97-3.96-1.17Z"/>
          <path class="cls-62" d="M50.8,93.17c-.87,.8-.52,2.61,.79,4.05,1.31,1.44,3.08,1.96,3.96,1.17,.88-.8,.52-2.61-.79-4.05-1.31-1.44-3.08-1.96-3.96-1.17Z"/>
          <path class="cls-114" d="M50.8,93.17c-.87,.8-.52,2.61,.78,4.05,1.31,1.44,3.08,1.96,3.95,1.17,.87-.79,.52-2.61-.78-4.04-1.31-1.44-3.08-1.96-3.95-1.17Z"/>
          <path class="cls-41" d="M50.81,93.17c-.87,.79-.52,2.6,.78,4.04,1.31,1.44,3.07,1.96,3.95,1.16,.87-.79,.52-2.6-.78-4.04-1.31-1.44-3.07-1.96-3.95-1.17Z"/>
          <path class="cls-72" d="M50.81,93.18c-.87,.79-.52,2.6,.78,4.03,1.3,1.43,3.07,1.95,3.94,1.16,.87-.79,.52-2.6-.78-4.03-1.3-1.44-3.07-1.96-3.94-1.16Z"/>
          <path class="cls-52" d="M50.81,93.18c-.87,.79-.52,2.59,.78,4.03,1.3,1.43,3.06,1.95,3.94,1.16,.87-.79,.52-2.59-.78-4.03-1.3-1.43-3.06-1.95-3.94-1.16Z"/>
          <path class="cls-147" d="M50.82,93.18c-.87,.79-.52,2.59,.78,4.02,1.3,1.43,3.06,1.95,3.93,1.16,.87-.79,.52-2.59-.78-4.02-1.3-1.43-3.06-1.95-3.93-1.16Z"/>
          <path class="cls-56" d="M50.82,93.19c-.87,.79-.52,2.59,.78,4.02s3.06,1.95,3.92,1.16c.87-.79,.52-2.59-.78-4.02-1.3-1.43-3.06-1.95-3.93-1.16Z"/>
          <path class="cls-86" d="M50.82,93.19c-.87,.79-.52,2.58,.78,4.01,1.3,1.43,3.05,1.95,3.92,1.16,.87-.79,.52-2.59-.78-4.01-1.3-1.43-3.05-1.95-3.92-1.16Z"/>
          <path class="cls-134" d="M50.83,93.19c-.87,.79-.52,2.58,.78,4.01,1.3,1.43,3.05,1.94,3.91,1.16,.87-.79,.52-2.58-.78-4.01-1.29-1.43-3.05-1.94-3.91-1.16Z"/>
          <path class="cls-96" d="M50.83,93.2c-.87,.79-.52,2.58,.78,4,1.29,1.42,3.04,1.94,3.91,1.15,.87-.79,.52-2.58-.78-4-1.29-1.42-3.04-1.94-3.91-1.15Z"/>
          <path class="cls-150" d="M50.83,93.2c-.86,.79-.52,2.57,.78,4,1.29,1.42,3.04,1.94,3.9,1.15,.86-.79,.52-2.57-.78-4-1.29-1.42-3.04-1.94-3.9-1.15Z"/>
          <path class="cls-81" d="M50.83,93.2c-.86,.78-.52,2.57,.77,3.99,1.29,1.42,3.04,1.94,3.9,1.15,.86-.78,.52-2.57-.77-3.99-1.29-1.42-3.04-1.94-3.9-1.15Z"/>
          <path class="cls-97" d="M50.84,93.21c-.86,.78-.52,2.57,.77,3.99,1.29,1.42,3.03,1.93,3.89,1.15,.86-.78,.52-2.57-.77-3.99-1.29-1.42-3.03-1.93-3.89-1.15Z"/>
          <path class="cls-194" d="M50.84,93.21c-.86,.78-.52,2.56,.77,3.98,1.29,1.42,3.03,1.93,3.89,1.15,.86-.78,.52-2.56-.77-3.98-1.29-1.42-3.03-1.93-3.89-1.15Z"/>
          <path class="cls-55" d="M50.84,93.21c-.86,.78-.51,2.56,.77,3.98,1.29,1.41,3.02,1.93,3.88,1.15,.86-.78,.51-2.56-.77-3.97-1.29-1.41-3.02-1.93-3.88-1.15Z"/>
          <path class="cls-105" d="M50.85,93.22c-.86,.78-.51,2.56,.77,3.97,1.28,1.41,3.02,1.92,3.88,1.14,.86-.78,.51-2.56-.77-3.97-1.28-1.41-3.02-1.93-3.88-1.15Z"/>
          <path class="cls-9" d="M50.85,93.22c-.86,.78-.51,2.55,.77,3.96,1.28,1.41,3.02,1.92,3.87,1.14,.86-.78,.51-2.55-.77-3.96-1.28-1.41-3.02-1.92-3.87-1.14Z"/>
          <path class="cls-3" d="M50.85,93.22c-.86,.78-.51,2.55,.77,3.96,1.28,1.41,3.01,1.92,3.87,1.14,.86-.78,.51-2.55-.77-3.96-1.28-1.41-3.01-1.92-3.87-1.14Z"/>
          <path class="cls-22" d="M50.86,93.23c-.86,.78-.51,2.55,.77,3.95,1.28,1.41,3.01,1.92,3.86,1.14,.86-.78,.51-2.55-.77-3.96-1.28-1.41-3.01-1.92-3.86-1.14Z"/>
          <path class="cls-196" d="M50.86,93.23c-.85,.78-.51,2.54,.77,3.95,1.28,1.41,3,1.91,3.86,1.14,.85-.78,.51-2.54-.77-3.95-1.28-1.4-3-1.91-3.86-1.14Z"/>
          <path class="cls-110" d="M50.86,93.23c-.85,.77-.51,2.54,.76,3.94,1.27,1.4,3,1.91,3.85,1.14,.85-.78,.51-2.54-.76-3.94s-3-1.91-3.85-1.14Z"/>
          <path class="cls-116" d="M50.87,93.24c-.85,.77-.51,2.54,.76,3.94,1.27,1.4,3,1.91,3.85,1.14,.85-.77,.51-2.54-.76-3.94-1.27-1.4-3-1.91-3.85-1.14Z"/>
          <path class="cls-124" d="M50.87,93.24c-.85,.77-.51,2.53,.76,3.93,1.27,1.4,2.99,1.91,3.84,1.13,.85-.77,.51-2.53-.76-3.93s-2.99-1.91-3.84-1.13Z"/>
          <path class="cls-18" d="M50.87,93.24c-.85,.77-.51,2.53,.76,3.93,1.27,1.4,2.99,1.91,3.84,1.13,.85-.77,.51-2.53-.76-3.93-1.27-1.4-2.99-1.9-3.84-1.13Z"/>
          <path class="cls-57" d="M50.87,93.25c-.85,.77-.51,2.53,.76,3.92,1.27,1.4,2.98,1.9,3.83,1.13,.85-.77,.51-2.53-.76-3.92-1.27-1.4-2.98-1.9-3.83-1.13Z"/>
          <path class="cls-148" d="M50.88,93.25c-.85,.77-.51,2.52,.76,3.92,1.27,1.39,2.98,1.9,3.83,1.13,.85-.77,.51-2.52-.76-3.92-1.27-1.39-2.98-1.9-3.83-1.13Z"/>
          <path class="cls-113" d="M50.88,93.25c-.85,.77-.51,2.52,.76,3.91,1.26,1.39,2.98,1.9,3.82,1.13,.85-.77,.51-2.52-.76-3.91-1.26-1.39-2.98-1.9-3.82-1.13Z"/>
          <path class="cls-125" d="M50.88,93.26c-.84,.77-.51,2.52,.76,3.91,1.26,1.39,2.97,1.89,3.82,1.13,.84-.77,.51-2.52-.76-3.91-1.26-1.39-2.97-1.89-3.82-1.13Z"/>
          <path class="cls-209" d="M50.89,93.26c-.84,.77-.5,2.51,.76,3.9,1.26,1.39,2.97,1.89,3.81,1.12,.84-.77,.5-2.51-.76-3.9-1.26-1.39-2.97-1.89-3.81-1.13Z"/>
          <path class="cls-142" d="M50.89,93.26c-.84,.77-.5,2.51,.76,3.9,1.26,1.39,2.96,1.89,3.81,1.12,.84-.77,.5-2.51-.75-3.9-1.26-1.39-2.96-1.89-3.81-1.12Z"/>
          <path class="cls-183" d="M50.89,93.27c-.84,.76-.5,2.51,.75,3.89,1.26,1.38,2.96,1.89,3.8,1.12,.84-.76,.5-2.51-.75-3.89-1.26-1.38-2.96-1.89-3.8-1.12Z"/>
          <path class="cls-127" d="M50.9,93.27c-.84,.76-.5,2.5,.75,3.89,1.26,1.38,2.96,1.88,3.8,1.12,.84-.76,.5-2.5-.75-3.89-1.26-1.38-2.96-1.88-3.8-1.12Z"/>
          <path class="cls-10" d="M50.9,93.27c-.84,.76-.5,2.5,.75,3.88,1.25,1.38,2.95,1.88,3.79,1.12,.84-.76,.5-2.5-.75-3.88-1.25-1.38-2.95-1.88-3.79-1.12Z"/>
          <path class="cls-149" d="M50.9,93.28c-.84,.76-.5,2.5,.75,3.88,1.25,1.38,2.95,1.88,3.79,1.12,.84-.76,.5-2.5-.75-3.88-1.25-1.38-2.95-1.88-3.79-1.12Z"/>
          <path class="cls-95" d="M50.91,93.28c-.84,.76-.5,2.49,.75,3.87,1.25,1.38,2.94,1.88,3.78,1.12,.84-.76,.5-2.49-.75-3.87-1.25-1.38-2.94-1.88-3.78-1.12Z"/>
          <path class="cls-102" d="M50.91,93.28c-.83,.76-.5,2.49,.75,3.86,1.25,1.38,2.94,1.87,3.78,1.11,.84-.76,.5-2.49-.75-3.86-1.25-1.38-2.94-1.87-3.78-1.11Z"/>
          <path class="cls-106" d="M50.91,93.29c-.83,.76-.5,2.49,.75,3.86,1.25,1.37,2.94,1.87,3.77,1.11,.83-.76,.5-2.49-.75-3.86-1.25-1.37-2.94-1.87-3.77-1.11Z"/>
          <path class="cls-109" d="M50.92,93.29c-.83,.76-.5,2.48,.75,3.85s2.93,1.87,3.76,1.11c.83-.76,.5-2.48-.75-3.85s-2.93-1.87-3.76-1.11Z"/>
          <path class="cls-119" d="M50.92,93.29c-.83,.76-.5,2.48,.75,3.85,1.24,1.37,2.93,1.87,3.76,1.11,.83-.76,.5-2.48-.75-3.85-1.24-1.37-2.93-1.87-3.76-1.11Z"/>
          <path class="cls-66" d="M50.92,93.3c-.83,.75-.5,2.48,.75,3.84,1.24,1.37,2.92,1.86,3.75,1.11,.83-.75,.5-2.48-.75-3.84-1.24-1.37-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-172" d="M50.92,93.3c-.83,.75-.5,2.47,.74,3.84,1.24,1.37,2.92,1.86,3.75,1.11,.83-.75,.5-2.47-.74-3.84-1.24-1.37-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-178" d="M50.93,93.31c-.83,.75-.5,2.47,.74,3.83,1.24,1.36,2.92,1.86,3.74,1.1,.83-.75,.5-2.47-.74-3.83-1.24-1.36-2.92-1.86-3.75-1.11Z"/>
          <path class="cls-93" d="M50.93,93.31c-.83,.75-.5,2.47,.74,3.83,1.24,1.36,2.91,1.86,3.74,1.1,.83-.75,.5-2.47-.74-3.83-1.24-1.36-2.91-1.86-3.74-1.1Z"/>
          <path class="cls-65" d="M50.93,93.31c-.83,.75-.5,2.46,.74,3.82,1.24,1.36,2.91,1.85,3.73,1.1,.83-.75,.5-2.46-.74-3.82-1.24-1.36-2.91-1.85-3.73-1.1Z"/>
          <path class="cls-164" d="M50.94,93.32c-.83,.75-.49,2.46,.74,3.82,1.23,1.36,2.9,1.85,3.73,1.1s.49-2.46-.74-3.82c-1.23-1.36-2.9-1.85-3.73-1.1Z"/>
          <path class="cls-174" d="M50.94,93.32c-.82,.75-.49,2.46,.74,3.81,1.23,1.36,2.9,1.85,3.72,1.1,.82-.75,.49-2.46-.74-3.81-1.23-1.36-2.9-1.85-3.72-1.1Z"/>
          <path class="cls-92" d="M50.94,93.32c-.82,.75-.49,2.45,.74,3.81s2.9,1.85,3.72,1.1c.82-.75,.49-2.45-.74-3.81-1.23-1.35-2.9-1.85-3.72-1.1Z"/>
          <path class="cls-203" d="M50.95,93.33c-.82,.75-.49,2.45,.74,3.8,1.23,1.35,2.89,1.84,3.71,1.1,.82-.75,.49-2.45-.74-3.8-1.23-1.35-2.89-1.84-3.71-1.1Z"/>
          <path class="cls-144" d="M50.95,93.33c-.82,.75-.49,2.45,.74,3.8s2.89,1.84,3.71,1.09c.82-.75,.49-2.45-.74-3.8-1.23-1.35-2.89-1.84-3.71-1.09Z"/>
          <path class="cls-195" d="M50.95,93.33c-.82,.74-.49,2.44,.73,3.79,1.23,1.35,2.88,1.84,3.7,1.09,.82-.74,.49-2.44-.73-3.79-1.23-1.35-2.88-1.84-3.7-1.09Z"/>
          <path class="cls-140" d="M50.96,93.34c-.82,.74-.49,2.44,.73,3.79s2.88,1.84,3.7,1.09c.82-.74,.49-2.44-.73-3.79-1.22-1.35-2.88-1.83-3.7-1.09Z"/>
          <path class="cls-120" d="M50.96,93.34c-.82,.74-.49,2.43,.73,3.78,1.22,1.34,2.88,1.83,3.69,1.09,.82-.74,.49-2.44-.73-3.78-1.22-1.34-2.88-1.83-3.69-1.09Z"/>
          <path class="cls-173" d="M50.96,93.34c-.82,.74-.49,2.43,.73,3.77,1.22,1.34,2.87,1.83,3.69,1.09,.82-.74,.49-2.43-.73-3.77-1.22-1.34-2.87-1.83-3.69-1.09Z"/>
          <path class="cls-79" d="M50.96,93.35c-.81,.74-.49,2.43,.73,3.77,1.22,1.34,2.87,1.83,3.68,1.09,.82-.74,.49-2.43-.73-3.77-1.22-1.34-2.87-1.83-3.68-1.09Z"/>
          <path class="cls-179" d="M50.97,93.35c-.81,.74-.49,2.42,.73,3.76,1.22,1.34,2.86,1.82,3.68,1.09,.81-.74,.49-2.42-.73-3.76-1.22-1.34-2.86-1.83-3.68-1.09Z"/>
          <path class="cls-179" d="M50.97,93.35c-.81,.74-.49,2.42,.73,3.76,1.21,1.34,2.86,1.82,3.67,1.08,.81-.74,.49-2.42-.73-3.76-1.21-1.34-2.86-1.82-3.67-1.08Z"/>
          <path class="cls-173" d="M50.98,93.36c-.81,.74-.49,2.41,.73,3.75,1.21,1.33,2.85,1.82,3.66,1.08,.81-.74,.49-2.41-.73-3.75-1.21-1.33-2.85-1.82-3.66-1.08Z"/>
          <path class="cls-140" d="M50.99,93.37c-.81,.73-.48,2.41,.72,3.74s2.84,1.81,3.65,1.08c.81-.73,.48-2.41-.72-3.74-1.21-1.33-2.84-1.81-3.65-1.08Z"/>
          <path class="cls-144" d="M50.99,93.38c-.81,.73-.48,2.4,.72,3.72,1.2,1.32,2.83,1.81,3.64,1.07,.81-.73,.48-2.4-.72-3.72-1.2-1.32-2.83-1.81-3.64-1.07Z"/>
          <path class="cls-115" d="M51,93.38c-.8,.73-.48,2.39,.72,3.71,1.2,1.32,2.82,1.8,3.63,1.07,.8-.73,.48-2.39-.72-3.71-1.2-1.32-2.82-1.8-3.63-1.07Z"/>
          <path class="cls-88" d="M51.01,93.39c-.8,.73-.48,2.38,.72,3.7,1.2,1.32,2.82,1.79,3.62,1.07,.8-.73,.48-2.38-.72-3.7-1.2-1.32-2.82-1.79-3.62-1.07Z"/>
          <path class="cls-162" d="M51.01,93.4c-.8,.72-.48,2.38,.71,3.69s2.81,1.79,3.6,1.06c.8-.73,.48-2.38-.71-3.69s-2.81-1.79-3.6-1.06Z"/>
          <path class="cls-133" d="M51.02,93.41c-.8,.72-.48,2.37,.71,3.68,1.19,1.31,2.8,1.78,3.59,1.06,.79-.72,.48-2.37-.71-3.68-1.19-1.31-2.8-1.78-3.59-1.06Z"/>
          <path class="cls-66" d="M51.03,93.41c-.79,.72-.47,2.36,.71,3.67,1.19,1.3,2.79,1.78,3.58,1.06,.79-.72,.47-2.36-.71-3.67-1.19-1.3-2.79-1.78-3.58-1.06Z"/>
          <path class="cls-109" d="M51.03,93.42c-.79,.72-.47,2.35,.71,3.65,1.18,1.3,2.78,1.77,3.57,1.05,.79-.72,.47-2.35-.71-3.65s-2.78-1.77-3.57-1.05Z"/>
          <path class="cls-43" d="M51.04,93.43c-.79,.72-.47,2.35,.71,3.64,1.18,1.3,2.77,1.77,3.56,1.05,.79-.72,.47-2.35-.71-3.64-1.18-1.3-2.77-1.77-3.56-1.05Z"/>
          <path class="cls-95" d="M51.05,93.44c-.79,.71-.47,2.34,.7,3.63,1.17,1.29,2.76,1.76,3.55,1.05,.79-.71,.47-2.34-.7-3.63s-2.76-1.76-3.55-1.05Z"/>
          <path class="cls-99" d="M51.05,93.44c-.78,.71-.47,2.33,.7,3.62,1.17,1.29,2.75,1.75,3.54,1.04,.78-.71,.47-2.33-.7-3.62-1.17-1.29-2.75-1.75-3.54-1.04Z"/>
          <path class="cls-181" d="M51.06,93.45c-.78,.71-.47,2.32,.7,3.61,1.17,1.28,2.75,1.75,3.52,1.04,.78-.71,.47-2.32-.7-3.61-1.17-1.28-2.74-1.75-3.52-1.04Z"/>
          <path class="cls-152" d="M51.07,93.46c-.78,.71-.46,2.32,.7,3.6,1.16,1.28,2.74,1.74,3.51,1.04,.78-.71,.47-2.32-.7-3.6-1.16-1.28-2.74-1.74-3.51-1.04Z"/>
          <path class="cls-125" d="M51.07,93.47c-.77,.7-.46,2.31,.7,3.58,1.16,1.28,2.73,1.74,3.5,1.03,.78-.7,.46-2.31-.69-3.58-1.16-1.28-2.73-1.74-3.5-1.03Z"/>
          <path class="cls-148" d="M51.08,93.47c-.77,.7-.46,2.3,.69,3.57,1.16,1.27,2.72,1.73,3.49,1.03,.77-.7,.46-2.3-.69-3.57-1.15-1.27-2.72-1.73-3.49-1.03Z"/>
          <path class="cls-68" d="M51.09,93.48c-.77,.7-.46,2.29,.69,3.56,1.15,1.27,2.71,1.73,3.48,1.03,.77-.7,.46-2.29-.69-3.56-1.15-1.27-2.71-1.73-3.48-1.03Z"/>
          <path class="cls-157" d="M51.09,93.49c-.77,.7-.46,2.29,.69,3.55,1.15,1.26,2.7,1.72,3.47,1.02,.77-.7,.46-2.29-.69-3.55-1.15-1.26-2.7-1.72-3.47-1.02Z"/>
          <path class="cls-143" d="M51.1,93.49c-.76,.7-.46,2.28,.69,3.54,1.14,1.26,2.69,1.72,3.46,1.02,.77-.7,.46-2.28-.69-3.54s-2.69-1.71-3.46-1.02Z"/>
          <path class="cls-196" d="M51.11,93.5c-.76,.69-.46,2.27,.68,3.53,1.14,1.25,2.68,1.71,3.45,1.02,.76-.69,.46-2.27-.68-3.53-1.14-1.25-2.68-1.71-3.45-1.02Z"/>
          <path class="cls-104" d="M51.11,93.51c-.76,.69-.46,2.26,.68,3.51,1.14,1.25,2.67,1.71,3.43,1.01,.76-.69,.45-2.26-.68-3.51-1.14-1.25-2.67-1.7-3.43-1.01Z"/>
          <path class="cls-105" d="M51.12,93.52c-.76,.69-.45,2.26,.68,3.5,1.13,1.25,2.66,1.7,3.42,1.01,.76-.69,.45-2.26-.68-3.5-1.13-1.25-2.66-1.7-3.42-1.01Z"/>
          <path class="cls-137" d="M51.13,93.53c-.75,.69-.45,2.25,.68,3.49,1.13,1.24,2.66,1.69,3.41,1.01,.75-.69,.45-2.25-.68-3.49-1.13-1.24-2.66-1.69-3.41-1.01Z"/>
          <path class="cls-167" d="M51.13,93.53c-.75,.68-.45,2.24,.67,3.48,1.12,1.24,2.65,1.69,3.4,1,.75-.68,.45-2.24-.67-3.48-1.12-1.24-2.65-1.69-3.4-1Z"/>
          <path class="cls-67" d="M51.14,93.54c-.75,.68-.45,2.23,.67,3.47,1.12,1.23,2.64,1.68,3.39,1,.75-.68,.45-2.23-.67-3.47-1.12-1.23-2.64-1.68-3.39-1Z"/>
          <path class="cls-96" d="M51.15,93.55c-.75,.68-.45,2.23,.67,3.46,1.12,1.23,2.63,1.68,3.38,1,.75-.68,.45-2.23-.67-3.46-1.12-1.23-2.63-1.68-3.38-1Z"/>
          <path class="cls-134" d="M51.16,93.56c-.75,.68-.45,2.22,.67,3.44,1.11,1.23,2.62,1.67,3.37,.99,.74-.68,.45-2.22-.67-3.44-1.11-1.23-2.62-1.67-3.37-.99Z"/>
          <path class="cls-56" d="M51.16,93.56c-.74,.67-.44,2.21,.67,3.43,1.11,1.22,2.61,1.66,3.35,.99,.74-.67,.44-2.21-.67-3.43-1.11-1.22-2.61-1.66-3.35-.99Z"/>
          <path class="cls-132" d="M51.17,93.57c-.74,.67-.44,2.2,.66,3.42,1.11,1.22,2.6,1.66,3.34,.99,.74-.67,.44-2.2-.66-3.42-1.11-1.22-2.6-1.66-3.34-.99Z"/>
          <path class="cls-118" d="M51.18,93.58c-.74,.67-.44,2.2,.66,3.41,1.1,1.21,2.59,1.65,3.33,.98s.44-2.2-.66-3.41c-1.1-1.21-2.59-1.65-3.33-.98Z"/>
          <path class="cls-155" d="M51.18,93.59c-.74,.67-.44,2.19,.66,3.4,1.1,1.21,2.58,1.65,3.32,.98,.73-.67,.44-2.19-.66-3.4-1.1-1.21-2.58-1.65-3.32-.98Z"/>
          <path class="cls-122" d="M51.19,93.59c-.73,.67-.44,2.18,.66,3.39,1.09,1.21,2.58,1.64,3.31,.98,.73-.67,.44-2.18-.66-3.39-1.1-1.2-2.58-1.64-3.31-.98Z"/>
          <path class="cls-128" d="M51.2,93.6c-.73,.66-.44,2.17,.65,3.38,1.09,1.2,2.57,1.64,3.3,.97s.44-2.17-.65-3.37c-1.09-1.2-2.57-1.64-3.3-.97Z"/>
          <path class="cls-158" d="M51.2,93.61c-.73,.66-.44,2.17,.65,3.36,1.09,1.2,2.56,1.63,3.29,.97,.73-.66,.44-2.17-.65-3.36-1.09-1.2-2.56-1.63-3.29-.97Z"/>
          <path class="cls-168" d="M51.21,93.61c-.72,.66-.43,2.16,.65,3.35,1.08,1.19,2.55,1.62,3.27,.97,.72-.66,.43-2.16-.65-3.35-1.08-1.19-2.55-1.62-3.27-.97Z"/>
          <path class="cls-73" d="M51.22,93.62c-.72,.66-.43,2.15,.65,3.34,1.08,1.19,2.54,1.62,3.26,.96,.72-.66,.43-2.15-.65-3.34-1.08-1.19-2.54-1.62-3.26-.96Z"/>
          <path class="cls-58" d="M51.22,93.63c-.72,.65-.43,2.14,.64,3.33,1.08,1.18,2.53,1.61,3.25,.96,.72-.65,.43-2.14-.65-3.33-1.08-1.18-2.53-1.61-3.25-.96Z"/>
          <path class="cls-184" d="M51.23,93.64c-.72,.65-.43,2.14,.64,3.32,1.07,1.18,2.52,1.61,3.24,.96,.72-.65,.43-2.14-.64-3.32-1.07-1.18-2.52-1.61-3.24-.96Z"/>
          <path class="cls-185" d="M51.24,93.65c-.71,.65-.43,2.13,.64,3.3,1.07,1.18,2.51,1.6,3.23,.95s.43-2.13-.64-3.3c-1.07-1.18-2.51-1.6-3.23-.95Z"/>
          <path class="cls-186" d="M51.24,93.65c-.71,.65-.43,2.12,.64,3.29,1.07,1.17,2.51,1.6,3.22,.95,.71-.65,.43-2.12-.64-3.29-1.06-1.17-2.5-1.6-3.22-.95Z"/>
          <path class="cls-74" d="M51.25,93.66c-.71,.65-.42,2.11,.64,3.28,1.06,1.17,2.5,1.59,3.21,.95,.71-.65,.42-2.11-.64-3.28-1.06-1.17-2.5-1.59-3.21-.95Z"/>
          <path class="cls-90" d="M51.26,93.67c-.71,.64-.42,2.11,.63,3.27,1.06,1.16,2.49,1.58,3.19,.94,.71-.64,.42-2.11-.63-3.27-1.06-1.16-2.49-1.59-3.19-.94Z"/>
          <path class="cls-15" d="M51.26,93.68c-.71,.64-.42,2.1,.63,3.26,1.05,1.16,2.48,1.58,3.18,.94,.7-.64,.42-2.1-.63-3.26-1.05-1.16-2.48-1.58-3.18-.94Z"/>
          <path class="cls-156" d="M51.27,93.68c-.7,.64-.42,2.09,.63,3.25,1.05,1.16,2.47,1.57,3.17,.94,.7-.64,.42-2.09-.63-3.25-1.05-1.16-2.47-1.57-3.17-.94Z"/>
          <path class="cls-123" d="M51.28,93.69c-.7,.64-.42,2.08,.63,3.24,1.05,1.15,2.46,1.57,3.16,.93,.7-.64,.42-2.08-.63-3.24-1.05-1.15-2.46-1.57-3.16-.93Z"/>
          <path class="cls-175" d="M51.29,93.7c-.7,.63-.42,2.08,.62,3.22,1.04,1.15,2.45,1.56,3.15,.93,.7-.63,.42-2.08-.62-3.22-1.04-1.15-2.45-1.56-3.15-.93Z"/>
          <path class="cls-188" d="M51.29,93.7c-.7,.63-.42,2.07,.62,3.21s2.44,1.56,3.14,.93c.69-.63,.42-2.07-.62-3.21-1.04-1.14-2.44-1.56-3.14-.93Z"/>
          <path class="cls-1" d="M51.3,93.71c-.69,.63-.42,2.06,.62,3.2,1.03,1.14,2.43,1.55,3.13,.92,.69-.63,.41-2.06-.62-3.2-1.04-1.14-2.43-1.55-3.13-.92Z"/>
          <path class="cls-189" d="M51.31,93.72c-.69,.63-.41,2.05,.62,3.19,1.03,1.13,2.42,1.55,3.11,.92,.69-.63,.41-2.05-.62-3.19-1.03-1.13-2.42-1.55-3.11-.92Z"/>
          <path class="cls-71" d="M51.31,93.73c-.69,.62-.41,2.05,.62,3.18,1.03,1.13,2.42,1.54,3.1,.92,.69-.62,.41-2.05-.62-3.18-1.03-1.13-2.42-1.54-3.1-.92Z"/>
          <path class="cls-103" d="M51.32,93.74c-.68,.62-.41,2.04,.61,3.17,1.02,1.13,2.41,1.54,3.09,.91,.68-.62,.41-2.04-.61-3.17-1.02-1.13-2.41-1.54-3.09-.91Z"/>
          <path class="cls-76" d="M51.33,93.74c-.68,.62-.41,2.03,.61,3.15,1.02,1.12,2.4,1.53,3.08,.91,.68-.62,.41-2.03-.61-3.15-1.02-1.12-2.4-1.53-3.08-.91Z"/>
          <path class="cls-136" d="M51.33,93.75c-.68,.62-.41,2.02,.61,3.14,1.02,1.12,2.39,1.52,3.07,.91,.68-.62,.41-2.02-.61-3.14-1.02-1.12-2.39-1.52-3.07-.91Z"/>
          <path class="cls-154" d="M51.34,93.76c-.68,.62-.41,2.02,.61,3.13,1.01,1.11,2.38,1.52,3.06,.9,.68-.62,.41-2.02-.61-3.13-1.01-1.11-2.38-1.52-3.06-.9Z"/>
          <path class="cls-112" d="M51.35,93.77c-.67,.61-.4,2.01,.6,3.12,1.01,1.11,2.37,1.51,3.05,.9,.67-.61,.4-2.01-.6-3.12-1.01-1.11-2.37-1.51-3.05-.9Z"/>
          <path class="cls-191" d="M51.35,93.77c-.67,.61-.4,2,.6,3.11,1,1.11,2.36,1.51,3.04,.9,.67-.61,.4-2-.6-3.11-1-1.11-2.36-1.51-3.04-.9Z"/>
          <path class="cls-82" d="M51.36,93.78c-.67,.61-.4,1.99,.6,3.1,1,1.1,2.35,1.5,3.02,.89,.67-.61,.4-1.99-.6-3.1-1-1.1-2.35-1.5-3.02-.89Z"/>
          <path class="cls-5" d="M51.37,93.79c-.67,.61-.4,1.99,.6,3.08,1,1.1,2.34,1.5,3.01,.89,.67-.61,.4-1.99-.6-3.08-1-1.1-2.35-1.5-3.01-.89Z"/>
          <path class="cls-42" d="M51.37,93.8c-.66,.6-.4,1.98,.6,3.07,.99,1.09,2.34,1.49,3,.89,.66-.6,.4-1.98-.59-3.07-.99-1.09-2.34-1.49-3-.89Z"/>
          <path class="cls-207" d="M51.38,93.8c-.66,.6-.4,1.97,.59,3.06,.99,1.09,2.33,1.48,2.99,.88,.66-.6,.4-1.97-.59-3.06-.99-1.09-2.33-1.48-2.99-.88Z"/>
          <path class="cls-107" d="M51.39,93.81c-.66,.6-.39,1.96,.59,3.05,.99,1.08,2.32,1.48,2.98,.88,.66-.6,.4-1.96-.59-3.05-.99-1.08-2.32-1.48-2.98-.88Z"/>
          <path class="cls-78" d="M51.39,93.82c-.66,.6-.39,1.96,.59,3.04,.98,1.08,2.31,1.47,2.97,.88,.66-.6,.39-1.96-.59-3.04-.98-1.08-2.31-1.47-2.97-.88Z"/>
          <path class="cls-75" d="M51.4,93.82c-.65,.59-.39,1.95,.59,3.03,.98,1.08,2.3,1.47,2.95,.87,.65-.59,.39-1.95-.59-3.03-.98-1.08-2.3-1.47-2.96-.87Z"/>
          <path class="cls-20" d="M51.41,93.83c-.65,.59-.39,1.94,.58,3.01,.97,1.07,2.29,1.46,2.94,.87,.65-.59,.39-1.94-.58-3.01-.97-1.07-2.29-1.46-2.94-.87Z"/>
          <path class="cls-201" d="M51.41,93.84c-.65,.59-.39,1.93,.58,3,.97,1.07,2.28,1.46,2.93,.87,.65-.59,.39-1.93-.58-3-.97-1.07-2.28-1.45-2.93-.87Z"/>
          <path class="cls-201" d="M51.42,93.85c-.65,.59-.39,1.93,.58,2.99,.97,1.06,2.27,1.45,2.92,.86,.65-.59,.39-1.93-.58-2.99-.97-1.06-2.28-1.45-2.92-.86Z"/>
          <path class="cls-126" d="M51.43,93.86c-.64,.58-.39,1.92,.58,2.98,.96,1.06,2.27,1.44,2.91,.86,.64-.58,.38-1.92-.58-2.98-.96-1.06-2.27-1.44-2.91-.86Z"/>
          <path class="cls-35" d="M51.43,93.86c-.64,.58-.38,1.91,.58,2.97,.96,1.06,2.26,1.44,2.9,.86,.64-.58,.38-1.91-.58-2.97-.96-1.06-2.26-1.44-2.9-.86Z"/>
          <path class="cls-204" d="M51.44,93.87c-.64,.58-.38,1.9,.57,2.96,.96,1.05,2.25,1.43,2.89,.85,.64-.58,.38-1.9-.57-2.96-.96-1.05-2.25-1.43-2.89-.85Z"/>
          <path class="cls-89" d="M51.45,93.88c-.64,.58-.38,1.9,.57,2.94,.95,1.05,2.24,1.43,2.88,.85,.64-.58,.38-1.9-.57-2.94-.95-1.05-2.24-1.43-2.88-.85Z"/>
          <path class="cls-91" d="M51.45,93.89c-.63,.58-.38,1.89,.57,2.93,.95,1.04,2.23,1.42,2.87,.85,.63-.58,.38-1.89-.57-2.93-.95-1.04-2.23-1.42-2.87-.85Z"/>
          <path class="cls-94" d="M51.46,93.89c-.63,.57-.38,1.88,.57,2.92,.94,1.04,2.22,1.42,2.85,.84,.63-.57,.38-1.88-.57-2.92-.94-1.04-2.22-1.42-2.85-.84Z"/>
          <path class="cls-94" d="M51.47,93.9c-.63,.57-.38,1.87,.56,2.91,.94,1.04,2.21,1.41,2.84,.84s.38-1.87-.56-2.91c-.94-1.04-2.21-1.41-2.84-.84Z"/>
          <path class="cls-80" d="M51.48,93.91c-.63,.57-.37,1.87,.56,2.9,.94,1.03,2.2,1.4,2.83,.83,.63-.57,.37-1.87-.56-2.9-.94-1.03-2.21-1.4-2.83-.84Z"/>
          <path class="cls-141" d="M51.48,93.91c-.62,.57-.37,1.86,.56,2.89,.93,1.03,2.2,1.4,2.82,.83s.37-1.86-.56-2.89c-.93-1.03-2.2-1.4-2.82-.83Z"/>
          <path class="cls-130" d="M51.49,93.92c-.62,.57-.37,1.85,.56,2.87,.93,1.02,2.19,1.39,2.81,.83,.62-.56,.37-1.85-.56-2.87-.93-1.02-2.19-1.39-2.81-.83Z"/>
          <path class="cls-21" d="M51.5,93.93c-.62,.56-.37,1.84,.55,2.86,.93,1.02,2.18,1.39,2.8,.83,.62-.56,.37-1.84-.55-2.86-.93-1.02-2.18-1.39-2.8-.83Z"/>
          <path class="cls-21" d="M51.5,93.94c-.62,.56-.37,1.84,.55,2.85,.92,1.01,2.17,1.38,2.79,.82,.62-.56,.37-1.84-.55-2.85-.92-1.01-2.17-1.38-2.79-.82Z"/>
          <path class="cls-187" d="M51.51,93.95c-.61,.56-.37,1.83,.55,2.84,.92,1.01,2.16,1.38,2.77,.82,.61-.56,.37-1.83-.55-2.84-.92-1.01-2.16-1.38-2.77-.82Z"/>
          <path class="cls-192" d="M51.52,93.95c-.61,.56-.37,1.82,.55,2.83,.91,1.01,2.15,1.37,2.76,.82s.37-1.82-.55-2.83c-.92-1.01-2.15-1.37-2.76-.82Z"/>
          <path class="cls-192" d="M51.52,93.96c-.61,.55-.37,1.81,.55,2.82,.91,1,2.14,1.37,2.75,.81,.61-.55,.36-1.81-.55-2.82-.91-1-2.14-1.37-2.75-.81Z"/>
          <path class="cls-129" d="M51.53,93.97c-.61,.55-.36,1.81,.54,2.8,.91,1,2.13,1.36,2.74,.81,.61-.55,.36-1.81-.54-2.8-.91-1-2.13-1.36-2.74-.81Z"/>
          <path class="cls-129" d="M51.54,93.98c-.6,.55-.36,1.8,.54,2.79,.9,.99,2.12,1.35,2.73,.8,.6-.55,.36-1.8-.54-2.79-.9-.99-2.12-1.35-2.73-.81Z"/>
          <path class="cls-83" d="M51.54,93.98c-.6,.55-.36,1.79,.54,2.78,.9,.99,2.12,1.35,2.72,.8,.6-.55,.36-1.79-.54-2.78-.9-.99-2.12-1.35-2.72-.8Z"/>
          <path class="cls-177" d="M51.55,93.99c-.6,.54-.36,1.78,.54,2.77,.9,.99,2.11,1.34,2.71,.8,.6-.54,.36-1.78-.54-2.77-.9-.99-2.11-1.34-2.71-.8Z"/>
          <path class="cls-177" d="M51.56,94c-.6,.54-.36,1.78,.53,2.76,.89,.98,2.1,1.34,2.69,.8s.36-1.78-.53-2.76c-.89-.98-2.1-1.34-2.69-.8Z"/>
          <path class="cls-37" d="M51.56,94.01c-.59,.54-.36,1.77,.53,2.75,.89,.98,2.09,1.33,2.68,.79,.59-.54,.36-1.77-.53-2.75-.89-.98-2.09-1.33-2.68-.79Z"/>
          <path class="cls-169" d="M51.57,94.01c-.59,.54-.35,1.76,.53,2.73,.88,.97,2.08,1.33,2.67,.79,.59-.54,.35-1.76-.53-2.73-.88-.97-2.08-1.33-2.67-.79Z"/>
          <path class="cls-19" d="M51.58,94.02c-.59,.54-.35,1.75,.53,2.72,.88,.97,2.07,1.32,2.66,.79,.59-.54,.35-1.75-.53-2.72-.88-.97-2.07-1.32-2.66-.79Z"/>
          <path class="cls-19" d="M51.58,94.03c-.59,.53-.35,1.75,.53,2.71,.88,.96,2.06,1.31,2.65,.78,.59-.53,.35-1.75-.53-2.71-.88-.96-2.06-1.31-2.65-.78Z"/>
          <path class="cls-19" d="M51.59,94.03c-.58,.53-.35,1.74,.52,2.7,.87,.96,2.05,1.31,2.64,.78,.58-.53,.35-1.74-.52-2.7-.87-.96-2.05-1.31-2.64-.78Z"/>
          <path class="cls-6" d="M51.6,94.04c-.58,.53-.35,1.73,.52,2.69,.87,.96,2.04,1.3,2.63,.78,.58-.53,.35-1.73-.52-2.69-.87-.96-2.04-1.3-2.63-.78Z"/>
          <path class="cls-6" d="M51.6,94.05c-.58,.53-.35,1.72,.52,2.68,.87,.95,2.04,1.3,2.61,.77,.58-.53,.35-1.72-.52-2.68-.87-.95-2.04-1.3-2.62-.77Z"/>
          <path class="cls-6" d="M51.61,94.06c-.58,.52-.34,1.72,.52,2.67,.86,.95,2.03,1.29,2.6,.77s.34-1.72-.52-2.67c-.86-.95-2.03-1.29-2.6-.77Z"/>
          <path class="cls-6" d="M51.62,94.07c-.57,.52-.34,1.71,.51,2.65,.86,.94,2.02,1.29,2.59,.76,.57-.52,.34-1.71-.51-2.65-.86-.94-2.02-1.29-2.59-.76Z"/>
          <path class="cls-146" d="M51.62,94.07c-.57,.52-.34,1.7,.51,2.64,.85,.94,2.01,1.28,2.58,.76,.57-.52,.34-1.7-.51-2.64-.85-.94-2.01-1.28-2.58-.76Z"/>
          <path class="cls-161" d="M51.63,94.08c-.57,.52-.34,1.69,.51,2.63,.85,.94,2,1.28,2.57,.76,.57-.52,.34-1.69-.51-2.63-.85-.94-2-1.28-2.57-.76Z"/>
          <path class="cls-161" d="M51.64,94.09c-.57,.51-.34,1.69,.51,2.62,.85,.93,1.99,1.27,2.56,.75,.57-.51,.34-1.69-.51-2.62-.85-.93-1.99-1.27-2.56-.75Z"/>
          <path class="cls-161" d="M51.65,94.1c-.56,.51-.34,1.68,.51,2.61,.84,.93,1.98,1.26,2.55,.75,.56-.51,.34-1.68-.5-2.61-.84-.93-1.98-1.26-2.55-.75Z"/>
          <path class="cls-14" d="M51.65,94.1c-.56,.51-.34,1.67,.5,2.6,.84,.92,1.97,1.26,2.54,.75,.56-.51,.34-1.67-.5-2.59-.84-.92-1.97-1.26-2.54-.75Z"/>
          <path class="cls-14" d="M51.66,94.11c-.56,.51-.33,1.66,.5,2.58,.83,.92,1.96,1.25,2.52,.75,.56-.51,.33-1.66-.5-2.58-.84-.92-1.97-1.25-2.52-.75Z"/>
          <path class="cls-176" d="M51.67,94.12c-.56,.5-.33,1.66,.5,2.57,.83,.92,1.96,1.25,2.51,.74,.56-.5,.33-1.66-.5-2.57-.83-.92-1.96-1.25-2.51-.74Z"/>
          <path class="cls-176" d="M51.67,94.12c-.55,.5-.33,1.65,.5,2.56,.83,.91,1.95,1.24,2.5,.74,.55-.5,.33-1.65-.5-2.56-.83-.91-1.95-1.24-2.5-.74Z"/>
          <path class="cls-176" d="M51.68,94.13c-.55,.5-.33,1.64,.49,2.55,.82,.91,1.94,1.24,2.49,.73,.55-.5,.33-1.64-.49-2.55-.82-.91-1.94-1.24-2.49-.74Z"/>
          <path class="cls-40" d="M51.69,94.14c-.55,.5-.33,1.63,.49,2.54,.82,.9,1.93,1.23,2.48,.73,.55-.5,.33-1.63-.49-2.54-.82-.9-1.93-1.23-2.48-.73Z"/>
          <path class="cls-40" d="M51.69,94.15c-.55,.5-.33,1.63,.49,2.53,.82,.9,1.92,1.22,2.47,.73,.55-.5,.33-1.63-.49-2.53-.82-.9-1.92-1.22-2.47-.73Z"/>
          <path class="cls-199" d="M51.7,94.16c-.54,.49-.33,1.62,.49,2.51,.81,.89,1.91,1.22,2.45,.72,.54-.49,.33-1.62-.49-2.51-.81-.89-1.91-1.22-2.46-.72Z"/>
          <path class="cls-199" d="M51.71,94.16c-.54,.49-.32,1.61,.49,2.5,.81,.89,1.9,1.21,2.44,.72,.54-.49,.32-1.61-.49-2.5-.81-.89-1.9-1.21-2.44-.72Z"/>
          <path class="cls-199" d="M51.71,94.17c-.54,.49-.32,1.6,.48,2.49,.81,.89,1.89,1.21,2.43,.72,.54-.49,.32-1.6-.48-2.49-.8-.89-1.89-1.21-2.43-.72Z"/>
          <path class="cls-199" d="M51.72,94.18c-.54,.49-.32,1.6,.48,2.48,.8,.88,1.89,1.2,2.42,.71,.54-.49,.32-1.6-.48-2.48-.8-.88-1.89-1.2-2.42-.71Z"/>
          <path class="cls-199" d="M51.73,94.19c-.53,.48-.32,1.59,.48,2.47,.8,.88,1.88,1.2,2.41,.71,.53-.48,.32-1.59-.48-2.47-.8-.88-1.88-1.2-2.41-.71Z"/>
          <path class="cls-199" d="M51.73,94.19c-.53,.48-.32,1.58,.48,2.46,.79,.87,1.87,1.19,2.4,.71,.53-.48,.32-1.58-.48-2.45-.79-.87-1.87-1.19-2.4-.71Z"/>
          <path class="cls-199" d="M51.74,94.2c-.53,.48-.32,1.57,.47,2.44,.79,.87,1.86,1.19,2.39,.71,.53-.48,.32-1.57-.47-2.44-.79-.87-1.86-1.19-2.39-.71Z"/>
          <path class="cls-199" d="M51.75,94.21c-.53,.48-.31,1.57,.47,2.43,.79,.87,1.85,1.18,2.38,.7,.53-.48,.31-1.57-.47-2.43-.79-.87-1.85-1.18-2.38-.7Z"/>
          <path class="cls-199" d="M51.75,94.22c-.52,.48-.31,1.56,.47,2.42,.78,.86,1.84,1.17,2.37,.7,.52-.48,.31-1.56-.47-2.42-.78-.86-1.84-1.17-2.36-.7Z"/>
          <path class="cls-199" d="M51.76,94.22c-.52,.47-.31,1.55,.47,2.41,.78,.86,1.83,1.17,2.35,.69,.52-.47,.31-1.55-.47-2.41-.78-.86-1.83-1.17-2.35-.7Z"/>
          <path class="cls-199" d="M51.77,94.23c-.52,.47-.31,1.54,.46,2.4,.77,.85,1.82,1.16,2.34,.69,.52-.47,.31-1.54-.46-2.4-.78-.85-1.82-1.16-2.34-.69Z"/>
        </g>
        <path class="cls-138" d="M82.47,109.21s-.1-1.06,1.34-1.06l-1.59-1.5-.38,1.77,.63,.79Z"/>
        <path class="cls-138" d="M55.15,94.86s-.1-1.06,1.34-1.06l-1.59-1.5-.38,1.77,.63,.79Z"/>
        <path class="cls-61" d="M51.81,74.12c.25-.46,.81-.63,1.27-.39,.46,.24,.63,.81,.39,1.27-2,3.77-4.54,9.79-3.92,11.36,1.05,2.65,2.35,3.34,5.5,4.8l29.49,15.55c3.75,1.71,7.8,1.82,10.79,.3l7.31-4.29c.85-.53,1.88-1.37,1.9-2.38,.02-.84-.67-1.77-1.89-2.6l-1.45-.89v3.67c0,1.1-.89,1.47-1.99,.84-1.1-.63-1.99-2.04-1.99-3.14,0-.91,.62-1.32,1.46-1.07v-1.86l-22.53-13.84v3.51c0,1.1-.89,1.47-1.99,.84-1.1-.63-1.99-2.04-1.99-3.14,0-.91,.62-1.32,1.46-1.07v-1.69c-.09-.05-.18-.11-.27-.16-2.87-1.71-5.58-3.32-4.46-6.67,1.04-3.11,3.68-10.15,3.7-10.22,.18-.49,.73-.73,1.21-.55,.49,.18,.73,.72,.55,1.21-.03,.07-2.65,7.08-3.68,10.15-.58,1.74,.53,2.61,3.63,4.46,.54,.32,1.1,.65,1.65,1.01l27.69,17c2.32,1.57,2.78,3.16,2.76,4.22-.02,1.03-.52,2.54-2.81,3.95l-7.38,4.33c-1.69,.86-3.64,1.29-5.68,1.29-2.24,0-4.59-.52-6.85-1.55l-1.25-.66v3.6c0,1.1-.89,1.47-1.99,.84-1.1-.63-1.99-2.04-1.99-3.14,0-.91,.62-1.32,1.46-1.07v-1.57l-24.8-13.08v3.65c0,1.1-.89,1.47-1.99,.84-1.1-.63-1.99-2.04-1.99-3.14,0-.91,.62-1.32,1.46-1.07v-1.54c-2.13-1.07-3.73-2.24-4.83-5.04-1.16-2.94,3.14-11.29,4.01-12.94Z"/>
        <path class="cls-131" d="M64.48,21.72c.36-.47,.9-.74,1.49-.74,.42,0,.81,.13,1.15,.39,.2,.15,4.82,3.78,5.77,9.84l.34,3.39c.11,1.03-.65,1.96-1.69,2.06-.06,0-.12,0-.19,0-.97,0-1.77-.73-1.87-1.69l-.33-3.29c-.69-4.39-4.2-7.22-4.34-7.34-.82-.63-.97-1.81-.34-2.64Z"/>
        <path class="cls-165" d="M111.54,76.51c.16,0,.32-.08,.41-.23,.13-.22,.06-.51-.17-.64l-39.13-23.09c-.22-.13-.51-.06-.64,.17-.13,.22-.06,.51,.17,.64l39.13,23.09c.07,.04,.16,.06,.24,.06Z"/>
        <path class="cls-165" d="M112.6,67.57c.16,0,.32-.08,.4-.23,.13-.22,.06-.51-.16-.65l-40.88-24.62c-.22-.13-.51-.06-.65,.16-.13,.22-.06,.51,.16,.65l40.88,24.62c.08,.05,.16,.07,.24,.07Z"/>
        <path class="cls-165" d="M80.22,67.28h0c.26,0,.47-.21,.47-.47l-.17-29.8c0-.26-.21-.47-.47-.47h0c-.26,0-.47,.21-.47,.47l.17,29.8c0,.26,.21,.47,.47,.47Z"/>
        <path class="cls-34" d="M104.08,89.04c.24,0,.48-.13,.61-.35,.19-.34,.08-.77-.26-.96l-37.64-21.73c-.34-.2-.77-.08-.96,.26-.2,.34-.08,.77,.26,.96l37.64,21.73c.11,.06,.23,.09,.35,.09Z"/>
        <path class="cls-34" d="M81.87,92.32c.08,0,.16-.02,.24-.06l20.83-12.08c.22-.13,.3-.42,.17-.64-.13-.23-.42-.3-.64-.17l-20.83,12.08c-.23,.13-.3,.42-.17,.64,.09,.15,.25,.23,.41,.23Z"/>
        <path class="cls-165" d="M49.29,56.01c.08,0,.16-.02,.24-.06l22.42-13.05c.22-.13,.3-.42,.17-.64-.13-.23-.42-.3-.64-.17l-22.42,13.05c-.22,.13-.3,.42-.17,.64,.09,.15,.25,.23,.41,.23Z"/>
        <path class="cls-165" d="M50.82,65.71c.08,0,.16-.02,.23-.06l21.58-12.3c.23-.13,.3-.42,.18-.64-.13-.23-.42-.3-.64-.18l-21.58,12.3c-.23,.13-.3,.42-.18,.64,.09,.15,.25,.24,.41,.24Z"/>
        <path class="cls-34" d="M59.38,79.31c.08,0,.16-.02,.24-.06l20.84-12.03c.23-.13,.3-.42,.17-.64-.13-.22-.42-.3-.64-.17l-20.84,12.03c-.22,.13-.3,.42-.17,.64,.09,.15,.25,.24,.41,.24Z"/>
        <path class="cls-165" d="M95.64,76.13c.25,0,.46-.2,.47-.45l1.37-27.76c.01-.26-.19-.48-.45-.49-.24-.01-.48,.19-.49,.45l-1.37,27.76c-.01,.26,.19,.48,.45,.49h.02Z"/>
        <path class="cls-165" d="M59.95,70.81s.05,0,.07,0c.26-.04,.43-.28,.4-.54l-4.46-29.78c-.04-.26-.28-.43-.53-.4-.26,.04-.43,.28-.4,.54l4.46,29.78c.04,.23,.24,.4,.46,.4Z"/>
        <path class="cls-165" d="M66.44,67.07s.03,0,.05,0c.26-.03,.45-.26,.42-.52l-3.17-30.31c-.03-.26-.25-.45-.52-.42-.26,.03-.45,.26-.42,.52l3.17,30.31c.02,.24,.23,.42,.47,.42Z"/>
        <path class="cls-165" d="M88.33,71.57c.25,0,.46-.2,.47-.46l.63-28.37c0-.26-.2-.47-.46-.48h-.01c-.25,0-.46,.2-.47,.46l-.63,28.37c0,.26,.2,.47,.46,.48h.01Z"/>
        <path class="cls-34" d="M97.01,93.11c.25,0,.48-.13,.61-.35,.2-.34,.08-.77-.26-.96l-37.64-21.73c-.34-.2-.77-.08-.96,.26-.19,.34-.08,.77,.26,.96l37.64,21.73c.11,.06,.23,.09,.35,.09Z"/>
        <path class="cls-34" d="M74.72,88.15c.08,0,.16-.02,.23-.06l20.92-12.02c.22-.13,.3-.42,.17-.64-.13-.23-.42-.3-.64-.17l-20.92,12.02c-.23,.13-.3,.42-.17,.64,.09,.15,.25,.24,.41,.24Z"/>
        <path class="cls-34" d="M67.26,83.85c.08,0,.16-.02,.24-.06l21.06-12.27c.22-.13,.3-.42,.17-.64-.13-.22-.42-.3-.64-.17l-21.06,12.27c-.22,.13-.3,.42-.17,.64,.09,.15,.24,.23,.41,.23Z"/>
        <path class="cls-34" d="M52.32,75.69c.16,0,.32-.04,.47-.13l20.82-12.02c.45-.26,.6-.84,.34-1.29-.26-.45-.84-.6-1.29-.34l-20.82,12.02c-.45,.26-.6,.84-.34,1.29,.17,.3,.49,.47,.82,.47Z"/>
        <path class="cls-165" d="M113.48,59.41c.31,0,.61-.15,.79-.43,.28-.44,.15-1.02-.28-1.3l-42.34-27.18c-.44-.28-1.02-.15-1.3,.28-.28,.44-.15,1.02,.28,1.3l42.34,27.18c.16,.1,.33,.15,.51,.15Z"/>
        <path class="cls-165" d="M73.14,63.66s.04,0,.06,0c.52-.03,.91-.48,.88-1l-2-31.44c-.03-.52-.48-.92-1-.88-.52,.03-.91,.48-.88,1l2,31.44c.03,.5,.45,.88,.94,.88Z"/>
        <path class="cls-34" d="M110.85,85.35c.33,0,.64-.17,.82-.47,.26-.45,.1-1.03-.35-1.29l-37.71-21.69c-.45-.26-1.02-.1-1.29,.35-.26,.45-.1,1.03,.35,1.29l37.71,21.69c.15,.08,.31,.13,.47,.13Z"/>
        <path class="cls-165" d="M47.62,45.81c.16,0,.32-.04,.47-.13l23.52-13.58c.45-.26,.6-.83,.34-1.29-.26-.45-.84-.61-1.29-.34l-23.52,13.58c-.45,.26-.61,.84-.34,1.29,.17,.3,.49,.47,.82,.47Z"/>
        <path class="cls-49" d="M52.32,75.69s.1,0,.15-.01c.51-.08,.86-.56,.78-1.08l-4.7-29.88c-.08-.51-.56-.86-1.08-.78-.51,.08-.86,.56-.78,1.08l4.71,29.88c.07,.46,.47,.79,.93,.79Z"/>
        <path class="cls-49" d="M89.95,97.42c.33,0,.64-.17,.82-.47,.26-.45,.11-1.03-.34-1.29l-37.64-21.73c-.45-.26-1.02-.11-1.29,.34-.26,.45-.11,1.03,.34,1.29l37.64,21.73c.15,.08,.31,.13,.47,.13Z"/>
        <g>
          <polygon class="cls-200" points="67.59 42.16 72.57 45.03 72.6 40.73 67.59 42.16"/>
          <polygon class="cls-171" points="75.04 37.83 75.04 46.46 87.05 53.39 89.5 50.49 94.5 49.06 75.04 37.83"/>
          <polygon class="cls-197" points="94.5 49.06 97.67 83.68 91.5 85.45 89.5 50.49 94.5 49.06"/>
          <polygon class="cls-166" points="87.04 53.39 88.46 89.04 91.5 85.45 89.5 50.49 87.04 53.39"/>
          <path class="cls-200" d="M81.24,39.25c0-1.19,.85-1.68,1.89-1.08l3.88,2.24c1.04,.6,1.88,2.06,1.88,3.25v4.29s-1.26-.72-1.26-.72v-4.29c.01-.4-.27-.88-.62-1.08l-3.88-2.24c-.34-.2-.63-.04-.63,.36v4.29s-1.26-.72-1.26-.72v-4.29Z"/>
          <polygon class="cls-36" points="72.57 45.03 75.04 46.46 75.04 37.83 72.6 40.73 72.57 45.03"/>
          <path class="cls-151" d="M74.1,43.56c0-1.19,.85-1.68,1.89-1.08l3.88,2.24c1.04,.6,1.88,2.06,1.87,3.25v4.29s-1.26-.72-1.26-.72v-4.29c.01-.4-.27-.88-.62-1.08l-3.88-2.24c-.34-.2-.63-.04-.63,.36v4.29s-1.26-.72-1.26-.72v-4.29Z"/>
          <polygon class="cls-139" points="64.41 75.16 67.59 42.16 87.04 53.39 88.46 89.04 64.41 75.16"/>
        </g>
        <path class="cls-49" d="M89.96,97.42c.52,0,.94-.42,.94-.94v-24.05c0-.52-.42-.94-.94-.94s-.94,.42-.94,.94v24.05c0,.52,.42,.94,.94,.94Z"/>
        <path class="cls-49" d="M110.85,85.35c.48,0,.89-.36,.93-.85l2.63-25.94c.05-.52-.33-.98-.84-1.03-.53-.05-.98,.33-1.03,.84l-2.63,25.94c-.05,.52,.32,.98,.84,1.03,.03,0,.06,0,.1,0Z"/>
        <path class="cls-49" d="M89.96,97.42c.16,0,.32-.04,.47-.13l20.9-12.07c.45-.26,.6-.83,.34-1.29-.26-.45-.84-.61-1.29-.34l-20.9,12.07c-.45,.26-.6,.83-.34,1.29,.17,.3,.49,.47,.82,.47Z"/>
        <path class="cls-49" d="M104.08,88.8c.25,0,.46-.2,.47-.45l.95-24.85c0-.26-.19-.48-.45-.49,0,0-.01,0-.02,0-.25,0-.46,.2-.47,.45l-.95,24.85c0,.26,.19,.48,.45,.49,0,0,.01,0,.02,0Z"/>
        <path class="cls-49" d="M89.95,73.37c.31,0,.61-.15,.79-.43,.28-.44,.16-1.02-.27-1.3l-42.34-27.56c-.44-.28-1.02-.16-1.3,.28-.28,.44-.16,1.02,.27,1.3l42.34,27.56c.16,.1,.34,.15,.51,.15Z"/>
        <path class="cls-49" d="M89.96,88.8c.16,0,.32-.08,.41-.23,.13-.22,.06-.51-.17-.64l-39.13-23.09c-.22-.13-.51-.06-.64,.17-.13,.22-.06,.51,.17,.64l39.13,23.09c.08,.04,.16,.07,.24,.07Z"/>
        <path class="cls-49" d="M89.96,80.65c.16,0,.31-.08,.4-.23,.13-.22,.06-.51-.16-.65l-40.66-24.64c-.22-.13-.51-.06-.65,.16-.13,.22-.06,.51,.16,.65l40.66,24.64c.08,.05,.16,.07,.24,.07Z"/>
        <path class="cls-49" d="M89.96,80.65c.08,0,.16-.02,.24-.06l22.64-13.07c.23-.13,.3-.42,.17-.64-.13-.23-.42-.3-.64-.17l-22.64,13.07c-.22,.13-.3,.42-.17,.64,.09,.15,.25,.24,.41,.24Z"/>
        <path class="cls-49" d="M89.96,88.8c.08,0,.16-.02,.23-.06l21.58-12.3c.23-.13,.3-.42,.18-.64-.13-.23-.42-.3-.64-.18l-21.58,12.3c-.23,.13-.3,.42-.18,.64,.09,.15,.25,.24,.41,.24Z"/>
        <path class="cls-49" d="M97.01,92.88c.26,0,.47-.21,.47-.47l.24-24.31c0-.26-.21-.47-.47-.47h0c-.26,0-.47,.21-.47,.47l-.24,24.31c0,.26,.21,.47,.47,.48h0Z"/>
        <path class="cls-49" d="M59.37,79.31s.03,0,.04,0c.26-.02,.45-.25,.43-.51l-2.35-27.85c-.02-.26-.25-.45-.51-.43-.26,.02-.45,.25-.43,.51l2.35,27.85c.02,.25,.23,.43,.47,.43Z"/>
        <path class="cls-49" d="M81.87,92.32h0c.26,0,.47-.22,.46-.48l-.34-24.91c0-.26-.21-.49-.48-.46-.26,0-.47,.22-.46,.48l.34,24.91c0,.26,.21,.46,.47,.46Z"/>
        <path class="cls-49" d="M74.72,88.38h.03c.39-.02,.69-.35,.67-.74l-1.23-25.97c-.02-.39-.39-.68-.74-.67-.39,.02-.69,.35-.67,.74l1.23,25.97c.02,.38,.33,.67,.7,.67Z"/>
        <path class="cls-49" d="M67.26,83.85s.02,0,.04,0c.26-.02,.45-.24,.44-.5l-1.96-26.99c-.02-.26-.24-.46-.5-.44-.26,.02-.45,.24-.44,.5l1.96,26.99c.02,.25,.23,.44,.47,.44Z"/>
        <path class="cls-165" d="M102.7,80.24c.24,0,.45-.19,.47-.43l2.32-26.73c.02-.26-.17-.49-.43-.51-.26-.03-.49,.17-.51,.43l-2.32,26.73c-.02,.26,.17,.49,.43,.51,.01,0,.03,0,.04,0Z"/>
        <polygon class="cls-208" points="94.53 69.72 94.25 85.89 108.21 77.93 109.02 61.01 94.53 69.72"/>
        <path class="cls-182" d="M84.53,68.89c.26-.4,.7-.64,1.18-.64,.27,0,.54,.08,.77,.23l3.51,2.29,20.8-12.34-2.65-1.7c-.65-.42-.85-1.29-.42-1.95,.26-.41,.71-.65,1.19-.65,.27,0,.53,.08,.76,.22l4.57,2.94c.18,.12,.32,.27,.43,.44,0,.01,.02,.02,.03,.03,.02,.03,.03,.06,.04,.09,.02,.04,.04,.07,.05,.11,.02,.05,.03,.1,.05,.15,.01,.04,.02,.07,.03,.11,0,.05,.01,.1,.02,.15,0,.04,0,.08,0,.13,0,.04,0,.09,0,.13,0,.05-.01,.09-.02,.14,0,.04-.02,.08-.03,.12-.01,.05-.03,.09-.05,.14-.02,.04-.04,.08-.06,.12-.02,.03-.03,.06-.04,.08,0,.01-.02,.02-.03,.03-.05,.07-.11,.14-.17,.21-.01,.01-.02,.03-.03,.04-.07,.06-.15,.12-.23,.17l-23.52,13.96s-.02,0-.02,0c-.07,.04-.15,.07-.23,.1-.03,.01-.07,.03-.1,.04-.11,.03-.22,.04-.34,.04,0,0-.01,0-.02,0h0s0,0,0,0c0,0-.01,0-.02,0-.12,0-.24-.02-.36-.05-.05-.01-.09-.04-.14-.06-.07-.03-.15-.06-.21-.1-.01,0-.03,0-.04-.02l-4.25-2.76c-.32-.21-.53-.52-.61-.89s0-.75,.2-1.06Z"/>
        <path class="cls-54" d="M41.27,37.3c.1-.36,.33-.67,.66-.86l23.52-13.68c.22-.12,.46-.19,.71-.19,.5,0,.97,.27,1.22,.7,.19,.33,.24,.71,.15,1.07-.1,.36-.33,.67-.66,.86l-23.52,13.68c-.22,.12-.46,.19-.71,.19-.5,0-.97-.27-1.22-.7-.19-.33-.24-.71-.15-1.07Z"/>
        <path class="cls-190" d="M40.31,35.64c.35-.5,.93-.8,1.54-.8,.39,0,.76,.12,1.08,.34,.23,.16,5.7,4.06,6.65,10.08l.53,3.39c.08,.5-.04,.99-.34,1.4-.3,.41-.73,.67-1.23,.75-.1,.01-.2,.02-.29,.02-.93,0-1.71-.67-1.86-1.59l-.53-3.39c-.69-4.39-5.05-7.56-5.1-7.59-.85-.6-1.05-1.77-.46-2.62Z"/>
      </g>
    </g>
  </g>
</svg>
</template>

<script>
export default {

}
</script>

<style>
      .cls-1 {
        fill: #313843;
      }

      .cls-2 {
        fill: #2e313a;
      }

      .cls-3 {
        fill: #41546b;
      }

      .cls-4 {
        fill: #394656;
      }

      .cls-5 {
        fill: #2d3139;
      }

      .cls-6 {
        fill: #242123;
      }

      .cls-7, .cls-8 {
        opacity: .7;
      }

      .cls-9 {
        fill: #41546a;
      }

      .cls-10 {
        fill: #48607c;
      }

      .cls-11, .cls-12, .cls-13 {
        fill: #fff;
      }

      .cls-14 {
        fill: #242021;
      }

      .cls-15 {
        fill: #343c49;
      }

      .cls-16 {
        fill: #333b48;
      }

      .cls-17 {
        fill: url(#linear-gradient-17);
      }

      .cls-18 {
        fill: #445972;
      }

      .cls-19 {
        fill: #252224;
      }

      .cls-20 {
        fill: #2b2d33;
      }

      .cls-21 {
        fill: #27262a;
      }

      .cls-22 {
        fill: #42556c;
      }

      .cls-23 {
        fill: url(#linear-gradient-11);
      }

      .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-29, .cls-30, .cls-31, .cls-32, .cls-33 {
        opacity: .5;
      }

      .cls-34 {
        fill: #68c6e3;
      }

      .cls-35 {
        fill: #2a2a30;
      }

      .cls-36 {
        fill: #ffb445;
      }

      .cls-37 {
        fill: #252325;
      }

      .cls-38 {
        fill: #343d4a;
      }

      .cls-39 {
        fill: #2774c6;
      }

      .cls-40 {
        fill: #231f21;
      }

      .cls-41 {
        fill: #3b495a;
      }

      .cls-42 {
        fill: #2d3038;
      }

      .cls-43 {
        fill: #4a6380;
      }

      .cls-24 {
        fill: url(#linear-gradient-6);
      }

      .cls-25 {
        fill: url(#linear-gradient-8);
      }

      .cls-44 {
        fill: url(#linear-gradient-15);
      }

      .cls-45 {
        fill: url(#linear-gradient-18);
      }

      .cls-46, .cls-47, .cls-48 {
        opacity: .6;
      }

      .cls-49 {
        fill: #cff7ff;
      }

      .cls-50, .cls-51 {
        fill: #102184;
      }

      .cls-52 {
        fill: #3c4a5d;
      }

      .cls-12, .cls-53 {
        opacity: .3;
      }

      .cls-54 {
        fill: url(#linear-gradient-28);
      }

      .cls-55 {
        fill: #405268;
      }

      .cls-56 {
        fill: #3d4c5f;
      }

      .cls-57 {
        fill: #455a73;
      }

      .cls-58 {
        fill: #374251;
      }

      .cls-59 {
        fill: url(#linear-gradient-14);
      }

      .cls-60 {
        fill: #262326;
      }

      .cls-26 {
        fill: url(#linear-gradient-10);
      }

      .cls-61 {
        fill: url(#linear-gradient-20);
      }

      .cls-62 {
        fill: #3a4758;
      }

      .cls-63 {
        clip-path: url(#clippath-2);
      }

      .cls-64 {
        fill: #272629;
      }

      .cls-65 {
        fill: #4e6a89;
      }

      .cls-66 {
        fill: #4c6684;
      }

      .cls-67 {
        fill: #3f4f64;
      }

      .cls-68 {
        fill: #445a72;
      }

      .cls-69 {
        fill: #374352;
      }

      .cls-70, .cls-51 {
        opacity: .2;
      }

      .cls-71 {
        fill: #303641;
      }

      .cls-72 {
        fill: #3b495c;
      }

      .cls-73 {
        fill: #384353;
      }

      .cls-74 {
        fill: #353e4c;
      }

      .cls-75 {
        fill: #2b2d34;
      }

      .cls-76 {
        fill: #2f353f;
      }

      .cls-77 {
        fill: #163fa1;
      }

      .cls-78 {
        fill: #2c2e35;
      }

      .cls-79 {
        fill: #537295;
      }

      .cls-80 {
        fill: #28272c;
      }

      .cls-27 {
        fill: url(#linear-gradient-7);
      }

      .cls-81 {
        fill: #3f5064;
      }

      .cls-82 {
        fill: #2d313a;
      }

      .cls-83 {
        fill: #262426;
      }

      .cls-13, .cls-84 {
        opacity: .8;
      }

      .cls-85 {
        fill: #323844;
      }

      .cls-86 {
        fill: #3d4c60;
      }

      .cls-87 {
        fill: #394555;
      }

      .cls-88 {
        fill: #4e6b8b;
      }

      .cls-89 {
        fill: #29292f;
      }

      .cls-90 {
        fill: #343d4b;
      }

      .cls-91 {
        fill: #29292e;
      }

      .cls-92 {
        fill: #4f6c8c;
      }

      .cls-93 {
        fill: #4d6988;
      }

      .cls-94 {
        fill: #28282d;
      }

      .cls-95 {
        fill: #49627e;
      }

      .cls-28 {
        fill: url(#linear-gradient-3);
      }

      .cls-96 {
        fill: #3e4e62;
      }

      .cls-97 {
        fill: #3f5065;
      }

      .cls-98 {
        clip-path: url(#clippath-1);
      }

      .cls-99 {
        fill: #48617c;
      }

      .cls-100 {
        fill: #30353f;
      }

      .cls-101 {
        fill: #2f343d;
      }

      .cls-102 {
        fill: #4a637f;
      }

      .cls-103 {
        fill: #303640;
      }

      .cls-104 {
        fill: #42546b;
      }

      .cls-105 {
        fill: #415369;
      }

      .cls-106 {
        fill: #4a6481;
      }

      .cls-29 {
        fill: url(#linear-gradient-9);
      }

      .cls-107 {
        fill: #2c2f36;
      }

      .cls-8, .cls-108 {
        fill: #0b113f;
      }

      .cls-109 {
        fill: #4b6582;
      }

      .cls-110 {
        fill: #43576e;
      }

      .cls-111 {
        fill: #364150;
      }

      .cls-112 {
        fill: #2e333c;
      }

      .cls-113 {
        fill: #465c75;
      }

      .cls-114 {
        fill: #3a4859;
      }

      .cls-115 {
        fill: #4f6d8d;
      }

      .cls-116 {
        fill: #43586f;
      }

      .cls-117 {
        fill: url(#linear-gradient-16);
      }

      .cls-118 {
        fill: #3b4a5c;
      }

      .cls-119 {
        fill: #4b6583;
      }

      .cls-120 {
        fill: #527092;
      }

      .cls-121 {
        fill: #2b2e34;
      }

      .cls-122 {
        fill: #3a4759;
      }

      .cls-123 {
        fill: #333b47;
      }

      .cls-124 {
        fill: #445871;
      }

      .cls-125 {
        fill: #465c76;
      }

      .cls-126 {
        fill: #2a2b31;
      }

      .cls-127 {
        fill: #48607b;
      }

      .cls-128 {
        fill: #394657;
      }

      .cls-129 {
        fill: #262427;
      }

      .cls-130 {
        fill: #27272b;
      }

      .cls-131 {
        fill: url(#linear-gradient-21);
      }

      .cls-132 {
        fill: #3c4b5d;
      }

      .cls-133 {
        fill: #4d6886;
      }

      .cls-134 {
        fill: #3d4d61;
      }

      .cls-135, .cls-48 {
        fill: #47a0ff;
      }

      .cls-136 {
        fill: #2f343e;
      }

      .cls-137 {
        fill: #405267;
      }

      .cls-138 {
        fill: #6b8fa6;
      }

      .cls-30 {
        fill: url(#linear-gradient-2);
      }

      .cls-139 {
        fill: url(#linear-gradient-25);
      }

      .cls-140 {
        fill: #517091;
      }

      .cls-141 {
        fill: #28272b;
      }

      .cls-31 {
        fill: url(#linear-gradient-5);
      }

      .cls-142 {
        fill: #475e79;
      }

      .cls-143 {
        fill: #43576f;
      }

      .cls-144 {
        fill: #506e8f;
      }

      .cls-145 {
        clip-path: url(#clippath);
      }

      .cls-146 {
        fill: #242122;
      }

      .cls-147 {
        fill: #3c4b5e;
      }

      .cls-148 {
        fill: #455b74;
      }

      .cls-149 {
        fill: #49617d;
      }

      .cls-150 {
        fill: #3e4f63;
      }

      .cls-151 {
        fill: #ffd752;
      }

      .cls-152 {
        fill: #475e78;
      }

      .cls-153 {
        fill: none;
      }

      .cls-154 {
        fill: #2f333d;
      }

      .cls-155 {
        fill: #3b485a;
      }

      .cls-156 {
        fill: #333c48;
      }

      .cls-157 {
        fill: #445870;
      }

      .cls-47 {
        fill: url(#linear-gradient-12);
      }

      .cls-158 {
        fill: #394556;
      }

      .cls-159 {
        clip-path: url(#clippath-3);
      }

      .cls-160 {
        fill: #323945;
      }

      .cls-161 {
        fill: #242022;
      }

      .cls-162 {
        fill: #4e6989;
      }

      .cls-163 {
        fill: #28292d;
      }

      .cls-164 {
        fill: #4e6a8a;
      }

      .cls-165 {
        fill: #a1daf7;
      }

      .cls-166 {
        fill: url(#linear-gradient-24);
      }

      .cls-167 {
        fill: #3f5166;
      }

      .cls-168 {
        fill: #384454;
      }

      .cls-169 {
        fill: #252225;
      }

      .cls-170 {
        fill: #2b2c33;
      }

      .cls-171 {
        fill: url(#linear-gradient-22);
      }

      .cls-172 {
        fill: #4c6785;
      }

      .cls-173 {
        fill: #527194;
      }

      .cls-174 {
        fill: #4f6b8b;
      }

      .cls-175 {
        fill: #323a46;
      }

      .cls-32 {
        fill: url(#linear-gradient-4);
      }

      .cls-176 {
        fill: #232021;
      }

      .cls-177 {
        fill: #252326;
      }

      .cls-178 {
        fill: #4d6887;
      }

      .cls-179 {
        fill: #537396;
      }

      .cls-180 {
        fill: url(#linear-gradient);
      }

      .cls-181 {
        fill: #485f7a;
      }

      .cls-182 {
        fill: url(#linear-gradient-27);
      }

      .cls-183 {
        fill: #475f7a;
      }

      .cls-184 {
        fill: #374150;
      }

      .cls-185 {
        fill: #36404f;
      }

      .cls-186 {
        fill: #353f4d;
      }

      .cls-187 {
        fill: #272529;
      }

      .cls-188 {
        fill: #323944;
      }

      .cls-189 {
        fill: #313742;
      }

      .cls-190 {
        fill: url(#linear-gradient-29);
      }

      .cls-191 {
        fill: #2e323b;
      }

      .cls-192 {
        fill: #262528;
      }

      .cls-193 {
        fill: #36404e;
      }

      .cls-194 {
        fill: #405166;
      }

      .cls-195 {
        fill: #516f90;
      }

      .cls-196 {
        fill: #42566d;
      }

      .cls-197 {
        fill: url(#linear-gradient-23);
      }

      .cls-198 {
        fill: url(#linear-gradient-19);
      }

      .cls-199 {
        fill: #231f20;
      }

      .cls-33 {
        fill: #58dfec;
      }

      .cls-200 {
        fill: #ffbc47;
      }

      .cls-201 {
        fill: #2a2c32;
      }

      .cls-202 {
        fill: #2c3037;
      }

      .cls-203 {
        fill: #506d8e;
      }

      .cls-204 {
        fill: #292a2f;
      }

      .cls-205 {
        fill: #262529;
      }

      .cls-206 {
        fill: url(#linear-gradient-13);
      }

      .cls-207 {
        fill: #2c2f37;
      }

      .cls-208 {
        fill: url(#linear-gradient-26);
      }

      .cls-209 {
        fill: #475d77;
      }
    </style>