<template>
  <v-app>
    <v-app-bar
      app
      dark
    >
    <v-app-bar elevation="0" class="mx-auto" max-width="1200">
      <v-app-bar-nav-icon v-if="isMobile" class="mr-2" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/wealthylogo.png"
          transition="scale-transition"
          width="40"
        />

        <h1>Wealthy Vibe</h1>
      </div>
      <v-spacer></v-spacer>
      <v-btn v-if="!isMobile" to="/" class="" rounded  text color="primary">Home</v-btn>
      <v-btn v-if="!isMobile" to="/courses" class="ml-5" rounded  text color="primary">Courses</v-btn>
      <v-btn v-if="!isMobile" to="/article" class="ml-5" rounded  text color="primary">Articles</v-btn>




      </v-app-bar>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed

      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="primary"
        >
          <v-btn outlined to="/" width="100%" color="primary">Home</v-btn>
          <v-btn class="mt-2" outlined to="/" width="100%" color="primary">Courses</v-btn>


        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style lang="scss">@import '@/styles/moivingborder.scss';</style>



<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({ isMobile: false , drawer: false,group: null,}),

    beforeDestroy () {
      if (typeof window === 'undefined') return

      window.removeEventListener('resize', this.onResize, { passive: true })
    },

    mounted () {
      this.onResize()

      window.addEventListener('resize', this.onResize, { passive: true })
    },
    watch: {
      group () {
        this.drawer = false
      },
    },

    methods: {
      onResize () {
        this.isMobile = window.innerWidth < 600
        window.isMobile = window.innerWidth < 600
      },
    },
};
</script>
