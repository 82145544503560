<template>
  <div class="home">
    <v-card class="mx-auto" color="transparent" outlined max-width="1200">
      <v-row>
        <v-card
          class="mx-auto pa-10 mt-10"
          dark
          color="transparent"
          elevation="0"
        >
          <h1>Start your own busniess in</h1>
          <vue-typed-js
            :loop="true"
            :strings="[
              'Dropshipping',
              'Affiliate Marketing',
              'Social Media Marketing',
            ]"
          >
            <h1><span color="primary" class="typing primary--text"></span></h1>
          </vue-typed-js>
          <h3 class="mt-5">
            With our hand picked out tools, courses and websites
          </h3>
        </v-card>
        <v-card  class="mx-auto mt-10" color="transparent" elevation="0">
          <svg-home :width="$vuetify.breakpoint.xs ? 350 : 500"></svg-home>
        </v-card>


      </v-row>

      <h1 class="mb-10 mt-10 text-center">Just starting out?</h1>

      <v-card-text>
        <v-row
          ><content-card class="mb-2 mx-auto"></content-card
          ><content-card class="mb-2 mx-auto"></content-card
        ></v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import ContentCard from "../components/ContentCard.vue";
import { VueTypedJs } from "vue-typed-js";
import SvgHome from "../components/SvgHome.vue";
export default {
  name: "Home",
  components: {
    ContentCard,
    VueTypedJs,
    SvgHome,
  },
  data: () => {
    // eslint-disable-next-line no-unused-labels
    isMobile: this.$isMobile
  },
  methods: {
    mobile() {
      return window.isMobile;
    }
  },
};
</script>
