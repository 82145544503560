import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.prototype.$ismobile = false;
import { VueTypedJs } from 'vue-typed-js'

Vue.use(VueTypedJs)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
